import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import ProductReportActionModal from '../actionDropDowns/ProductReportActionModal'

const ProductReportOverlay = ({id}: any) => {
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={<ProductReportActionModal id={id} />}
      />
    </>
  )
}

export default ProductReportOverlay
