import {useContext} from 'react'
import {BackOrderContext} from '../../context'
import {DynamicTable} from '../../../../components/DynamicTable'
import BackOrderTableRow from './BackOrderTableRow'
import BackOrderActionModal from '../model/BackOrderActionModal'

const BackOrderTable = () => {
  const {backOrderListing, isLoading, filters, onSortingChange, checkedRows, setCheckedRows} =
    useContext(BackOrderContext)

  const columns = [
    {key: 'product_name', headerStyle: 'w-100', label: 'Product Name', isSorted: true},
    {key: 'child_sku', headerStyle: 'min-w-150px', label: 'SKU', isSorted: true},
    {
      key: 'qty',
      headerStyle: 'min-w-100px',
      style: 'justify-content-center',
      label: 'Qty',
      isSorted: true,
    },
    {
      headerStyle: 'min-w-175px',
      label: 'Modified By',
      isSorted: false,
    },
    {
      key: 'action',
      label: 'Action',
      render: (row: any) => <BackOrderActionModal row={row} />,
    },
  ]

  return (
    <DynamicTable
      id='back-order-table'
      data={backOrderListing}
      filters={filters}
      sortableColumns={columns}
      onSortingChange={onSortingChange}
      loading={isLoading}
      TableRow={BackOrderTableRow}
      checkedRowId={checkedRows}
      setCheckedRows={setCheckedRows}
      permissionPath={'analytics_replenishment_backorder'}
    />
  )
}

export default BackOrderTable
