/* eslint-disable */
import {useContext, useEffect, useState} from 'react'
import {isEmpty} from '../../../utils/common'
import {useQueryClient} from 'react-query'
import {ProjectDetailsContext} from '../context'
import {formatForLink} from '../../../utils/string'
import {convertToUTC, formatDateObject} from '../../../utils/date'
import TaskTimeline from '../components/GanttChart/TaskTimeline'
import {
  estimationColumnCell,
  progressColumnCell,
  spentTimeColumnCell,
  ticketNameColumnCell,
} from '../components/GanttChart/CustomTableCells'

//<---------------------- Agile Board Page ----------------------->

// Default Values
export const createTicketDefaultValues = (
  columns: any,
  modules: any,
  statuses: any,
  priorities: any,
  isCreateFromBoard: boolean,
  columnId: number | null,
  moduleId: number | null
) => {
  const defaultStatus = statuses?.find((status: any) => status?.is_default)
    ? statuses?.find((status: any) => status?.is_default)?.id
    : statuses?.[0]?.id || 1

  const defaultPriority = priorities?.find((priority: any) => priority?.is_default)
    ? priorities.find((priority: any) => priority?.is_default)?.id
    : priorities?.[0]?.id || 1

  return {
    currentColumnId: isCreateFromBoard ? columnId : columns?.[0]?.column_id,
    moduleId: isCreateFromBoard ? moduleId : modules?.[0]?.module_id,
    status: defaultStatus,
    priority: defaultPriority,
    title: '',
    description: '',
    assignedTo: '',
    estimation: '',
    dueDate: null,
    isArchived: false,
  }
}

export const defaultAgileBoardTicketValues = (row: any) => ({
  assignedTo: row?.defaultAssignee,
  assigneeName: row?.assigneeName,
  dueDate: row?.dueDate,
  dueDateLabel: row?.dueDateLabel,
})

//<---------------------- ListView Page ----------------------->

export const listViewTicketColumns: any[] = [
  {
    key: 'title',
    label: 'TICKET NAME',
    isSorted: true,
    headerStyle: 'min-w-500px',
  },
  {
    key: 'module_name',
    label: 'MODULE',
    isSorted: true,
    headerStyle: 'min-w-175px',
  },
  {
    key: 'column_name',
    label: 'STAGE',
    isSorted: true,
    headerStyle: 'min-w-120px',
  },
  {
    key: 'assignee',
    label: 'ASSIGNEE',
    isSorted: true,
    headerStyle: 'min-w-150px',
  },
  {
    key: 'due_date',
    label: 'DUE DATE',
    isSorted: true,
    headerStyle: 'min-w-120px',
  },
  {
    key: 'priority_label',
    label: 'PRIORITY',
    isSorted: true,
    headerStyle: 'min-w-120px',
  },
  {
    key: 'created_at',
    label: 'CREATED DATE',
    isSorted: true,
    headerStyle: 'min-w-150px',
  },
  {
    key: 'updated_at',
    label: 'UPDATED DATE',
    isSorted: true,
    headerStyle: 'min-w-150px',
  },
]

export const defaultListViewTicketValues = (row: any) => ({
  assignedTo: row?.defaultAssignee,
  dueDate: row?.dueDate,
})

// ticket details path
export const generateTicketDetailsPath = (ticketDetails: any, projectDetails: any) => {
  const {projectId} = projectDetails
  const {ticketId, ticketInitial} = ticketDetails
  return `/projects/all-projects/${projectId}/ticket/${ticketId}/${formatForLink(ticketInitial)}`
}

//<---------------------- Members Page ----------------------->

export const memberPageTableColumns = [
  {key: 'name', label: 'NAME', isSorted: true, headerStyle: 'min-w-200px'},
  {key: 'role', label: 'SYSTEM ROLE', isSorted: false, headerStyle: 'min-w-100px'},
  {
    key: 'card_count',
    label: 'TICKET ASSIGNED',
    isSorted: true,
    headerStyle: 'min-w-100px',
    style: 'justify-content-center',
  },
  {key: 'created_at', label: 'CREATED AT', isSorted: true, headerStyle: 'min-w-150px'},
  {
    key: 'action',
    label: 'ACTION',
    isSorted: false,
    headerStyle: 'w-80px',
  },
]

//<---------------------- Gantt Chart Page ----------------------->

export const mapGanttData = (data: any[]): any[] => {
  if (isEmpty(data)) return []
  return data.reduce((acc: any[], module) => {
    if (!module.columnItems.length) return acc

    return [
      ...acc,
      {
        ...module,
        id: module.mID,
        text: module.moduleName,
        taskProgress: module.total_progress,
        progress: calculatePercentage(
          unixToDate(getLowestStartDate(module), false),
          unixToDate(getHighestDueDate(module), true),
          module.total_estimation
        ),
        type: 'summary',
        open: true,
        spent_time: module?.total_spent_time,
      },
      ...module.columnItems?.map((item: any) => {
        return {
          ...item,
          id: item.ticketID,
          text: item.title,
          start: unixToDate(item.start_date, false),
          end: unixToDate(item.due_date, true),
          parent: module.mID,
          type: 'task',
          progress: calculatePercentage(
            unixToDate(item.start_date, false),
            unixToDate(item.due_date, true),
            item?.estimation
          ),
          taskProgress: item.progress,
        }
      }),
    ]
  }, [])
}

const getLowestStartDate = (module: any) => {
  return module.columnItems.reduce(
    (acc: number, item: any) => {
      const startDate = Number(item.start_date)
      return Math.min(acc, isNaN(startDate) ? Date.now() / 1000 : startDate)
    },
    isNaN(module.start_date) ? Infinity : Number(module.start_date)
  )
}

const getHighestDueDate = (module: any) => {
  return module.columnItems.reduce(
    (acc: number, item: any) => {
      const dueDate = Number(item.due_date)
      return Math.max(acc, isNaN(dueDate) ? Date.now() / 1000 : dueDate)
    },
    isNaN(module.due_date) ? -Infinity : Number(module.due_date)
  )
}

export const ganttChartConfig = (projectId: string, chartView: string) => ({
  features: {
    taskDrag: true,
    taskResize: true,
    columnResize: true,
    sort: true,
    filter: true,
    taskTemplate: TaskTimeline,
    fullScreen: true,
    highlightWeekend: true,
  },
  tableColumns: [
    {
      id: 'text',
      header: 'Ticket Name',
      width: 300,
      sort: true,
      template: (data: any) => ticketNameColumnCell(data, projectId),
    },
    {
      id: 'progress',
      header: 'Estimation',
      align: 'center',
      width: 150,
      sort: true,
      template: estimationColumnCell,
    },
    {
      id: 'spent_time',
      header: 'Spent Time',
      align: 'center',
      width: 150,
      sort: true,
      template: spentTimeColumnCell,
    },
    {
      id: 'taskProgress',
      header: 'Progress',
      width: 120,
      sort: true,
      template: progressColumnCell,
    },
  ],
  zoomConfig: {
    maxCellWidth: 200,
    level: chartView === 'month' ? 4 : 3,
    levels: [
      {
        minCellWidth: 200,
        maxCellWidth: 400,
        scales: [
          {
            unit: 'year',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'month'}),
          },
        ],
      },
      {
        minCellWidth: 150,
        maxCellWidth: 400,
        scales: [
          {
            unit: 'year',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'month'}),
          },
          {
            unit: 'quarter',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'quarter'}),
          },
        ],
      },
      {
        minCellWidth: 250,
        maxCellWidth: 350,
        scales: [
          {
            unit: 'quarter',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'quarter'}),
          },
          {
            unit: 'month',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'month'}),
          },
        ],
      },
      {
        minCellWidth: 100,
        scales: [
          {
            unit: 'month',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'month'}),
          },
          {
            unit: 'week',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'week'}),
          },
        ],
      },
      {
        minCellWidth: 70,
        maxCellWidth: 70,
        scales: [
          {
            unit: 'month',
            step: 1,
            format: (date: Date, isFirstInScale?: boolean) =>
              formatChartScale({date, formatType: 'month', isFirstInScale}),
          },
          {
            unit: 'day',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'day'}),
          },
        ],
      },
      {
        minCellWidth: 25,
        maxCellWidth: 100,
        scales: [
          {
            unit: 'day',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'day'}),
          },
          {
            unit: 'hour',
            step: 6,
            format: (start: Date, end: Date) =>
              formatChartScale({date: start, endDate: end, formatType: 'hour-range'}),
          },
        ],
      },
      {
        maxCellWidth: 120,
        scales: [
          {
            unit: 'day',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'day'}),
          },
          {
            unit: 'hour',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'hour'}),
          },
        ],
      },
    ],
  },
})

const formatChartScale = ({
  date,
  formatType,
  isFirstInScale,
  endDate,
}: {
  date: Date
  formatType: string
  isFirstInScale?: boolean
  endDate?: Date
}): string => {
  switch (formatType) {
    case 'quarter':
      return `<div class='ms-5'>  Q${Math.floor(date.getMonth() / 3) + 1}</div>`

    case 'week':
      return `<div class='ms-5'>  W <span class='text-gray-800'>${formatDateObject(
        date,
        'w'
      )}</span></div>`

    case 'hour-range':
      if (!endDate) return formatDateObject(date, 'HH:mm')
      return `<div class='ms-5'>  ${formatDateObject(date, 'HH:mm')} - ${formatDateObject(
        endDate,
        'HH:mm'
      )}</div>`

    case 'hour':
      return `<div class='ms-5'>  ${formatDateObject(date, 'HH:mm')}</div>`

    case 'month':
      const prevDate = new Date(date)
      prevDate.setDate(prevDate.getDate() - 1)

      if (isFirstInScale || prevDate.getMonth() !== date.getMonth()) {
        return `<div class="ms-5">
          ${new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
          }).format(date)}
        </div>`
      }
      return ''

    case 'day':
      const dayNum = date.getDate()
      const weekday = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
      }).format(date)[0]

      return `<div class="w-100 d-flex justify-content-center gap-1">
        <div class="text-gray-800">${dayNum}</div>
        <div>${weekday}</div>
      </div>`

    default:
      return `<div class='ms-5'>  ${formatDateObject(date, 'yyyy-MM-dd')}</div>`
  }
}

// Function to convert Unix timestamp to Date object with time set to midnight
export const unixToDate = (timestamp: number | null, isEndDate: boolean = false): Date => {
  const fallbackDate = new Date().setHours(0, 0, 0, 0)
  const validTimestamp = timestamp && !isNaN(timestamp) ? timestamp : fallbackDate / 1000

  const date = new Date(validTimestamp * 1000)
  if (isEndDate) {
    date.setDate(date.getDate() + 1)
  }
  return new Date(date.setHours(0, 0, 0, 0))
}

export const calculatePercentage = (startDate: Date, dueDate: Date, estimation: string): number => {
  // Parse hours and minutes from estimation string
  const timeParts = estimation.match(/(\d+)(h|m)/g) || []
  let totalHours = 0

  timeParts.forEach((part) => {
    const value = parseInt(part)
    if (part.includes('h')) totalHours += value
    if (part.includes('m')) totalHours += value / 60
  })

  // Calculate total working hours between dates (1 day = 8 working hours)
  const durationMs = dueDate.getTime() - startDate.getTime()
  const totalWorkingHours = (durationMs / (1000 * 60 * 60)) * (8 / 24)

  // Handle edge case when duration is zero
  if (totalWorkingHours <= 0) return 0

  // Calculate percentage with bounds (0-100)
  const percentage = Math.min(Math.max((totalHours * 100) / totalWorkingHours, 0), 100)

  return parseFloat(percentage.toFixed(2))
}

export const convertPercentageToEstimation = (
  percentage: number,
  startDate: Date,
  dueDate: Date
): string => {
  // Calculate total duration in hours considering 1 day = 8 working hours
  const durationInMs = dueDate.getTime() - startDate.getTime()
  const durationInHours = (durationInMs / (1000 * 60 * 60)) * (8 / 24)

  // Calculate estimation hours based on the percentage
  const estimationHours = (percentage / 100) * durationInHours

  // Convert estimation hours to hours and rounded 15-minute intervals
  let hours = Math.floor(estimationHours)
  let minutes = Math.round(((estimationHours - hours) * 60) / 15) * 15

  // Handle overflow if minutes reach 60
  if (minutes >= 60) {
    hours += 1
    minutes = 0
  }

  // Return in "Xh Ym" format
  return `${hours}h ${minutes}m`
}

// Recalculate parent progress based on children progress
export function reCalcParentProgress(id: any, api: any) {
  const {tasks} = api.getState()
  const task = api.getTask(id)

  if (task.type !== 'milestone') {
    const summary = tasks.getSummaryId(id)

    if (summary) {
      const progress = getSummaryProgress(summary, api)
      api.exec('update-task', {
        id: summary,
        task: {progress},
      })
    }
  }
}

// Get summary progress
function getSummaryProgress(id: any, api: any) {
  const [totalEstimation, parent] = collectProgressFromKids(id, api)
  return calculatePercentage(parent.start, parent.end, totalEstimation)
}

// Collect progress from children
function collectProgressFromKids(id: any, api: any) {
  const kids = api.getTask(id).data
  const parent = api.getTask(id)
  const totalEstimation = sumEstimations(kids)
  return [totalEstimation, parent]
}

// Sum estimations of all children
function sumEstimations(items: any[]): string {
  const totalMinutes = items.reduce((acc, {progress, start, end}) => {
    const calculatedEstimation = convertPercentageToEstimation(progress, start, end)
    const [hours = 0, minutes = 0] = (calculatedEstimation.match(/\d+/g) || []).map(Number)
    return acc + hours * 60 + minutes
  }, 0)
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return `${hours}h ${minutes}m`
}

// Prepare chart payload
export const getChartPayload = (data: any, type: string) => {
  switch (type) {
    case 'chart-update':
      return {
        ticket_id: data?.task?.id,
        start_date: convertToUTC(data?.task?.start?.toISOString()),
        due_date: convertToUTC(new Date(data?.task?.end?.getTime() - 86400000)?.toISOString()),
        estimation: convertPercentageToEstimation(
          data?.task?.progress,
          data?.task?.start,
          data?.task?.end
        ),
      }
    case 'child-row-sorting':
      return {
        ticketId: data?.data?.id,
        moduleId: data?.data?.parent,
        ticketIds: data?.chartAPIRef?.current
          ?.getTask(data?.data?.parent)
          ?.data?.map((item: any) => item?.ticketID)
          ?.join(','),
      }
    case 'parent-row-sorting':
      const parentTasks = data?.chartAPIRef?.current?.getState()
      return {
        moduleIds: parentTasks?._tasks
          ?.filter((item: any) => item?.parent === 0)
          ?.map((item: any) => item?.id),
      }
  }
}

// Get Gantt details for header

export const useDynamicGanttDetails = (chartState: any) => {
  const [ganttDetails, setGanttDetails] = useState({
    startDate: '',
    endDate: '',
    duration: '',
  })

  useEffect(() => {
    const adjustDate = (date: Date | undefined, days: number): Date | null => {
      if (!date) return null
      return new Date(date.getTime() + days * 86400000)
    }

    const formatGanttDate = (date: Date | null): string => {
      if (!date) return ''
      return date.toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'})
    }

    const calculateTotalDays = (start: Date | null, end: Date | null): string => {
      if (!start || !end) return ''
      const timeDiff = end.getTime() - start.getTime()
      const dayDiff = Math.round(timeDiff / (1000 * 3600 * 24) + 1)
      return `${dayDiff} Day${dayDiff !== 1 ? 's' : ''}`
    }

    const startDate = adjustDate(chartState?._start, 1)
    const endDate = adjustDate(chartState?._end, -2)

    setGanttDetails({
      startDate: formatGanttDate(startDate) || '',
      endDate: formatGanttDate(endDate) || '',
      duration: calculateTotalDays(startDate, endDate) || '',
    })
  }, [chartState?._start, chartState?._end])

  return ganttDetails
}

//<---------------------- Board Settings Page ----------------------->

// Table Columns
export const boardColumnsTableColumns = (isValidForStatusMapping: boolean) => [
  {key: 'name', label: 'NAME', headerClass: 'min-w-300px'},
  {key: 'description', label: 'DESCRIPTION', headerClass: 'min-w-300px'},
  {
    key: 'columnMapping',
    label: 'COLUMN MAPPING',
    headerClass: 'w-250px',
    show: isValidForStatusMapping,
  },
  {key: 'cards', label: 'CARDS', headerClass: 'w-100px text-center'},
  {key: 'isVisible', label: 'VISIBILITY', headerClass: 'w-100px text-center'},
  {key: 'action', label: 'ACTION', headerClass: 'w-80px text-center'},
]

export const boardModulesTableColumns = [
  {
    key: 'moduleName',
    label: 'NAME',
    headerClass: 'min-w-300px',
  },
  {
    key: 'description',
    label: 'Description',
    headerClass: 'min-w-300px',
  },
  {
    key: 'cards',
    label: 'CARDS',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'isVisible',
    label: 'VISIBILITY',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'action',
    label: 'ACTION',
    headerClass: 'w-80px text-center',
  },
]

export const boardCustomFieldsColumns = (isValidForStatusMapping: boolean) => [
  {
    key: 'customFieldName',
    label: 'NAME',
    headerClass: 'min-w-400px',
  },
  {
    key: 'fieldType',
    label: 'FIELD TYPE',
    headerClass: 'w-150px',
  },
  {
    key: 'customFieldMapping',
    label: 'CUSTOM FIELD MAPPING',
    headerClass: 'w-200px',
    show: isValidForStatusMapping,
  },
  {
    key: 'cardCounts',
    label: 'USED IN CARDS',
    headerClass: 'w-150px text-center',
  },
  {
    key: 'isReadOnly',
    label: 'READ ONLY',
    headerClass: 'w-100px text-center', 
  },
  {
    key: 'isVisible',
    label: 'VISIBILITY',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'action',
    label: 'ACTION',
    headerClass: 'w-80px text-center',
  },
]

// Default Values

export const projectMappingDefaultValues = (
  projectMappingDetails: any,
  isEdit: boolean = false
) => {
  if (isEdit && isEmpty(projectMappingDetails)) return {}
  return {
    projectType: isEdit ? projectMappingDetails?.projectType || 'standard' : 'standard',
    tableId: isEdit ? projectMappingDetails?.tableId || 0 : 0,
    columnKey: isEdit ? projectMappingDetails?.columnKey || '' : '',
    ticketName: isEdit ? projectMappingDetails?.ticketName || '' : '',
    defaultAssignee: isEdit ? projectMappingDetails?.defaultAssignee || '' : '',
  }
}

export const AddBoardColumnDefaultValues = (columnData: any, isEdit: boolean): any => ({
  name: isEdit ? columnData.columnName : '',
  description: isEdit ? columnData.description : '',
  isVisible: isEdit ? columnData.is_visible : true,
  is_resolved: isEdit ? columnData.isResolved : false,
})

export const AddBoardModuleDefaultValues = (moduleData: any, isEdit: boolean): any => ({
  name: isEdit ? moduleData.moduleName : '',
  description: isEdit ? moduleData.description : '',
  isVisible: isEdit ? moduleData.isVisible : true,
})

export const addBoardCustomFieldsData = (customFieldData: any, isEdit: boolean) => {
  return {
    type: isEdit ? customFieldData?.type : 'global',
    name: isEdit ? customFieldData?.customFieldName : '',
    customfieldMetaIds: [],
    groupValues: isEdit
      ? customFieldData?.groupValues?.split(',').map((value: any) => ({value: value.trim()}))
      : '',
    datatype: isEdit ? customFieldData?.fieldType : '',
  }
}

export const defaultBoardColumnStatusMappingValues = (boardColumnsList: any) => {
  return boardColumnsList?.reduce(
    (acc: any, column: any) => ({
      ...acc,
      [column.columnId]: column.mappedColumnValue,
    }),
    {}
  )
}

export const defaultBoardCustomFieldStatusMappingValues = (boardCustomFieldsList: any) => {
  return boardCustomFieldsList?.reduce(
    (acc: any, customField: any) => ({
      ...acc,
      [customField.id]: customField.mappedColumnValue,
    }),
    {}
  )
}

export const customFieldTypesDropdownList = [
  {label: 'Text', value: 'text'},
  {label: 'Number', value: 'number'},
  {label: 'Date', value: 'date'},
  {label: 'Select', value: 'select'},
  {label: 'Multi Select', value: 'multi select'},
]

// helper functions

export const useChangeProjectMappingValues = (reset: any, formValues: any, isDirty: boolean) => {
  const queryClient = useQueryClient()
  const {projectMappingDetails, isLoading} = useContext(ProjectDetailsContext)
  const {projectType, tableId, columnKey} = formValues
  const isCustomProject = projectType === 'custom'

  useEffect(() => {
    if (!isEmpty(projectMappingDetails) && !isLoading) {
      reset(projectMappingDefaultValues(projectMappingDetails, true))
      queryClient.setQueryData(['projectMappingData'], projectMappingDetails)
    }
  }, [projectMappingDetails, isLoading])

  useEffect(() => {
    if (!isCustomProject && isDirty) {
      reset(projectMappingDefaultValues(projectMappingDetails))
    }
  }, [isCustomProject, isDirty])

  useEffect(() => {
    if (isDirty) {
      queryClient.setQueryData(['projectMappingData'], {tableId, columnKey})
    }
  }, [tableId, columnKey, isDirty])
}

export const formatBoardCustomFieldSubmitData = (data: any, isEdit: boolean) => {
  const isGroup = ['multi select', 'select'].includes(data.datatype)
  const {groupValues, customfieldMetaIds, ...remainingData} = data

  if (data?.type === 'global') {
    return isEdit
      ? {name: data?.name}
      : {
          type: data?.type,
          customfieldMetaIds,
        }
  }

  return {
    ...(isGroup && {
      groupValues: data.groupValues.map((field: any) => field.value).join(','),
    }),
    ...remainingData,
  }
}

export const TasksReportTableColumns = [
  {key: 'card_title', label: 'TICKET NAME', isSorted: true, headerStyle: 'min-w-400px'},
  {key: 'project_name', label: 'PROJECT', isSorted: true, headerStyle: 'min-w-200px'},
  {key: 'assignee', label: 'ASSIGNEE', isSorted: true, headerStyle: 'min-w-175px'},
  {key: 'module_name', label: 'MODULE', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'estimation', label: 'ESTIMATION', isSorted: true, headerStyle: 'min-w-120px'},
  {key: 'spent_time', label: 'SPENT TIME', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'remaining_time', label: 'REMAINING TIME', isSorted: true, headerStyle: 'min-w-175px'},
  {
    key: 'progress',
    label: 'PROGRESS',
    isSorted: true,
    headerStyle: 'min-w-80px',
    style: 'flex-end',
  },
]
export const MembersReportTableColumns = [
  {key: 'assignee', label: 'ASSIGNEE', isSorted: true, headerStyle: 'min-w-400px'},
  {key: 'total_cards', label: 'TOTAL TASK', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'total_estimation', label: 'ESTIMATION', isSorted: true, headerStyle: 'min-w-120px'},
  {key: 'total_spent_time', label: 'SPENT TIME', isSorted: true, headerStyle: 'min-w-150px'},
  {
    key: 'total_remaining_time',
    label: 'REMAINING TIME',
    isSorted: true,
    headerStyle: 'min-w-175px',
  },
  {
    key: 'progress_percentage',
    label: 'PROGRESS',
    isSorted: true,
    headerStyle: 'min-w-80px',
    style: 'flex-end',
  },
]

export const TimeSheetReportTableColumns = (meta: any) => {
  if (isEmpty(meta)) return []
  return [
    {
      key: 'customer_name',
      label: 'USERS',
      isSorted: true,
      headerStyle: 'min-w-400px w-400px border-end head-col-sticky',
    },
    {
      key: 'total_spent_time',
      label: 'TOTAL TIME',
      isSorted: false,
      headerStyle: 'min-w-120px w-120px head-col-sticky border-end',
    },
    ...Object?.entries(meta)?.map(([key, value]: any, index, array: any) => {
      const [day, year] = value.split('/ ')
      const month = year.split(' ')[0]
      const prevMonth = index > 0 ? array[index - 1][1].split(' / ')[1].split(' ')[0] : ''
      const monthStart = month !== prevMonth ? `${year}` : ''
      const className = key.includes('S') || day.includes('S') ? 'bg-light' : ''

      return {
        key,
        label: `${monthStart} <br/> ${day}`,
        isSorted: false,
        headerStyle: `min-w-100px ${className}`,
        className,
      }
    }),
  ]
}

// Matrix Page

export const matrixAxisOptions = [
  {label: 'Assignee', value: 'Assignee'},
  {label: 'Module', value: 'Module'},
  {label: 'Priority', value: 'Priority'},
  {label: 'Stage', value: 'Stage'},
]
