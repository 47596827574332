import {useState} from 'react'
import useApi from '../../../services/useApi'

const useSafetyStockOperations = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isValidatingSku, setIsValidatingSku] = useState(false)

  const validateChildSku = (sku: string, setError: any) => {
    return Api.get(`/analytics/replenishment/safetystock/sku/validate`, {
      filters: {sku: sku},
      setLoading: setIsValidatingSku,
      isToast: false,
      isErrorPageRedirection: false,
      setUseFormError: setError,
    })
  }

  const createSafetyStockSKU = (payload: any, setError?: any) => {
    return Api.post(
      `/analytics/replenishment/safetystock/sku/qty`,
      {data: payload},
      {
        setLoading: setIsLoading,
        successMessage: 'Safety stock added successfully.',
        setUseFormError: setError,
        refetch: 'safety-stock-listing',
        onError: ({error, response}) => {
          if (response?.response?.data?.records_processed > 0) {
            return {
              refetch: 'safety-stock-listing',
              error,
            }
          } else {
            return error
          }
        },
      }
    )
  }

  const updateSafetyStockSKU = (productId: any, payload: any, setError: any) => {
    return Api.patch(`/analytics/replenishment/safetystock/sku/qty/${productId}`, payload, {
      setLoading: setIsLoading,
      successMessage: 'Safety stock updated successfully.',
      setUseFormError: setError,
      refetch: 'safety-stock-listing',
    })
  }

  const deleteSafetyStockSKU = (payload: any, productId?: number) => {
    return Api.delete(`/analytics/replenishment/safetystock/sku/qty`, payload, {
      setLoading: setIsDeleting,
      successMessage: 'Safety stock deleted successfully.',
      refetch: 'safety-stock-listing',
    })
  }

  return {
    validateChildSku: validateChildSku,
    createSafetyStockSKU: createSafetyStockSKU,
    updateSafetyStockSKU: updateSafetyStockSKU,
    deleteSafetyStockSKU: deleteSafetyStockSKU,
    isValidatingSku: isValidatingSku,
    isDeleting: isDeleting,
    isLoading: isLoading,
  }
}

export default useSafetyStockOperations
