const BackOrderTableRow = ({row, actionComponent, checkBoxComponent}: any) => {
  return (
    <tr>
      {checkBoxComponent && <td>{checkBoxComponent(row)}</td>}
      <td>{row.product_name}</td>
      <td>{row.child_sku}</td>
      <td>
        <div className='text-center'>{row.qty}</div>
      </td>
      <td>
        <div>{row.updated_by ?? '-'}</div>
      </td>
      {actionComponent && <td>{actionComponent(row)}</td>}
    </tr>
  )
}

export default BackOrderTableRow
