import {useQuery} from 'react-query'
import {getClassification} from '../services'
import { convertToLabelValue } from '../utils'

const useGetAllClassifications = (): any=> {
  const {
    data: response,
    isFetching,
    refetch,
  } = useQuery(`get-all-classifications`, () => {
    return getClassification(`/analytics/replenishment/unique/classifications`, {})
  })

  if (!response?.['data']) {
    return []
  }

  return {
    classifications: convertToLabelValue(response?.['data']),
    isFetchingData: isFetching,
    refetch,
  }
}

export default useGetAllClassifications
