import {Link} from 'react-router-dom'
import {parseAgrigatedProductRowData} from '../../../analyticDetails/utils'
import useToastify from '../../../../hook/useToastify'

const AgrigatedProductTableRow = ({row: rowData}: any) => {
  const row = parseAgrigatedProductRowData(rowData)
  const {toastMessage} = useToastify()

  const handleRowClick = () => {
    if (row['total'] === 0) {
      toastMessage('error', "Product doesn't have sold Quantity")
    }
  }

  return (
    <tr>
      <td>
        <div className='align-items-center'>
          {row['total'] === 0 ? (
            <span className='text-dark text-hover-primary fs-6' onClick={handleRowClick}>
              {row['product_name']}
            </span>
          ) : (
            <Link
              to={`/analytics/sold-products/product-report/${row['id']}`}
              className='text-dark text-hover-primary fs-6'
              state={{productName: row['product_name']}}
              onClick={() => {
                localStorage.setItem('detailScreenProductName', row['product_name'])
              }}
            >
              {row['product_name']}
            </Link>
          )}
        </div>
      </td>
      <td className='text-center'>
        <div className='align-items-center'>{row['available_quantity']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['sold_quantity']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['month_4']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['month_3']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['month_2']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['month_1']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['total']}</div>
      </td>
    </tr>
  )
}

export default AgrigatedProductTableRow
