import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

const useGetSafetyStockListing = () => {
  const Api = useApi()

  const initialFilters = {
    page: true,
    limit: 30,
    filter: true,
    sort_by: 'created_at/-1',
  }

  const {filters, onSearch, onPageChange, onSortingChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data: response, isFetching: isLoading} = Api.useGetQuery(
    '/analytics/replenishment/safetystock/sku/qty',
    {
      queryId: 'safety-stock-listing',
      filters: filters,
    }
  )

  return {
    safetyStockListing: response?.data || [],
    pagination: response?.meta?.pagination || {},
    isLoading: isLoading,
    filters: filters,
    onSearch: onSearch,
    onPageChange: onPageChange,
    onSortingChange: onSortingChange,
  }
}

export default useGetSafetyStockListing
