import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import SafetyStockCreateModal from './SafetyStockCreateModal'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {SafetyStockContext} from '../../context'
import useSafetyStockOperations from '../../hooks/useSafetyStockOperations'
import { SafetyStockActionsDropdown } from '../dropdown/SafetyStockActionsDropdown'

const SafetyStockActionModal = ({row}: any) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {deleteSafetyStockSKU, isDeleting} = useSafetyStockOperations()
  const {isLoading} = useContext(SafetyStockContext)

  const handleDelete = (productId: number) => {
    deleteSafetyStockSKU({ids: [productId]})
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <SafetyStockActionsDropdown
            setShowModal={setShowModal}
            setShowDeleteModal={setShowDeleteModal}
          />
        }
        rootClose={true}
      />

      <SafetyStockCreateModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={true}
        productData={row}
      />

      <ConfirmationModal
        uniqueID={row?.id}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onAction={handleDelete}
        isOperationLoading={isDeleting}
        isDataLoading={isLoading}
      />
    </>
  )
}

export default SafetyStockActionModal
