import {useState} from 'react'
import {deleteTag, updateTag} from '../services'
import useToastify from '../../../hook/useToastify'
import useApi from '../../../services/useApi'
import useFilters from '../../../hook/useFilters'

const useGetProductTags = (): any => {
  const {toastMessage} = useToastify()
  const [isOperationLoading, setIsOperationLoading] = useState(false)
  const Api = useApi()
  const initialFilters = {
    sort_by: 'created_at/-1',
  }

  const {filters, onSortingChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {
    data: response,
    refetch,
    isFetching,
  } = Api.useGetQuery(`/analytics/replenishment/tags`, {
    queryId: 'product-tags-listing',
    filters: filters,
  })

  const onAddTag = async (data: any, setError: Function) => {
    setIsOperationLoading(true)
    return Api.post('/analytics/replenishment/tags', data, {
      setUseFormError: setError,
      onSuccess: refetch,
      successMessage: 'Tag added successfully.',
      setLoading: setIsOperationLoading,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const onDeleteTag = async (data: any) => {
    setIsOperationLoading(true)
    const res = await deleteTag(data)
    if (res.status === 200) {
      toastMessage('success', `Tag deleted successfully.`)
      setIsOperationLoading(false)
      refetch()
    } else {
      toastMessage('error', `Somethig went wrong.`)
      setIsOperationLoading(false)
    }
  }

  const onUpdateTag = async (tagId: string, newTag: string) => {
    setIsOperationLoading(true)
    const data: {[key: string]: string[]} = {}
    data[tagId] = [newTag]
    const res = await updateTag(data)
    if (res.status === 200) {
      toastMessage('success', `Tag Updated successfully.`)
      setIsOperationLoading(false)
      refetch()
    } else {
      toastMessage('error', `Somethig went wrong.`)
      setIsOperationLoading(false)
    }
  }

  return {
    tags: response?.data || [],
    isFetchingTagData: isFetching,
    onAddTag,
    refetch,
    onDeleteTag,
    onUpdateTag,
    isOperationLoading,
    onSortingChange
  }
}

export default useGetProductTags
