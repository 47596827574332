import {useContext, useState} from 'react'
import Chart from '../components/GanttChart'
import {GanttChartPageContext, ProjectDetailsContext} from '../context'
import useGetGanttChartData from '../hooks/ganttChart/useGetGanttChartData'
import GanttChartPageHeader from '../sections/headers/GanttChartPageHeader'
import useGanttChartOperations from '../hooks/ganttChart/useGanttChartOperations'

const GanttChart = () => {
  const {hasProjectPermission} = useContext(ProjectDetailsContext)
  const {ganttChartData, filtersData, isLoading: isLoadingGanttChartData, onSearch: onSearchGanttChartData, refetch: refetchGanttChartData} = useGetGanttChartData()
  const {updateGanttChart, sortGanttChartRows, isLoading: isLoadingOperation} = useGanttChartOperations()
  const [chartView, setChartView] = useState('month')
  const [chartState, setChartState] = useState([])

  const contextValue = {
    hasGanttChartPermission: hasProjectPermission,

    ganttChartData,
    filtersData,
    isLoadingGanttChartData,
    onSearchGanttChartData,
    refetchGanttChartData,

    chartView,
    setChartView,

    chartState,
    setChartState,

    updateGanttChart,
    sortGanttChartRows,
    isLoadingOperation,
  }

  return (
    <GanttChartPageContext.Provider value={contextValue}>
      <GanttChartPageHeader />
      <Chart />
    </GanttChartPageContext.Provider>
  )
}

export default GanttChart
