import Api from '../../../services/Api'

export const getCsvData = (filters: any) => {
  const url = '/analytics/replenishment/po/reorder/csvdata'
  return Api.get(`${url}`, filters).then((d: any) => {
    return d?.data
  })
}

export const multyProductAddTags = async (data: any) => {
  const url = `/analytics/replenishment/tags/products`
  const response = await Api.post(url, data, null, null)
  return response
}

export async function deleteTag(data: any) {
  const url = `/analytics/replenishment/tags`
  const response = await Api.delete(url, data, null, null)
  return response
}

export async function updateTag(data: any) {
  const url = `/analytics/replenishment/tags`
  const response = await Api.put(url, data, null, null)
  return response
}