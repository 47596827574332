import * as yup from 'yup'

export const ClassifiedAsValidationSchema = (classifiedAsList: any, isEdit: boolean, row: any) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Name is required')
      .trim()
      .test('unique-name', 'This classified name already exists', function (value) {
        if (!value) return true

        const normalizedValue = value.toLowerCase().trim()
        const isDuplicate = classifiedAsList.some((item: any) => {
          if (isEdit && row?.id === item.id) return false
          return item?.name?.toLowerCase().trim() === normalizedValue
        })

        return !isDuplicate
      }),
  })
