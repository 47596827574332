import {PageLink} from '../../_metronic/layout/core'
import Navigation from '../../components/Navigation/Navigation'
import ProductTags from './layouts/ProductTags'
import TagsMaster from './layouts/TagsMaster'

const TagsManagementCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
  {
    title: 'Dashboard',
    path: '/analytics/replenishment/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

const TagsManagementPage = () => {
  return (
    <Navigation
      baseUrl='/analytics/replenishment/product-tags'
      breadcrumbs={TagsManagementCrumbs}
      defaultKey='product-tags'
      navigationData={[
        {
          key: 'product-tags',
          label: 'Product Tags',
          component: <ProductTags />,
        },
        {
          key: 'tags-master',
          label: 'Tags Master',
          component: <TagsMaster />,
        },
      ]}
    />
  )
}

export default TagsManagementPage
