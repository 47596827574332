
import {useState} from 'react'
import { BackOrderContext } from './context'
import { Pagination } from '../../utils/pagination'
import BackOrderHeader from './sections/headers/BackOrderHeader'
import BackOrderTable from './sections/table/BackOrderTable'
import useGetBackOrderListing from './hooks/useGetBackOrderListing'
import { PageTitle } from '../../_metronic/layout/core'
import { supplierManagementBreadCrumbs } from './utils'

const BackOrder = () => {
  const {
    backOrderListing,
    isLoading,
    filters,
    onSearch,
    onPageChange,
    onSortingChange,
    pagination,
  } = useGetBackOrderListing()

  const [showModal, setShowModal] = useState(false)
  const [checkedRows, setCheckedRows] = useState<any>([])

  const contextValue = {
    backOrderListing,
    isLoading,
    filters,
    pagination,
    onSearch,
    onSortingChange,
    showModal,
    setShowModal,
    checkedRows,
    setCheckedRows,
  }

  return (
    <BackOrderContext.Provider value={contextValue}>
      <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Backorder</PageTitle>
      <BackOrderHeader />
      <BackOrderTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </BackOrderContext.Provider>
  )
}

export default BackOrder
