import {useEffect, useState} from 'react'
import TableCellInput from '../../../components/Table/TableCellInput'
import useUpdatePurchasingOrderQuantity from '../hooks/useUpdatePOQuantity'
import {parseReorderingTableRowData} from '../utils'
import usePermission from '../../../hook/usePermission'
import Date from '../../../components/Date/Date'

function ReorderingTableRow({
  row: rowData,
  monthly_column_keys,
  index,
  inputRef,
  inputRefArr,
}: any) {
  const row = parseReorderingTableRowData(rowData)
  const [updatedQty, setQty] = useState(row['quantity'])
  const [isVisible, setVisible] = useState(false)
  const {updatePurchasingOrderQuantity} = useUpdatePurchasingOrderQuantity()
  const {hasPermission} = usePermission()
  useEffect(() => {
    setQty(row['quantity']);
  }, [row]);

  const handleCellChange = (updatedQty: any) => {
    if (updatedQty) {
      updatePurchasingOrderQuantity(
        row['sku'],
        updatedQty,
        row['sale_history_months'],
        row['days_to_replenishment'],
        row['suggested_order_qty'],
        row['total_sold_30'],
        row['weeks_on_hand'],
        row['turn_rate'],
        row['to_order_qty'],
        row['quantity_available'],
        row['quantity_incoming'],
        row['incremental_quantity'],
        row['title']
      );
      setQty(updatedQty);
      setVisible(false);
    }
  };
  return (
    <tr id={index}>
      <td>
        <div className='align-items-center'>{row['sku']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['title']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['primary_supplier']}</div>
      </td>
      <td className={`bg-light-success`}>
        <div
          className={`align-items-center order-quantity-input justify-content-center text-center`}
          onClick={() => setVisible(true)}
          ref={inputRef}
        >
          {hasPermission('analytics_replenishment_dashboard', 'write') ? (
            <>
              {isVisible ? (
                <TableCellInput
                  inputType='number'
                  onCellChange={handleCellChange}
                  exitEvent={(isFocusedOut) => (isFocusedOut ? setVisible(false) : null)}
                  min={0}
                  defaultValue={updatedQty}
                  currentIndex={index}
                  refArray={inputRefArr}
                />
              ) : updatedQty ? (
                <span
                  className='border p-3 bg-body w-100px'
                  style={{display: 'inline-block', borderRadius: '4px'}}
                >
                  {updatedQty}
                </span>
              ) : (
                <span
                  className='border p-3 bg-body w-100px'
                  style={{display: 'inline-block', borderRadius: '4px'}}
                >
                  {row['quantity']}
                </span>
              )}
            </>
          ) : (
            <span
              className='border p-3 bg-body w-100px'
              style={{display: 'inline-block', borderRadius: '4px'}}
            >
              {row['quantity']}
            </span>
          )}
        </div>
      </td>
      <td>
        <div className='text-center'>{row['total_cost']}</div>
      </td>
      <td>
        <div className='text-center'>{row['quantity_available']}</div>
      </td>
      <td>
        <div className='text-center'>{row['quantity_incoming']}</div>
      </td>
      <td>
        <div className='text-center'>{row['total_sold_30']}</div>
      </td>
      <td>
        <div className='text-center'>{row['reorder_point']}</div>
      </td>
      <td>
        <div className='text-center'>{row['incremental_quantity']}</div>
      </td>
      <td>
        <div className='text-center'>{row['suggested_order_qty']}</div>
      </td>
      <td>
        <div className='text-center'>{row['to_order_qty']}</div>
      </td>
      {monthly_column_keys &&
        monthly_column_keys !== undefined &&
        monthly_column_keys.map((column: any) => (
          <td>
            <div className='align-items-center'>{rowData[column]}</div>
          </td>
        ))}
      <td>
        <div className='align-items-center'>{row['cost']}</div>
      </td>
      <td>
        <div className='text-center'>{row['sale_history_months']}</div>
      </td>
      <td>
        <div className='text-center'>{row['days_to_replenishment']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['brand']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['classification']}</div>
      </td>
      <td>
        <div className='text-center'>{row['weeks_on_hand']}</div>
      </td>
      <td>
        <div className='text-center'>{row['turn_rate']}</div>
      </td>
      <td>
        <div className='align-items-center'>{row['modified_by']}</div>
      </td>
      <td>
        <Date date={rowData['modified_at']} />
      </td>
    </tr>
  )
}

export default ReorderingTableRow
