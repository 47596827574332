import Api from '../../../services/Api'

export const getPriceList = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getPriceListById = (url: string) => {
  return Api.get(`${url}`).then((d: any) => {
    return d.data
  })
}

export function updatePriceListById(url: string, data: any) {
  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export const uploadCsv = (url: string, data: any) => {
  const response = Api.put(url, data, null, null)
  return response
}


export const getAPi = (url: string) => {
  return Api.get(`${url}`).then((d: any) => {
    return d.data
  })
}

