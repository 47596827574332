import {useContext} from 'react'
import Search from '../../../../components/Search'
import {ProductsTagsContext} from '../../context'

const ProductTagsSearchComponent = () => {
  const {onSearch} = useContext(ProductsTagsContext)
  return (
    <Search
      onSearch={(searchTerm: string) => {
        onSearch && onSearch(searchTerm)
      }}
    />
  )
}

export default ProductTagsSearchComponent
