import React from 'react';
import usePermission from '../../../../hook/usePermission'
import Date from '../../../../components/Date/Date';
import {formatPrice} from '../../../../utils/common'
import Initials from '../../../../components/Initials'
import {Link} from 'react-router-dom'
import { getBadgeColor } from '../../../../utils/badge';
import { capitalizeFirstLetter } from '../../../../utils/string';

const OrderHistoryTableRow = ({row}: any) => {
  const {hasPermission} = usePermission()

  return (
    <tr>
      {/* Order Date */}
      <td>
        <Date date={row.order_created_date_time} />
      </td>
      {/* Order ID */}
      <td>
        {row.order_id && (
          <Link
            to={`/orders/all-orders/summary?id=${row.order_id}`}
            className='text-dark text-hover-primary fs-6 text-decoration-underline'
          >
            #{row.order_id}
          </Link>
        )}
      </td>
      <td>
        {' '}
        <span className={`badge ${getBadgeColor(row.order_status, 'light')} badge-lg`}>
          <div className='align-items-center'>{capitalizeFirstLetter(row?.order_status)}</div>
        </span>
      </td>
      {/* Customer Details */}
      <td>
        <div className='d-flex'>
          <div className='symbol symbol-circle symbol-35px me-3'>
            <Initials text={row.name[0]} />
          </div>
          <div className='d-flex flex-column'>
            <Link
              to={`/customers/all-customer/details?id=${row.customer_id}`}
              className='text-dark fw-semibold text-hover-primary'
            >
              {row.name}
            </Link>
            <span className='align-items-center text-muted'>{row.email}</span>
          </div>
        </div>
      </td>
      {/* Company Name */}
      <td>{row.company}</td>
      {/* Total Quantity */}
      <td className='text-end'>{row.total_quantity}</td>
      <td className='text-end'>{row.total_quantity_in_packets ? row.total_quantity_in_packets : '-'}</td>
      {/* Product Price After Discount */}
      <td className='text-end'>{formatPrice(row.product_price_after_discount, false)}</td>
      {/* Product Total */}
      <td className='text-end'>{formatPrice(row.product_total, false)}</td>
      {/* Order Total Including Tax */}
      <td className='text-end'>{formatPrice(row.order_total_including_tax, false)}</td>
      {/* Action Column */}
      {hasPermission('orders', 'write') && (
        <td>
          <div className='d-flex justify-content-center'>
            {/* Add overlay modal or action buttons here */}
          </div>
        </td>
      )}
    </tr>
  )
}

export default OrderHistoryTableRow;
