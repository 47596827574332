import {useForm} from 'react-hook-form'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {SectionForm} from '../../../../components/SectionForm'
import {useContext} from 'react'
import {ClassifiedAsPageContext} from '../../context'
import {yupResolver} from '@hookform/resolvers/yup'
import {ClassifiedAsValidationSchema} from '../../validations'

interface ClassifiedAsModalTypes {
  isEdit?: boolean
  showModal: boolean
  onClose: () => void
  row?: any
}

const ClassifiedAsModal = ({isEdit = false, showModal, onClose, row}: ClassifiedAsModalTypes) => {
  const {classifiedAsList, createClassifiedAs, updateClassifiedAs, isLoadingOperations, isLoading} =
    useContext(ClassifiedAsPageContext)

  const {
    register,
    handleSubmit,
    formState: {errors, isDirty, isValid},
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ClassifiedAsValidationSchema(classifiedAsList, isEdit, row)),
    defaultValues: {
      name: isEdit ? row?.classifiedAs : '',
    } as any,
  })

  const onSubmit = (data: any) => {
    if (isEdit) {
      updateClassifiedAs(data, row?.id)
    } else {
      createClassifiedAs(data)
    }
  }

  return (
    <ConfirmationModal
      show={showModal}
      onClose={onClose}
      title={isEdit ? 'Edit Classified As' : 'Create Classified As'}
      actionName={isEdit ? 'Update' : 'Create'}
      actionBtnClass='btn-primary'
      onAction={handleSubmit(onSubmit)}
      isDataLoading={isLoading}
      isOperationLoading={isLoadingOperations}
      isDisabled={!isDirty || !isValid}
      body={
        <SectionForm
          id='create-classified-as'
          register={register}
          errors={errors}
          inputs={[
            {
              id: 'name',
              registerKey: 'name',
              label: 'Enter Classified Name',
              required: true,
              maxLength: 60,
            },
          ]}
        />
      }
    />
  )
}

export default ClassifiedAsModal
