import {formatForLink} from '../../../../utils/string'
import {convertPercentageToEstimation} from '../../utils'

export const ticketNameColumnCell = (data: any, projectId: string) => {
  return (
    <div className='pe-10'>
      {data.card_identifier && (
        <a
          className='text-primary cursor-pointer'
          href={`/projects/all-projects/${projectId}/ticket/${data.ticketID}/${formatForLink(
            data.card_identifier
          )}`}
        >
          {data.card_identifier} -
        </a>
      )}
      <span className={data?.data ? 'fw-bold' : ''}>{data.text}</span>
    </div>
  )
}

export const estimationColumnCell = (data: any) => {
  return (
    <div className={data?.progress > 100 ? 'text-danger' : ''}>
      {convertPercentageToEstimation(data?.progress, data?.start, data?.end) || '0h 0m'}
    </div>
  )
}

export const spentTimeColumnCell = (data: any) => {
  const isPatent = data?.data
  return <div>{isPatent ? data?.total_spent_time : data?.spent_time || '0h 0m'}</div>
}

export const progressColumnCell = (data: any) => {
  const isPatent = data?.data
  const progress = isPatent
    ? data?.total_progress > 100
      ? 100
      : data?.total_progress?.toFixed(2) || 0
    : data?.taskProgress > 100
    ? 100
    : data?.taskProgress?.toFixed(2) || 0
  return (
    <div className='d-flex justify-content-end gap-2 pe-3'>
      <span>{progress}%</span>{' '}
      <svg width='20' height='20' viewBox='-2 -2 40 40'>
        <circle cx='18' cy='18' r='16' fill='none' stroke='#f3f4f6' strokeWidth='6' />
        <circle
          cx='18'
          cy='18'
          r='16'
          fill='none'
          stroke={'#466AEC'}
          strokeWidth='6'
          strokeDasharray={`${progress} 100`}
          transform='rotate(-90 18 18)'
        />
      </svg>
    </div>
  )
}
