import React from 'react'
import FlavorTableRow from './FlavorTableRow'
import {DynamicTable} from '../../../../../components/DynamicTable'
import {flavorsColumns} from '../../../utils'

const FlavorTable = ({data, loading}: any) => {

  const onShortingChange = (key: string, order: 'asc' | 'desc') => {
    data.sort((a: any, b: any) => {
      if (a[key] < b[key]) return order === 'asc' ? -1 : 1
      if (a[key] > b[key]) return order === 'asc' ? 1 : -1
      return 0
    })
  }

  return (
    <DynamicTable
      data={data}
      sortableColumns={flavorsColumns}
      TableRow={FlavorTableRow}
      onSortingChange={onShortingChange}
      filters={{}}
      loading={loading}
      tableClass='table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'
    />
  )
}

export default FlavorTable
