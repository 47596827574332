import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

export const useGetOrderHistory = (productId: any) => {
  const Api = useApi()

  const initialFilters = {
    page: true,
    limit: 30,
    sort_by: 'order_created_date_time/-1',
  }

  const {filters, onSortingChange,onPageChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data, isFetching} = Api.useGetQuery(
    `/analytics/sold-products/order/history/${productId}`,
    {
      queryId: 'get-OrderHistory',
      filters: filters,
    }
  )

  return {
    OrderHistoryData: data?.data || [],
    pagination: data?.meta?.pagination || {},
    ProductName: data?.meta?.product_name || '',
    isLoading: isFetching,
    orderFilters :filters,
    onPageChange,
    onSortingChange
  }
}
