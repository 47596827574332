import * as Yup from 'yup'

export const TicketDetailsFormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Ticket Name is required')
    .max(255, 'Ticket Name cannot be longer than 255 characters'),
})

export const AddSpentTimeFormSchema = Yup.object().shape({
  spent_time_created_at: Yup.string().required('Date Is Required'),
  spent_time: Yup.string().required('Spent Time is required'),
})

export const EditSpentTimeFormSchema = Yup.object().shape({
  spent_time_created_at: Yup.string().required('Date Is Required'),
  spent_time_diff: Yup.string().required('Spent Time is required'),
})

export const createTFromTValidationSchema = (menuEvent: any) =>
  Yup.object().shape({
    title: Yup.string().when('ticketSource', {
      is: 'new',
      then: (schema) =>
        schema.required('Ticket Name is required.').max(255, 'Maximum character limit is 255.'),
      otherwise: (schema) => schema.nullable(),
    }),
    parentCardId: Yup.number().when('ticketSource', {
      is: (ticketSource: string) => ticketSource === 'existing' && menuEvent === 'parent-ticket',
      then: (schema) => schema.required('Existing ticket is required'),
      otherwise: (schema) => schema.nullable(),
    }),
    childCardId: Yup.number().when('ticketSource', {
      is: (ticketSource: string) => ticketSource === 'existing' && menuEvent === 'sub-ticket',
      then: (schema) => schema.required('Existing ticket is required'),
      otherwise: (schema) => schema.nullable(),
    }),
  })
