import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {ClassificationDataDropdown} from '../dropdown/ClassificationDataDropdown'

const ClassificationActionModal = ({row}: any) => {
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={<ClassificationDataDropdown id={row?.id}/>}
        rootClose={true}
      />
    </>
  )
}

export default ClassificationActionModal
