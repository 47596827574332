import {TicketDetailsContext, TicketDetailsPageContext} from '../context'
import useGetTicketDetails from '../hooks/useGetTicketDetails'
import useCommentOperations from '../hooks/useCommentOperations'
import useGetCustomFieldsList from '../hooks/useGetCustomFieldsList'
import useGetCommentsList from '../hooks/useGetCommentsList'
import useCustomFieldsOperations from '../hooks/useCustomFieldsOperations'
import useTicketOperations from '../hooks/useTicketOperations'
import useGetProjectKanbanStages from '../hooks/useGetProjectKanbanStages'
import useGetProjectModules from '../hooks/useGetProjectModules'
import useGetProjectStatuses from '../hooks/useGetProjectStatuses'
import useGetProjectMembers from '../hooks/useGetProjectMembers'
import TicketForm from '../sections/TicketForm'
import {useContext, useState} from 'react'
import {toTitleCase} from '../../../utils/string'
import useGetTicketActivitiesHistory from '../hooks/useGetTicketActivitiesHistory'
import useGetProjectPriorities from '../hooks/useGetProjectPriorities'
import useGetTicketSpentTimeHistory from '../hooks/useGetTicketSpentTimeHistory'
import useSpentTimeHistoryOperations from '../hooks/useSpentTimeHistoryOperations'
import useGetProjectTickets from '../hooks/useGetProjectTickets'

export default function TicketDetails() {
  const {
    hasProjectPermission,
    projectStatus,
    projectName,
    projectId,
    ticketId,
    cardIdentifier,
  }: any = useContext(TicketDetailsContext)
  const {ticketDetails, isLoading: isLoadingTicketDetails} = useGetTicketDetails()
  const {customFieldsList, isLoading: isLoadingCustomFieldsList} = useGetCustomFieldsList()
  const {commentsList, isLoading: isLoadingCommentsList} = useGetCommentsList()
  const {projectMembers, isLoading: isLoadingProjectMembers} = useGetProjectMembers()
  const {projectKanbanStages, isLoading: isLoadingProjectKanbanStages} = useGetProjectKanbanStages()
  const {projectModules, isLoading: isLoadingProjectModules} = useGetProjectModules()
  const {projectStatuses, isLoading: isLoadingProjectStatuses} = useGetProjectStatuses()
  const {projectPriorities, isLoading: isLoadingProjectPriorities} = useGetProjectPriorities()
  const {updateCustomField, isLoading: isLoadingCFieldsOperation} = useCustomFieldsOperations()
  const {
    createTicket,
    updateTicket,
    deleteTicket,
    removeLinkedTicket,
    isLoading: isLoadingTicketOperation,
  } = useTicketOperations()
  const {projectTickets, isLoading: isLoadingProjectTickets} = useGetProjectTickets()
  const {
    createComment,
    editComment,
    deleteComment,
    isLoading: isLoadingCommentOperation,
  } = useCommentOperations()
  const {
    ticketActivitiesHistory,
    isLoading: isLoadingTicketActivitiesHistory,
    pagination,
    onLoadMore,
  } = useGetTicketActivitiesHistory()
  const {ticketSpentTimeHistory, SpentTimePagination, isSpentTimeLoading, onLoadMoreSpentTime} =
    useGetTicketSpentTimeHistory()
  const {
    deleteSpentTime,
    isLoading: isTimeLogOperationLoading,
    updateSpentTime,
  } = useSpentTimeHistoryOperations()

  const [isTicketEditable, setIsTicketEditable] = useState(false)

  const contextValue = {
    hasTicketWritePermission: hasProjectPermission,

    projectStatus,
    projectName: toTitleCase(projectName),
    projectId,
    ticketId,
    cardIdentifier: cardIdentifier?.toUpperCase(),

    ticketDetails,
    isLoadingTicketDetails,

    projectMembers,
    isLoadingProjectMembers,

    projectKanbanStages,
    isLoadingProjectKanbanStages,

    projectModules,
    isLoadingProjectModules,

    projectStatuses,
    isLoadingProjectStatuses,

    projectPriorities,
    isLoadingProjectPriorities,

    projectTickets,
    isLoadingProjectTickets,

    createTicket,
    updateTicket,
    deleteTicket,
    removeLinkedTicket,
    isLoadingTicketOperation,

    createComment,
    editComment,
    deleteComment,
    isLoadingCommentOperation,

    customFieldsList,
    isLoadingCustomFieldsList,

    commentsList,
    isLoadingCommentsList,

    updateCustomField,
    isLoadingCFieldsOperation,

    isTicketEditable,
    setIsTicketEditable,

    ticketActivitiesHistory,
    isLoadingTicketActivitiesHistory,
    pagination,
    onLoadMore: onLoadMore,

    ticketSpentTimeHistory,
    SpentTimePagination,
    isSpentTimeLoading,
    onLoadMoreSpentTime,

    deleteSpentTime,
    updateSpentTime,
    isTimeLogOperationLoading,
  }

  return (
    <TicketDetailsPageContext.Provider value={contextValue}>
      <TicketForm />
    </TicketDetailsPageContext.Provider>
  )
}
