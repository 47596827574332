import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

export const useGetPriceChangeLog = (productId: any) => {
  const Api = useApi()

  const initialFilters = {
    page: true,
    limit: 30,
    sort_by: 'updated_at/-1',
  }

  const {filters,onSortingChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data, isFetching} = Api.useGetQuery(
    `/analytics/sold-products/liquidated-product/logs/${productId}`,
    {
      queryId: 'get-Price-Change-log',
      filters: filters,
    }
  )

  return {
    PriceChangeLogData: data?.data || [],
    ProductName: data?.meta?.product_name || '',
    isLoading: isFetching,
    priceChangeLogFilters :filters,
    onSortingChange
  }
}
