import {useContext, useState} from 'react'
import Search from '../../../../components/Search'
import ClassifiedAsModal from '../model/ClassifiedAsModal'
import {ClassifiedAsPageContext} from '../../context'
import usePermission from '../../../../hook/usePermission'

const ClassifiedAsPageHeader = () => {
  const {hasPermission} = usePermission()
  const [showModal, setShowModal] = useState(false)
  const {onSearch} = useContext(ClassifiedAsPageContext)
  return (
    <div className='d-flex justify-content-between align-items-center mb-10'>
      <Search onSearch={onSearch} />
      {hasPermission('analytics_replenishment_classified as', 'write') && <button className='btn btn-primary' onClick={() => setShowModal(true)}>
        Create
      </button>}
      {showModal && <ClassifiedAsModal showModal={showModal} onClose={() => setShowModal(false)} />}
    </div>
  )
}

export default ClassifiedAsPageHeader
