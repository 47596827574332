import {useQuery} from 'react-query'
import {useEffect} from 'react'
import useFilters from '../../../hook/useFilters'
import {getProductLimitData} from '../services'
import {useLocation, useNavigate} from 'react-router-dom'

export const useGetProductLimitData = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const page = searchParams.get('page')

  const initialFilters = {
    filter: '',
    page: page || 1,
    limit: 30,
    sort_by: 'name/1',
  }

  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)
  const {data, isFetching, refetch} = useQuery(`get-product-limit-report`, () =>
    getProductLimitData('/analytics/replenishment/product/limit/report', filters)
  )

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSearch = (searchValue: string) => {
    setMultipleFilters({
      filter: searchValue,
    })
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  const onSortingChange = (key: string, value: string) => {
    const direction = value === 'asc' ? 1 : -1
    const sortFlag = key + '/' + direction
    setMultipleFilters({
      sort_by: sortFlag,
    })
    navigate(`${location.pathname}`)
  }

  function onChangeCustomerFilter(name: string) {
    setMultipleFilters({
      rep_name: name
    })
    navigate(`${location.pathname}`)
  }

  return {
    products: data?.['data'] || [],
    isLoading: isFetching,
    pagination: data?.['meta']?.['pagination'] || {},
    onSortingChange,
    onChangeCustomerFilter,
    onPageChange,
    onSearch,
    filters,
  }
}
