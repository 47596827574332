import {Navigate, useSearchParams, useLocation} from 'react-router-dom'
import {PageTitle} from '../../_metronic/layout/core'
import {NavigationProps} from './NavigationTypes'
import {NavLink} from './components/NavLink'

const Navigation = ({
  navigationData,
  baseUrl,
  className = '',
  defaultKey = navigationData?.[0]?.key,
  breadcrumbs = false,
  isLoading,
  pageTitle: propPageTitle = false,
  show = true,
  paramKey = 'view',
  viewSingleComponent = false,
}: NavigationProps) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  if (!show) return null

  const visibleNavigationData = navigationData.filter((item) => item.show !== false)
  const isSingleComponent = visibleNavigationData.length === 1 && !viewSingleComponent;

  // If no navigation items, render just the page title if provided
  if (!visibleNavigationData?.length) {
    return (
      <>
        {(typeof breadcrumbs !== 'boolean' || typeof propPageTitle !== 'boolean') &&
          (!isLoading ? (
            <PageTitle breadcrumbs={typeof breadcrumbs !== 'boolean' ? breadcrumbs : []}>
              {typeof propPageTitle !== 'boolean' ? propPageTitle : undefined}
            </PageTitle>
          ) : (
            <PageTitle
              className='placeholder bg-gray-200 w-150px h-30px rounded-2'
              content={
                typeof breadcrumbs !== 'boolean' ? (
                  <span className='placeholder bg-gray-200 w-200px h-10px mt-2 rounded-3'></span>
                ) : null
              }
            >
              {' '}
            </PageTitle>
          ))}
      </>
    )
  }

  // For single component, don't use URL params
  if (isSingleComponent) {
    const currentNavItem = visibleNavigationData[0]
    return (
      <>
        {(typeof breadcrumbs !== 'boolean' || typeof propPageTitle !== 'boolean') &&
          (!isLoading ? (
            <PageTitle breadcrumbs={typeof breadcrumbs !== 'boolean' ? breadcrumbs : []}>
              {typeof propPageTitle !== 'boolean' ? propPageTitle : currentNavItem?.label}
            </PageTitle>
          ) : (
            <PageTitle
              className='placeholder bg-gray-200 w-150px h-30px rounded-2'
              content={
                typeof breadcrumbs !== 'boolean' ? (
                  <span className='placeholder bg-gray-200 w-200px h-10px mt-2 rounded-3'></span>
                ) : null
              }
            >
              {' '}
            </PageTitle>
          ))}
        {currentNavItem?.component}
      </>
    )
  }

  // Multiple components - use URL params
  const currentKey = searchParams.get(paramKey) || defaultKey
  const isValidKey = visibleNavigationData.map((item) => item.key).includes(currentKey)
  const currentNavItem = visibleNavigationData.find((item) => item.key === currentKey)

  const pageTitle =
    typeof propPageTitle !== 'boolean'
      ? propPageTitle
      : breadcrumbs
      ? currentNavItem?.label
      : undefined

  if (!searchParams.get(paramKey)) {
    return <Navigate to={`${baseUrl}?${paramKey}=${defaultKey}`} replace state={location.state} />
  }

  if (!isValidKey) {
    return <Navigate to={`${baseUrl}?${paramKey}=${defaultKey}`} replace state={location.state} />
  }

  return (
    <>
      {(typeof breadcrumbs !== 'boolean' || typeof propPageTitle !== 'boolean') &&
        (!isLoading ? (
          <PageTitle breadcrumbs={typeof breadcrumbs !== 'boolean' ? breadcrumbs : []}>
            {pageTitle}
          </PageTitle>
        ) : (
          <PageTitle
            className='placeholder bg-gray-200 w-150px h-30px rounded-2'
            content={
              typeof breadcrumbs !== 'boolean' ? (
                <span className='placeholder bg-gray-200 w-200px h-10px mt-2 rounded-3'></span>
              ) : null
            }
          >
            {' '}
          </PageTitle>
        ))}

      <ul
        className={`nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10 ${className}`}
      >
        {visibleNavigationData.map((item) => (
          <NavLink
            key={item.key}
            keyValue={item.key}
            label={item.label}
            baseUrl={baseUrl}
            icon={item.icon}
            disabled={item.disabled ?? false}
            paramKey={paramKey}
          />
        ))}
      </ul>
      {currentNavItem?.component}
    </>
  )
}

export default Navigation
