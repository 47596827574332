import { PageLink } from "../../_metronic/layout/core"
import { Navigation } from "../../components/Navigation"
import useMeta from "../../hook/useMeta"
import LiquidationReport from "./layouts/LiquidationReport"

const ProjectSettingsPageCrumbs: Array<PageLink> = [
  {
    title: 'All Products',
    path: '/products/all-products',
    isSeparator: false,
    isActive: false,
  },
]

const ProductReports = () => {
  useMeta('Projects Settings')

  return (
    <Navigation
      baseUrl={`/products/liquidation`}
      pageTitle={'Liquidation'}
      breadcrumbs={ProjectSettingsPageCrumbs}
      navigationData={[
        {
          key: 'liquidation',
          label: 'Liquidation',
          component: <LiquidationReport />,
        },
      ]}
    />
  )
}

export default ProductReports
