import {getRandomColor} from '../../../utils/colors'
import {generateInitials, isEmpty} from '../../../utils/common'
import {formatDate} from '../../../utils/date'
import {capitalizeFirstLetter, truncateText} from '../../../utils/string'

//<---------------------- Agile Board Page ----------------------->

export const agileTicketData = (data: any) => {
  if (isEmpty(data)) return {}

  return {
    ticketId: data?.ticketID,
    priorityColor:
      data?.priorityLabel === 'Normal'
        ? 'bg-secondary'
        : data?.priorityLabel === 'Low'
        ? 'bg-success'
        : data?.priorityLabel === 'Medium'
        ? 'bg-warning'
        : data?.priorityLabel === 'High'
        ? 'bg-danger'
        : 'bg-secondary',
    ticketInitial: data?.card_identifier,
    ticketTitle: truncateText(data?.title, 65),
    assigneeInitialColor: getRandomColor(),
    assigneeInitial: generateInitials(data?.asignee_name, 1),
    assigneeName: data?.asignee_name || null,
    defaultAssignee: data?.assignee,
    isResolved: data?.is_resolved,
    dueDate: data?.due_date,
    dueDateLabel: data?.due_date ? formatDate(data?.due_date, true, true) : null,
  }
}

//<---------------------- ListView Page ----------------------->

export const agileBoardTicketListData = (row: any) => {
  if (isEmpty(row)) return {}

  return {
    id: row?.ticked_id,
    ticketIdentifier: row?.card_identifier || '-',
    ticketName: row?.title || '-',
    module: row?.module_name || '-',
    assignee: row?.assignee,
    defaultAssignee: row?.assignee_email,
    dueDate: row?.due_date,
    dueDateLabel: formatDate(row?.due_date, true),
    createdDate: row?.created_at,
    updatedDate: row?.updated_at,
    priority: row?.priority_label || '-',
    stage: row?.column_name || '-',
  }
}

//<---------------------- Board Settings Page ----------------------->

export const projectMappingDetailsData = (data: any) => {
  if (isEmpty(data)) return {}

  return {
    projectType: data?.project_type,
    tableId: data?.table_id,
    columnKey: data?.column_key,
    ticketName: data?.ticket_name,
    defaultAssignee: data?.default_assignee,
  }
}

export const boardColumnsData = (data: any) => {
  if (isEmpty(data)) return []

  return data.map((column: any) => {
    return {
      columnId: column['column_id'],
      columnName: column['name'] || '-',
      cards: column['cards'] || 0,
      description: column['description'],
      mappedColumnValue: column['mapped_column_value'] || '',
      isVisible: column['is_visible'] ?? '-',
      visibility: column['is_visible'] ? 'Active' : 'Inactive',
      isResolved: column['is_resolved'],
    }
  })
}

export const boardModulesData = (data: any) => {
  if (isEmpty(data)) return []

  return data.map((module: any) => {
    return {
      moduleId: module['module_id'],
      moduleName: capitalizeFirstLetter(module['name']) || '-',
      cards: module['cards'] || 0,
      description: module?.description || '',
      isVisible: module['is_visible'] ?? '-',
      visibility: module['is_visible'] ? 'Active' : 'Inactive',
    }
  })
}

export const boardCustomFieldsData = (data: any) => {
  if (isEmpty(data)) return []

  return data.map((field: any) => {
    return {
      cardCounts: field['card_counts'] || 0,
      customFieldName: field['custom_field_name'] || '-',
      fieldType: field['field_type'] || '-',
      groupValues: field['group_values'] || '',
      id: field['id'] || 0,
      isGlobal: field['is_global'] || false,
      type: field['is_global'] ? 'global' : 'custom',
      isVisible: field['is_visible'] || false,
      visibility: field['is_visible'] ? 'Active' : 'Inactive',
      sortId: field['sort_id'] || 0,
      mappedColumnValue: field['column_value'] || '',
      isReadOnly: field['read_only'] || false,
    }
  })
}

const parseTimeStringToMinutes = (timeString: string) => {
  const timeRegex = /(\d+)\s*h|\s*(\d+)\s*m/g;
  let totalMinutes = 0;
  let match;

  while ((match = timeRegex.exec(timeString)) !== null) {
    if (match[1]) {
      totalMinutes += parseInt(match[1], 10) * 60;
    }
    if (match[2]) {
      totalMinutes += parseInt(match[2], 10);
    }
  }

  return totalMinutes;
}


// parsing for members table
export function dataToMembersReportRow(row: any) {
  const totalEstimationInMinutes = parseTimeStringToMinutes(row['total_estimation'])
  const totalSpentInMinutes = parseTimeStringToMinutes(row['total_spent_time'])
  const isOverEstimation = totalSpentInMinutes > totalEstimationInMinutes

  return {
    id: row['id'] || '',
    assignee: row['assignee'] || '-',
    totalCards: row['total_cards'] || 0,
    totalEstimation: row['total_estimation'] || '-',
    totalSpentTime: row['total_spent_time'] || '-',
    totalRemainingTime: row['total_remaining_time'] || '-',
    progressPercentage: row['progress_percentage'],
    email: row['username'] || 'Unassigned',
    isOverEstimation,
  }
}

// parsing for tasks table
export function dataToTasksReportRow(row: any) {
  const totalEstimationInMinutes = parseTimeStringToMinutes(row['estimation'])
  const totalSpentInMinutes = parseTimeStringToMinutes(row['spent_time'])
  const isOverEstimation = totalSpentInMinutes > totalEstimationInMinutes

  return {
    id: row['id'] || '',
    cardIdentifier: row['card_identifier'] || '-',
    cardTitle: row['card_title'] || '-',
    cardId: row['card_id'] || '',
    projectId: row['project_id'] || '',
    projectName: row['project_name'] || '-',
    assignee: row['assignee'] || '-',
    moduleName: row['module_name'] || '-',
    estimation: row['estimation'] || '-',
    spentTime: row['spent_time'] || '-',
    remainingTime: row['remaining_time'] || '-',
    progressPercentage: row['progress'],
    resolved: row['is_resolved'],
    isOverEstimation,
  }
}

// parsing for time sheet table
export const timeSheetTableRowData = (data: any) => {
  if (isEmpty(data)) return {}

  return {}
}
