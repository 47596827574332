import {useState} from 'react'
import useApi from '../../../services/useApi'

const useBackOrderOperations = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isValidatingSku, setIsValidatingSku] = useState(false)

  const validateChildSku = (sku: string, setError: any) => {
    return Api.get(`/analytics/replenishment/backorder/sku/validate`, {
      filters: {sku: sku},
      setLoading: setIsValidatingSku,
      isToast: false,
      isErrorPageRedirection: false,
      setUseFormError: setError,
    })
  }

  const createBackOrderSKU = (payload: any, setError?: any) => {
    return Api.post(
      `/analytics/replenishment/backorder/sku/qty`,
      {data: payload},
      {
        setLoading: setIsLoading,
        successMessage: 'SKU added successfully.',
        setUseFormError: setError,
        refetch: 'back-order-listing',
        onError: ({error, response}) => {
          if (response?.response?.data?.records_processed > 0) {
            return {
              refetch: 'back-order-listing',
              error,
            }
          } else {
            return error
          }
        },
      }
    )
  }

  const updateBackOrderSKU = (productId: any, payload: any, setError: any) => {
    return Api.patch(`/analytics/replenishment/backorder/sku/qty/${productId}`, payload, {
      setLoading: setIsLoading,
      successMessage: 'SKU updated successfully.',
      setUseFormError: setError,
      refetch: 'back-order-listing',
    })
  }

  const deleteBackOrderSKU = (payload: any, productId?: number) => {
    return Api.delete(`/analytics/replenishment/backorder/sku/qty`, payload, {
      setLoading: setIsDeleting,
      successMessage: 'SKU deleted successfully.',
      refetch: 'back-order-listing',
    })
  }

  return {
    validateChildSku: validateChildSku,
    createBackOrderSKU: createBackOrderSKU,
    updateBackOrderSKU: updateBackOrderSKU,
    deleteBackOrderSKU: deleteBackOrderSKU,
    isValidatingSku: isValidatingSku,
    isDeleting: isDeleting,
    isLoading: isLoading,
  }
}

export default useBackOrderOperations
