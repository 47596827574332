import {useQuery} from 'react-query'
import {getAPi} from '../service'
import {dataToMultiSelectOptions} from '../utils'

export const useGetClassifications = () => {
  const {data: response = [], isFetching} = useQuery('get-Product-Classifications', () =>
    getAPi(`/products/price-list/classifications`)
  )
  return {
    Classifications: dataToMultiSelectOptions(response),
    ClassificationsLoading: isFetching,
  }
}
