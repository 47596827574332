import {useContext} from 'react'
import Search from '../../../../components/Search'
import {BackOrderContext} from '../../context'

const BackOrderSearchComponent = () => {
  const {onSearch} = useContext(BackOrderContext)
  return (
    <Search
      onSearch={(searchTerm: string) => {
        onSearch && onSearch(searchTerm)
      }}
    />
  )
}

export default BackOrderSearchComponent
