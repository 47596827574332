import NoDataFound from "../../../../components/NoDataFound"
import usePermission from "../../../../hook/usePermission"
import { formateUppperCase } from "../../../../utils/common"
import Loading from "../../../loading"
import UserSupplierTableRow from "./UserSupplierTableRow"


function UserSupplierMappingTable({data, isLoading}: any) {
  const {hasPermission} = usePermission()

  return (
    <div className='card-body p-0'>
      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 gs-0 gy-2 mb-15'>
            <thead className='table-row-bordered text-uppercase'>
              <tr className='fs-6 fw-semibold text-muted'>
                <th className='min-w-200px'>{formateUppperCase('Name')}</th>
                <th className='min-w-300px'>{formateUppperCase('Suppliers')}</th>
                {hasPermission('analytics_replenishment_supplier mapping', 'write') && (
                  <th className='w-80px text-center'>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data?.length
                ? data.map((row: any) => <UserSupplierTableRow key={row['name']} row={row} />)
                : !isLoading && <NoDataFound colspan={2} />}
            </tbody>
          </table>
          {isLoading && <Loading />}
        </div>
      </div>
    </div>
  )
}

export default UserSupplierMappingTable
