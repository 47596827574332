import {useState} from 'react'
import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

const useGetClassificationListing = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  let lastPayload: any = null

  const initialFilters = {
    page: true,
    limit: 30,
    sort_by: 'created_at/-1',
    classification_filter: '',
    search:true
  }

  const {filters, onSearch, onPageChange, onSortingChange, setMultipleFilters} = useFilters(
    initialFilters,
    {
      sortType: 'number',
    }
  )
  const {data: response, isFetching} = Api.useGetQuery('/analytics/replenishment/classifications', {
    queryId: 'classifications-listing',
    filters: filters,
  })

  const onClassificationChange = (payload: any) => {
    if (JSON.stringify(payload) === JSON.stringify(lastPayload)) {
      return
    }
    lastPayload = payload

    return Api.patch(`/analytics/replenishment/classifications`, payload, {
      setLoading: setIsLoading,
      successMessage: 'Classification updated successfully.',
      refetch: 'classifications-listing',
    })
  }

  const onApprove = async (payload: any) => {
    return Api.patch(`/analytics/replenishment/classifications`, payload, {
      setLoading: setIsLoading,
      successMessage: 'Classification updated successfully.',
      refetch: 'classifications-listing',
    })
  }

  const onReject = async (payload: any) => {
    return Api.patch(`/analytics/replenishment/classifications`, payload, {
      setLoading: setIsLoading,
      successMessage: 'Classification updated successfully.',
      refetch: 'classifications-listing',
    })
  }

  const onClassificationFilterChange = (newClassification: any) => {
    setMultipleFilters({
      page: 1,
      classification_filter: newClassification,
    })
  }

  return {
    ClassificationData: response?.data || [],
    pagination: response?.meta?.pagination || {},
    isLoading: isFetching,
    isOperationLoading: isLoading,
    filters: filters,
    onSearch: onSearch,
    onPageChange: onPageChange,
    onSortingChange: onSortingChange,
    onClassificationFilterChange,
    onClassificationChange,
    onApprove,
    onReject,
  }
}

export default useGetClassificationListing
