import {useContext, useEffect} from 'react'
import {useQuery} from 'react-query'
import {getFilters} from '../services'
import useFilters from '../../../hook/useFilters'
import {useSelector} from 'react-redux'
import {ReplenishmentDashboardContext} from '../context'
import {useAppSelector} from '../../../redux/useTypedSelector'

export const useGetFilters = (): any => {
  const {user} = useAppSelector((state) => state.auth)
  const {isUserExist} = useContext(ReplenishmentDashboardContext)
  const {global_user} = useSelector((state: any) => state.filters)
  const initialFilters = {
    primary_supplier: '',
    user: isUserExist ? user?.name : global_user?.value !== '' ? global_user?.value : '',
    hide_discontinued: true,
  }
  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`get-Replenishment-filters`, () => {
    return getFilters(filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  if (!response?.['data']) {
    return []
  }

  const onChangeSupplier = (supplierList: any, searchKey: string) => {
    if (searchKey === 'primary_supplier') {
      setMultipleFilters({
        primary_supplier: supplierList,
      })
    }
  }

  const onResetDropdownFilters = () => {
    setMultipleFilters({
      primary_supplier: '',
      user: '',
    })
  }

  const onChangeDiscontinueFilters = (hide_discontinued: boolean) => {
    setMultipleFilters({
      hide_discontinued: hide_discontinued,
    })
  }

  const handleUserChange = (selectedUser: string) => {
    if (!selectedUser) setSingleFilter('user', isUserExist ? user?.name : '')
    else setSingleFilter('user', selectedUser)
  }

  return {
    tableFilters: response?.['data']?.['data'] || {
      brand: [],
      classification: [],
      primary_supplier: [],
      years: [],
    },
    isFetchingDropdownData: isFetching,
    refetchFilters: refetch,
    onChangeSupplier,
    onResetDropdownFilters,
    handleUserChange,
    onChangeDiscontinueFilters,
  }
}
