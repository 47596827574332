import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../_metronic/helpers'
import {useEffect} from 'react'

interface DeleteConfirmationDialog {
  uniqueID: string | number
  show: boolean
  onClose: () => void
  onDelete: (uniqueID: string | number) => void
  isOperationLoading?: boolean
  isDataLoading?: boolean
}

const DeleteConfirmationModal = ({
  uniqueID,
  show,
  onClose,
  onDelete,
  isOperationLoading,
  isDataLoading,
}: DeleteConfirmationDialog) => {
  useEffect(() => {
    if (isDataLoading) {
      onClose()
    }
  }, [isDataLoading, onClose])

  if (!show) {
    return null
  }

  const onClickDelete = () => {
    if (uniqueID) {
      onDelete(uniqueID)
    }
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
      onHide={onClose}
      backdrop='static'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Are you sure ?</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <p>
            Do you really want to delete this record? <br></br>This action is irreversible.
          </p>
        </div>

        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-outline btn-active-light-primary'
            onClick={onClose}
          >
            No
          </button>
          <button type='submit' className='btn btn-danger' onClick={onClickDelete}>
            {!isOperationLoading && <span className='indicator-label'>Yes, Delete</span>}
            {isOperationLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Deleting...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteConfirmationModal
