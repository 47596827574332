import {createContext} from 'react'

export const SafetyStockContext = createContext({
  safetyStockListing: {},
  isLoading: true,
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
  showModal: false,
  setShowModal: (showModal: boolean) => {},
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
})
