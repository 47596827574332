import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import PDANavigation from '../components/PDANavigation'
import PriceChangeLog from '../layouts/PriceChangeLog'

const PriceChangeLogPageBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Sold Products',
    path: '/analytics/sold-products/product-report',
    isSeparator: false,
    isActive: false,
  },
]

const PriceChangeLogPage = () => {
    return (
        <>
          <Routes>
            <Route
              element={
                <>
                  <PDANavigation />
                  <Outlet />
                </>
              }
            >
              <Route
                path=''
                element={
                  <>
                    <PageTitle breadcrumbs={PriceChangeLogPageBreadCrumbs}>Dashboard</PageTitle>
                    <PriceChangeLog />
                  </>
                }
              />
            </Route>
          </Routes>
        </>
      )
}

export default PriceChangeLogPage