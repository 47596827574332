import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

const useGetAllProductsWithTags = (): any => {
  const Api = useApi()

  const initialFilters = {
    page: true,
    limit: 30,
    filter: true,
    sort_by: 'created_at/-1',
  }

  const {filters, onSearch, onPageChange, onSortingChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data: response, isFetching} = Api.useGetQuery(`/analytics/replenishment/tags/productslist`, {
    queryId: 'product-tags-listing',
    filters: filters,
  })

  return {
    productsTagsList: response?.data || [],
    pagination: response?.meta?.pagination || {},
    isLoading: isFetching,
    filters: filters,
    onSearch: onSearch,
    onPageChange: onPageChange,
    onSortingChange: onSortingChange,
  }
}

export default useGetAllProductsWithTags
