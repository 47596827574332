/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from 'react'
import TableCellInput from '../../../../components/Table/TableCellInput'
import useUpdatePurchasingOrderQuantity from '../../hooks/useUpdatePOQuantity'
import ProductAggregationReport from './ProductAggregationReport'
import OverlayModal from '../model/OverlayModal'
import {isProductDiscontinued, parseTableRowData} from '../../utils'
import {ReplenishmentContext} from '../../context'
import DefaultProductChildTable from './DefaultProductChildTable'
import {Select} from '../../../../components/Select/'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import usePermission from '../../../../hook/usePermission'

function TableRow({
  row: rowData,
  monthly_column_keys,
  monthly_column,
  register,
  showChildTables,
}: any) {
  const {hasPermission} = usePermission()
  const hasWritePermission = hasPermission('analytics_replenishment_dashboard', 'write') ?? false
  const row = parseTableRowData(rowData)
  const [updatedQty, setQty] = useState(row['item_order_qty'])
  const [itemToBeOrderd, setItemToBeOrderd] = useState(0)
  const [isVisible, setVisible] = useState(false)
  const [isChecked, setChecked] = useState(false)
  const {updatePurchasingOrderQuantity} = useUpdatePurchasingOrderQuantity()
  const [showAggregatedTable, setAggregatedTable] = useState(false)
  const [showDefaultChildTable, setShowDefaultChildTable] = useState(
    row?.child_data.length > 0 ? true : false
  )
  const [hasChildTable, setHasChildTable] = useState(row?.child_data.length > 0 ? true : false)
  const {
    dataFilters,
    selectedColumns,
    isColumnSelected,
    isFetchingReplenishmentData,
    onClassificationChange,
    classifications,
  }: any = useContext(ReplenishmentContext)

  useEffect(() => {
    if (showDefaultChildTable) {
      const sumItemOrderQty = row.child_data.reduce(
        (acc: number, curr: any) => acc + curr.item_order_qty,
        0
      )
      setItemToBeOrderd(sumItemOrderQty)
    }
    if (row?.child_data) {
      setHasChildTable(row?.child_data.length > 0 ? true : false)
    }
  }, [showDefaultChildTable, row.child_data, showChildTables])

  useEffect(() => {
    if (row?.child_data.length > 0) {
      setShowDefaultChildTable(showChildTables)
    }
  }, [showChildTables])

  useEffect(() => {
    setChecked(false)
  }, [isFetchingReplenishmentData])

  return (
    <>
      <tr
        className={`${
          row?.['suggested_order_qty'] > 0
            ? `bg-light-success`
            : row?.['suggested_order_qty'] < 0
            ? `bg-light-danger`
            : ``
        }`}
      >
        <td className='body-col-sticky'>
          <input
            type='checkbox'
            id={row['parent_sku']}
            value={row['parent_sku']}
            checked={isChecked}
            className='form-check-input form-check-input-smallbg'
            {...register('skus')}
            onClick={(e) => {
              setChecked(!isChecked)
            }}
          />
        </td>

        <td className='body-col-sticky'>
          {row?.child_data.length > 0 ? (
            showDefaultChildTable ? (
              <span onClick={() => setShowDefaultChildTable(!showDefaultChildTable)}>
                <i className='bi bi-dash-circle-fill cursor-pointer fs-5'></i>
              </span>
            ) : (
              <span onClick={() => setShowDefaultChildTable(!showDefaultChildTable)}>
                <i className='bi bi-plus-circle-fill cursor-pointer fs-5'></i>
              </span>
            )
          ) : row?.child_data.length > 0 ? (
            showAggregatedTable ? (
              <span onClick={() => setAggregatedTable(!showAggregatedTable)}>
                <i className='bi bi-dash-circle-fill cursor-pointer fs-5'></i>
              </span>
            ) : (
              <span onClick={() => setAggregatedTable(!showAggregatedTable)}>
                <i className='bi bi-plus-circle-fill cursor-pointer fs-5'></i>
              </span>
            )
          ) : null}
        </td>

        <td className='body-col-sticky'>
          <div className='align-items-center'>{row['parent_sku']}</div>
        </td>

        {/* {isColumnSelected('parent_sku') && <td>
          <div className='align-items-center'>{row['part_number']}</div>
        </td>} */}

        <td className='body-col-sticky'>
          <div className='align-items-center'>{row['product_title']}</div>
        </td>

        {isColumnSelected('classification') && (
          <td className='body-col-sticky'>
            <div className='d-flex gap-2'>
              <Select
                id={'classification_to_be'}
                placeholder={'Select Classification'}
                className='w-200px'
                options={classifications}
                isNullable={true}
                placeholderValue={''}
                value={row['classification'] || ''}
                disabled={!hasWritePermission}
                onChange={(e) =>
                  onClassificationChange({
                    new_classification: e.target.value,
                    sku: row['parent_sku'],
                  })
                }
              />
              <OverlayComponent
                btnIcon={<i className='fa-solid fa-circle-info'></i>}
                placement='bottom'
                className='btn-active-light-primary rounded-circle w-25px h-25px'
                trigger={['hover', 'focus']}
                children={<>{row['unique_classification']}</>}
              />
            </div>
          </td>
        )}

        {isColumnSelected('reserved_quantity') && (
          <td className='body-col-sticky'>
            <div className='text-center'>{row['reserved_quantity']}</div>
          </td>
        )}
        {isColumnSelected('quantity_available') && (
          <td className='body-col-sticky'>
            <div className='text-center'>{row['quantity_available']}</div>
          </td>
        )}
        {isColumnSelected('safetystock_quantity') && (
          <td className='body-col-sticky'>
            <div className='text-center'>{row['safetystock_quantity']}</div>
          </td>
        )}
        {isColumnSelected('quantity_incoming') && (
          <td className='body-col-sticky'>
            <div className='text-center'>{row['quantity_incoming']}</div>
          </td>
        )}
        {isColumnSelected('backorder_quantity') && (
          <td className='body-col-sticky'>
            <div className='text-center'>{row['backorder_quantity']}</div>
          </td>
        )}
        {isColumnSelected('total_sold_30') && (
          <td>
            <div className='text-center'>{row['total_sold_30']}</div>
          </td>
        )}
        {isColumnSelected('incremental_quantity') && (
          <td>
            <div className='text-center'>{row['incremental_quantity']}</div>
          </td>
        )}
        {isColumnSelected('case_qty') && (
          <td>
            <div className='text-center'>{row['caseQty'] === '-' ? '0' : row['caseQty']}</div>
          </td>
        )}
        {isColumnSelected('suggested_order_qty') && (
          <td>
            <div className='text-center'>{+row?.['suggested_order_qty']}</div>
          </td>
        )}
        {isColumnSelected('suggested_order_qty_45') && (
          <td
          // className={`${
          //   row?.['suggested_order_qty'] > 0
          //     ? `bg-light-success`
          //     : row?.['suggested_order_qty'] < 0
          //     ? `bg-light-danger`
          //     : ``
          // }`}
          >
            <div className='text-center'>{+row?.['suggested_order_qty_45']}</div>
          </td>
        )}
        {isColumnSelected('suggested_order_qty_60') && (
          <td
          // className={`${
          //   row?.['suggested_order_qty'] > 0
          //     ? `bg-light-success`
          //     : row?.['suggested_order_qty'] < 0
          //     ? `bg-light-danger`
          //     : ``
          // }`}
          >
            <div className='text-center'>{+row?.['suggested_order_qty_60']}</div>
          </td>
        )}

        {isColumnSelected('to_order_qty') && (
          <td
            className={`text-center ${
              row['to_order_qty'] >= 0 ? `bg-light-warning` : 'bg-light-danger'
            }`}
          >
            <div className='align-items-center'>{row['to_order_qty']}</div>
          </td>
        )}
        {isColumnSelected('item_order_qty') && (
          <td
            className={`${row['item_order_qty'] > 0 || updatedQty > 0 ? `bg-light-success` : ''}`}
          >
            <div
              className={`align-items-center order-quantity-input justify-content-center text-center cursor-pointer`}
              onClick={() => null}
            >
              {isVisible ? (
                <TableCellInput
                  inputType='number'
                  onCellChange={(updatedQty: any) => {
                    if (updatedQty) {
                      updatePurchasingOrderQuantity(
                        row['parent_sku'],
                        updatedQty,
                        row['sale_history_months'],
                        row['days_to_replenishment'],
                        row['suggested_order_qty'],
                        row['total_sold_30'],
                        row['weeks_on_hand'],
                        row['turn_rate'],
                        row['to_order_qty'],
                        row['quantity_available'],
                        row['quantity_incoming'],
                        row['incremental_quantity'],
                        row['product_title']
                      )
                      setQty(updatedQty)
                      setVisible(false)
                    }
                  }}
                  min={0}
                  exitEvent={(isFocusedOut) => (isFocusedOut ? setVisible(false) : null)}
                  defaultValue={updatedQty}
                />
              ) : (
                itemToBeOrderd
              )}
            </div>
          </td>
        )}

        {monthly_column_keys &&
          monthly_column_keys !== undefined &&
          monthly_column_keys.map((column: any) => (
            <td>
              <div className='align-items-center'>{rowData[column]}</div>
            </td>
          ))}
        {isColumnSelected('total_quantity') && (
          <td>
            <div className='text-center'>{row['total_quantity']}</div>
          </td>
        )}
        {isColumnSelected('days_out_of_stock') && (
          <td>
            <div className='text-center'>
              {hasChildTable || isProductDiscontinued(row['product_title'])
                ? '-'
                : row['days_out_of_stock']}
            </div>
          </td>
        )}
        {isColumnSelected('cost') && (
          <td>
            <div className='align-items-center'>{row['cost']}</div>
          </td>
        )}
        {isColumnSelected('total_cost') && (
          <th className='min-w-150px w-150px text-center'>
            {itemToBeOrderd !== undefined
              ? (rowData['cost'] * itemToBeOrderd).toFixed(2)
              : row['total_cost'] || '0'}
          </th>
        )}

        {isColumnSelected('primary_supplier') && <td>{row['primary_supplier']}</td>}

        {isColumnSelected('weeks_on_hand') && (
          <td>
            <div className='text-center'>{row?.['weeks_on_hand']}</div>
          </td>
        )}
        {isColumnSelected('turn_rate') && (
          <td>
            <div className='text-center'>{row?.['turn_rate']}</div>
          </td>
        )}

        {hasWritePermission && <td className='text-start'>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <OverlayModal sku={row?.parent_sku} />
          </div>
        </td>}
      </tr>
      {showDefaultChildTable ? (
        <tr>
          <td
            colSpan={
              (selectedColumns?.length === 0 ? 24 : selectedColumns?.length + 5) +
              monthly_column_keys?.length
            }
          >
            <DefaultProductChildTable
              child_data={row?.child_data}
              monthly_column={monthly_column}
              setItemToBeOrderd={setItemToBeOrderd}
              isColumnSelected={isColumnSelected}
            />
          </td>
        </tr>
      ) : null}
      {showAggregatedTable ? (
        <tr>
          <td
            colSpan={
              (selectedColumns?.length === 0 ? 24 : selectedColumns?.length + 5) +
              monthly_column_keys?.length
            }
          >
            <ProductAggregationReport
              sku={row['parent_sku']}
              title={row['product_title']}
              hide_discontinued={dataFilters?.hide_discontinued}
              sale_history_months={dataFilters?.sale_history_months}
              days_to_replenishment={dataFilters?.days_to_replenishment}
              setItemToBeOrderd={setItemToBeOrderd}
            />
          </td>
        </tr>
      ) : null}
    </>
  )
}

export default TableRow
