import {KTCard} from '../../../_metronic/helpers'
import {useGetReplenishmentData} from '../hooks/useGetReplenishmentData'
import useGetProductTags from '../hooks/useGetProductTags'
import ReplenishmentTable from '../sections/table/ReplenishmentTable'
import {ReplenishmentContext} from '../context'
import ReplenishmentSearchComponent from '../sections/ReplenishmentSearchComponent'
import useGetSelectedTableColumns from '../hooks/useGetSelectedTableColumns'
import useGetAllClassifications from '../hooks/useGetAllClassifications'

function ReplenishmentData() {
  const {
    replenishment,
    filters,
    isFetchingReplenishmentData,
    pagination,
    monthly_column,
    refetch,
    onFilter,
    onSearch,
    onPageChange,
    onClickSorting,
    onApplyFilters,
    onResetFilters,
    handleUserFitler,
    register,
    selectedSkus,
    handleResetClick,
    getProductTagsUsingSku,
    onMultyProductAddTags,
    onUpdateProductTags,
    onDownloadCsv,
    isFetchingCsvData,
    onClassificationChange
  } = useGetReplenishmentData()

  const {tags, isFetchingTagData, onAddTag} = useGetProductTags()
  const {allTableColumns, selectedColumns, setSelectedColumns, isColumnSelected} = useGetSelectedTableColumns()
  const {classifications} = useGetAllClassifications()

  const contextValue = {
    replenishment: replenishment,
    dataFilters: filters,
    isFetchingReplenishmentData,
    pagination,
    monthly_column,
    onPageChange,
    onFilter,
    onSearch,
    refetch,
    onClickSorting,
    onApplyFilters,
    onResetFilters,
    handleUserFitler,
    register,
    selectedSkus,
    handleResetClick,
    getProductTagsUsingSku,
    tags: tags,
    isFetchingTagData,
    onAddTag,
    onMultyProductAddTags,
    onUpdateProductTags,
    onDownloadCsv,
    isFetchingCsvData,
    allTableColumns,
    selectedColumns,
    setSelectedColumns,
    isColumnSelected,
    onClassificationChange,
    classifications
  }

  return (
    <KTCard>
      <ReplenishmentContext.Provider value={contextValue}>
        <ReplenishmentSearchComponent />
        <ReplenishmentTable pagination={pagination} onPageChange={onPageChange} />
      </ReplenishmentContext.Provider>
    </KTCard>
  )
}

export default ReplenishmentData
