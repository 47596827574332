import {useContext} from 'react'
import {ClassificationContext} from '../../context'

export function ClassificationDataDropdown({id}: any) {
  const {onApprove, onReject} = useContext(ClassificationContext)

  const handleApprove = () => {
    document.body.click()
    onApprove({approved: true,request_ids: [id]})
  }

  const handleReject = () => {
    document.body.click()
    onReject({approved: false, request_ids: [id]})
  }

  return (
    <div>
      <div className='px-2 py-1'>
        <span onClick={handleApprove} className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Approve</label>
        </span>
      </div>
      <div className='px-2 py-1'>
        <span onClick={handleReject} className='text-dark fw-bold text-hover-danger fs-6'>
          <label>Reject</label>
        </span>
      </div>
    </div>
  )
}
