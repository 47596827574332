import OverlayModalTags from '../../../analyticsReplenishment/sections/model/OverlayModalProductsTags'

const ProductTagsTableRow = ({row, actionComponent, checkBoxComponent}: any) => {
  return (
    <tr>
      {checkBoxComponent && <td>{checkBoxComponent(row)}</td>}
      <td>
        <div className='fw-semibold'>{row['name']}</div>
        <div>
          <strong> {row['option']}</strong>
        </div>
      </td>
      <td>
        <div className='fw-semibold'>{row['tableSku']}</div>
      </td>
      <td>
        <div>
          {row['tags'].length &&
            row['tags'] &&
            row['tags'].map((i: any) => (
              <span className='text-uppercase badge badge-light m-1 p-3 text-gray-800'>{i}</span>
            ))}
        </div>
      </td>
      {actionComponent && (
        <td className='text-start'>
          <div className=''>
            <OverlayModalTags row={row} />
          </div>
        </td>
      )}
    </tr>
  )
}

export default ProductTagsTableRow
