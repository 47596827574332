interface SafetyStockActionsDropdownProps {
  setShowModal: (showModal: boolean) => void
  setShowDeleteModal: (showDeleteModal: boolean) => void
}

export function SafetyStockActionsDropdown({
  setShowModal,
  setShowDeleteModal,
}: SafetyStockActionsDropdownProps) {
  const handleDelete = () => {
    document.body.click()
    setShowDeleteModal(true)
  }

  const handleEdit = () => {
    document.body.click()
    setShowModal(true)
  }

  return (
    <>
      <div>
        <div className='px-2 py-1'>
          <span className='text-dark fw-bold text-hover-primary fs-6' onClick={handleEdit}>
            <label className='cursor-pointer'>Edit</label>
          </span>
        </div>

        <div className='px-2 py-1'>
          <span className='text-dark fw-bold text-hover-primary fs-6' onClick={handleDelete}>
            <label className='cursor-pointer'>Delete</label>
          </span>
        </div>
      </div>
    </>
  )
}
