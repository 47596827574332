import React, {useContext} from 'react'
import PriceChangeLogTableRow from './PriceChangeLogTableRow'
import {DynamicTable} from '../../../../components/DynamicTable'
import {PriceChangeLogPageContext} from '../../contexts'
import {sortableColumnsForPriceChangeLog} from '../../utils'

const PriceChangeLogTable = () => {
  const {PriceChangeLogData, isLoading, priceChangeLogFilters, onSortingChange} =
    useContext(PriceChangeLogPageContext)

  return (
    <DynamicTable
      id='price-change-log-table'
      data={PriceChangeLogData}
      sortableColumns={sortableColumnsForPriceChangeLog}
      TableRow={PriceChangeLogTableRow}
      loading={isLoading}
      filters={priceChangeLogFilters}
      onSortingChange={onSortingChange}
      permissionPath={'price-changes'}
    />
  )
}

export {PriceChangeLogTable}
