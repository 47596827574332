import {PageLink} from '../../../_metronic/layout/core'
import {formatInputPrice, formatPrice, makeSemicolonSepratedValues} from '../../../utils/common'
import {formatDate} from '../../../utils/date'

export const ReplenishmentPageBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
]

export const ReorderBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
  {
    title: 'Replenishment',
    path: '/analytics/replenishment/data',
    isSeparator: false,
    isActive: false,
  },
]

export const supplierManagementBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: true,
  },
  {
    title: 'Replenishment',
    path: '/analytics/replenishment/data',
    isSeparator: false,
    isActive: false,
  },
]

export const dataToMultiSelectOptions = (data: any, type: string) => {
  const res: any = []

  if (data?.[type]?.length) {
    data[type].forEach((i: any) => {
      res.push({
        value: i,
        label: i,
      })
    })
  }

  return res
}

export function extractValues(input: any) {
  if (input?.length) {
    return input.map((item: any) => item.value)
  } else {
    return []
  }
}

export function dataToUserSupplierMappingList(data: any) {
  const res: any = []

  if (!data || data.length === 0) {
    return []
  }

  data.forEach((i: any) => {
    res.push({
      id: i['user_id'],
      name: i['user_name'],
      suppliers: i['suppliers'],
    })
  })

  return res
}

export function dataToSuppliersList(data: any) {
  const res: any = []

  if (!data || data.length === 0) {
    return []
  }

  data.forEach((i: any) => {
    res.push({
      label: i['primary_supplier'],
      value: i['primary_supplier'],
    })
  })

  return res
}

export function dataToFormValues(data: any) {
  if (!data) return {}

  const res: any = {
    name: {
      value: data['name'],
      label: data['name'],
    },
    email: data['email_id'],
  }

  const suppliers: any = []

  data['suppliers'].forEach((i: any) =>
    suppliers.push({
      value: i,
      label: i,
    })
  )

  res['suppliers'] = suppliers

  return res
}

function makeCommaSepratedValues(input: any) {
  if (input?.length) {
    return input.map((item: any) => item.value).join(',')
  } else {
    return ''
  }
}

export const formDataToFiltersValue = (data: any) => {
  return {
    limit: data?.limit || 10,
    brand: makeSemicolonSepratedValues(data?.brands),
    classification: makeSemicolonSepratedValues(data?.classifications),
    primary_supplier: makeSemicolonSepratedValues(data?.primary_supplier),
    search_value: data?.search || '',
    user: data?.name?.value || '',
    sale_history_months: data?.sale_history_months || 6,
    days_to_replenishment: data?.days_to_replenishment || 30,
    days_to_replenishment_45: data?.days_to_replenishment_45 || 30,
    days_to_replenishment_60: data?.days_to_replenishment_60 || 30,
    tags: makeCommaSepratedValues(data?.tags),
    hide_discontinued: data?.hide_discontinued || false,
    show_zero_qty_products: data?.show_zero_qty_products || false,
    no_sold_days: data?.no_sold_days || false,
    ratio_filter: data?.ratio_filter || 'month_6',
    year: data?.year || '',
  }
}

export const formDashboardDataToFiltersValue = (data: any) => {
  return {
    limit: data?.limit || 10,
    brand: makeSemicolonSepratedValues(data?.brands),
    classification: makeSemicolonSepratedValues(data?.classifications),
    primary_supplier: makeSemicolonSepratedValues(data?.primary_supplier),
    search_value: data?.search || '',
    user: data?.name?.value || '',
    sale_history_months: data?.sale_history_months || 6,
    days_to_replenishment: data?.days_to_replenishment || 30,
    days_to_replenishment_45: data?.days_to_replenishment_45 || 30,
    days_to_replenishment_60: data?.days_to_replenishment_60 || 30,
    tags: makeCommaSepratedValues(data?.tags),
    hide_discontinued: data?.hide_discontinued || false,
    show_zero_qty_products: data?.show_zero_qty_products || false,
    no_sold_days: data?.no_sold_days || false,
    ratio_filter: data?.ratio_filter || 'month_6',
    year: data?.year || '',
  }
}

export const formDataToFiltersValueReorder = (data: any) => {
  return {
    primary_supplier: makeSemicolonSepratedValues(data?.primary_supplier),
    user: data?.user?.value || '',
    filter_key: data?.filter_key || '',
    search_value: data?.search || '',
  }
}

export const parseReorderingTableRowData = (row: any) => {
  return {
    sku: row?.sku || '',
    title: row?.title || '-',
    primary_supplier: row?.primary_supplier || '-',
    quantity: row?.quantity || 0,
    total_cost: formatInputPrice(row?.total_cost, true, false) || '',
    quantity_available: row?.quantity_available || '0',
    quantity_incoming: row?.quantity_incoming || '0',
    total_sold_30: row?.total_sold_30 || '0',
    reorder_point: row?.reorder_point || '0',
    incremental_quantity: row?.incremental_quantity || '0',
    suggested_order_qty: row?.suggested_order_qty || '0',
    to_order_qty: row?.to_order_qty || '0',
    cost: formatInputPrice(row?.cost, true, false) || '',
    sale_history_months: row?.sale_history_months || '0',
    days_to_replenishment: row?.days_to_replenishment || '0',
    brand: row?.brand || '',
    classification: row?.classification || '0',
    weeks_on_hand: (row?.weeks_on_hand || 0)?.toFixed(2),
    turn_rate: (row?.turn_rate || 0)?.toFixed(2),
    modified_by: row?.modified_by || '0',
    modified_at: row?.modified_at == null ? '-' : formatDate(row?.modified_at, false),
  }
}

export const parseAggregatedTableRowData = (row: any) => {
  return {
    showAggregatedTable: row?.showAggregatedTable || false,
    product_id: row?.product_id || '',
    title: row?.title || '-',
    primary_supplier: row?.primary_supplier || '-',
    quantity_available: row?.quantity_available || 0,
    quantity_incoming: row?.quantity_incoming || 0,
    total_sold: row?.total_sold || 0,
    reorder_point: row?.reorder_point || '',
    incremental_quantity: row?.incremental_quantity || 0,
    suggested_order_qty: (row?.suggested_order_qty || 0)?.toFixed(2),
    to_order_qty: row?.to_order_qty || 0,
    cost: formatInputPrice(row?.cost, false, false) || '',
    brand: row?.brand || '-',
    classification: row?.classification || '-',
    weeks_on_hand: (row?.weeks_on_hand || 0)?.toFixed(2),
    turn_rate: (row?.turn_rate || 0)?.toFixed(2),
  }
}

export const parseTableRowData = (row: any) => {
  return {
    parent_sku: row?.parent_sku || '-',
    //product_id: row?.product_id || '',
    product_title: row?.product_title || '-',
    primary_supplier: row?.primary_supplier || '-',
    quantity_available: row?.quantity_available || 0,
    quantity_incoming: row?.quantity_incoming || 0,
    sale_history_months: row?.sale_history_months || 6,
    days_to_replenishment: row?.days_to_replenishment || 30,
    total_sold_30: row?.total_sold_30 || '-',
    reorder_point: row?.reorder_point || '-',
    incremental_quantity: row?.incremental_quantity || 0,
    suggested_order_qty: row?.suggested_order_qty?.toFixed(2) || 0,
    suggested_order_qty_45: row?.suggested_order_qty_45?.toFixed(2) || 0,
    suggested_order_qty_60: row?.suggested_order_qty_60?.toFixed(2) || 0,
    to_order_qty: row?.to_order_qty || 0,
    item_order_qty: row?.item_order_qty || 0,
    total_quantity:
      (parseInt(row?.quantity_incoming) || 0) + (parseInt(row?.quantity_available) || 0),
    cost: formatInputPrice(row?.cost, true, false) || 'N/A',
    classification: row?.classification || '-',
    brand: row?.brand || '-',
    weeks_on_hand: row?.weeks_on_hand?.toFixed(2) || '-',
    turn_rate: row?.turn_rate?.toFixed(2) || '-',
    child_data: row?.child_data || [],
    reserved_quantity: row?.reserved_quantity || '-',
    backorder_quantity: row?.backorder_quantity || 0,
    safetystock_quantity: row?.safetystock_quantity || 0,
    days_out_of_stock: row?.days_out_of_stock || 0,
    total_cost: row?.total_cost || 0,
    unique_classification: row?.unique_classification || null,
  }
}

export const parseDashboardTableRowData = (row: any) => {
  return {
    product_title: row?.product_title || '-',
    parent_sku: row?.parent_sku || '-',
    primary_supplier: row?.primary_supplier || '-',
    purchaser_name: row?.purchaser_name || '-',
    classification: row?.classification || '-',
    cost: row?.cost || 0,
    quantity_pending: row?.quantity_pending || 0,
    quantity_incoming: row?.quantity_incoming || 0,
    quantity_available: row?.quantity_available || 0,
    quantity_on_hold: row?.quantity_on_hold || 0,
    total_quantity:
      (parseInt(row?.quantity_incoming) || 0) + (parseInt(row?.quantity_available) || 0),
    terms_consignment: row?.terms_consignment || '-',
    action_taken: row?.action_taken || '',
    created_at: row?.created_at ? formatDate(row?.created_at, true) : '-',
    year: row?.year || '-',
    classified_as: row?.classified_as || '',
    total_sold: row?.total_sold || 0,
    ratio: row?.ratio || 0,
    total_cost: formatPrice(row?.total_cost || 0, true),
    wholesale_price: row?.wholesale_price || 0,
  }
}

export const parseTagsTableRowData = (row: any) => {
  return {
    tag: row?.tag || '-',
    productCount: row?.count || 0,
    sku: row?.sku || '-',
    id: row?.id || '',
  }
}

export const parseUserSupplierTableRowData = (row: any) => {
  return {
    name: row?.name || '-',
    suppliers: row?.suppliers || [],
  }
}

export const parseProductTagsTableRowData = (row: any) => {
  return {
    name: row?.product_name || '-',
    tags: row?.tags || [],
    tableSku: row?.variant_sku ? row?.variant_sku : row?.sku || '-',
    option: row?.variant_options || '',
    sku: `${row?.variant_sku} | ${row?.sku}` || '',
    variantSku: row?.variant_sku,
  }
}
export const sampleProductTagsCSVData = [
  {SKU: 'SKU001', Tags: 'Test Tag 1'},
  {SKU: 'SKU002', Tags: 'Test Tag 2'},
  {SKU: 'SKU003', Tags: 'Test Tag 3'},
]

export const productTagsCsvConfig = {
  requiredHeaders: ['SKU', 'Tags'],
  duplicates: {sku: false, tags: true, rows: true},
  maxRows: 5000,
  isNullable: false,
  isReplaceable: true,
  replacePartially: true,
  transformData: {
    uniqueKey: [{sku: 'sku'}],
    groupedKey: [{tags: 'tags'}],
    specialGroupKey: ',',
  },
  customErrors: {
    headerMismatch: () => 'The CSV file must contain two columns: SKU, Tags',
    invalidFileType: () =>
      `The file you are trying to upload is not in a valid CSV format. Please upload a valid CSV file.`,
    emptyCell: (RowNumber: number, headerName: string) =>
      `The ${headerName} is blank in CSV row number ${RowNumber}.`,
    duplicateWithDefault: (skuName: any, rowNumber: number, headerName: string) =>
      `${headerName}: <strong>${skuName}</strong>, Row No. <strong>${rowNumber}</strong>`,
  },
}

export const sampleBackOrderCSVData = [
  {SKU: 'SKU001', Qty: 10},
  {SKU: 'SKU002', Qty: 20},
  {SKU: 'SKU003', Qty: 30},
]

export const backOrderCsvConfig = {
  requiredHeaders: ['SKU', 'Qty'],
  duplicates: {sku: false},
  isNullable: false,
  isReplaceable: true,
  replacePartially: true,
  maxRows: 5000,
  types: {
    qty: 'integer',
  },
  customErrors: {
    headerMismatch: () => 'The CSV file must contain two columns: SKU, Qty',
    invalidFileType: () =>
      `The file you are trying to upload is not in a valid CSV format. Please upload a valid CSV file.`,
    emptyCell: (RowNumber: number, headerName: string) =>
      `The ${headerName} is blank in CSV row number ${RowNumber}.`,
    duplicateWithDefault: (skuName: any, rowNumber: number, headerName: string) =>
      `${headerName}: <strong>${skuName}</strong>, Row No. <strong>${rowNumber}</strong>`,
    replacePartially:
      'The following SKU(s) already exist in the system. Do you want to proceed with overwriting these data?',
    maxRowsLimit: (currentRows: number, maxLimit: number) =>
      `The current CSV contains ${currentRows} SKUs. The maximum allowed SKUs are ${maxLimit}. Please reduce the number of SKUs to proceed with the import.`,
    invalidDataType: (headerName: string, rowIndex: number) =>
      `Invalid ${headerName} found in rows: ${rowIndex}`,
  },
}

export const sampleSafetyStockCSVData = [
  {SKU: 'SKU001', Qty: 10},
  {SKU: 'SKU002', Qty: 20},
  {SKU: 'SKU003', Qty: 30},
]

export const safetyStockCsvConfig = {
  requiredHeaders: ['SKU', 'Qty'],
  duplicates: {sku: false, rows: true},
  isNullable: false,
  isValidateHeadersDataOnly: false,
  isReplaceable: true,
  replacePartially: true,
  maxRows: 5000,
  types: {
    qty: 'integer',
  },
  customErrors: {
    headerMismatch: () => 'The CSV file must contain two columns: SKU, Qty',
    invalidFileType: () =>
      `The file you are trying to upload is not in a valid CSV format. Please upload a valid CSV file.`,
    emptyCell: (RowNumber: number, headerName: string) =>
      `The ${headerName} is blank in CSV row number ${RowNumber}.`,
    duplicateWithDefault: (skuName: any, rowNumber: number, headerName: string) =>
      `${headerName}: <strong>${skuName}</strong>, Row No. <strong>${rowNumber}</strong>`,
    replacePartially:
      'The following SKU(s) already exist in the system. Do you want to proceed with overwriting these data?',
    maxRowsLimit: (currentRows: number, maxLimit: number) =>
      `The current CSV contains ${currentRows} SKUs. The maximum allowed SKUs are ${maxLimit}. Please reduce the number of SKUs to proceed with the import.`,
    invalidDataType: (headerName: string, rowIndex: number) =>
      `Invalid ${headerName} found in rows: ${rowIndex}`,
  },
}

export function isProductDiscontinued(productTitle: string): boolean {
  if (!productTitle || typeof productTitle !== 'string') return true
  return productTitle.toLowerCase().includes('discontinued')
}

export const toSnakeCase = (str: string) => {
  return str
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .replace(/([a-z])([A-Z])/g, '$1_$2') // Add underscore between lowercase and uppercase letters
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2') // Handle acronyms (CBD2Store -> cbd2_store)
    .toLowerCase() // Convert everything to lowercase
}

export const dataToProductTagsCSV = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      SKU: `${item?.variant_sku ?? item?.sku}`,
      Tags: `"${item?.tags.join(',') || ''}"`,
    })
  })

  return results
}

export const dataToBackOrderCSV = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      SKU: item?.child_sku,
      Qty: item?.qty || 0,
    })
  })

  return results
}

export const dataToSafetyStockCSV = (data: any) => {
  const results: any = []

  data.forEach((item: any) => {
    results.push({
      SKU: item?.child_sku,
      Qty: item?.qty || 0,
    })
  })

  return results
}

export const convertToLabelValue = (data: any) => {
  return data.map((item: any) => ({
    label: item,
    value: item,
  }))
}

// classified as page

export const classifiedAsTableColumns = [
  {
    id: 'classified-as',
    label: 'Classified As',
    headerStyle: 'min-w-100px',
  },
  {
    id: 'product-count',
    label: 'Product Count',
    headerStyle: 'min-w-100px',
  },
  {
    id: 'action',
    label: 'Action',
    headerStyle: 'w-80px',
  },
]

export const classifiedAsTableRowData = (row: any) => {
  return {
    id: row?.id,
    classifiedAs: row?.name || '-',
    productCount: row?.product_count || 0,
  }
}

