import React, {useRef, useEffect} from 'react'
import useGetChildAggregateChildData from '../../hooks/useGetChildAggregateChildData'
import Loading from '../../../loading'
import ChildTableHeader from './ChildTableHeader'
import ChildTableRow from './ChildTableRow'

function ProductAggregationReport({sku ,hide_discontinued, sale_history_months, days_to_replenishment, setItemToBeOrderd}: any) {

  const {childAggregation, isLoading, monthly_column, message} = useGetChildAggregateChildData({
    sku,
    hide_discontinued,
    sale_history_months,
    days_to_replenishment
  })    

  const inputRefs = useRef([])

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, childAggregation.length)
    inputRefs.current = childAggregation.map(
      (_: any, i: number) => inputRefs.current[i] || React.createRef()
    )
  }, [childAggregation])
  
  const originalObject = monthly_column !== undefined ? monthly_column : {};
  const reversedObject = Object.fromEntries(
    Object.entries(originalObject).reverse()
  );
  const monthly_column_keys = Object.keys(reversedObject);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : message ? (
        <p className='text-danger px-5'>{message}</p>
      ) : (
        <div className='table-responsive remove-overflow'>
          <table className='table gs-0 gy-3 align-middle my-3 table-row-bordered border'>        
            <ChildTableHeader monthly_column={monthly_column} />
            <tbody>
              {childAggregation?.length
                ? childAggregation.map((i: any, index: any) => {
                    const arrayLength = childAggregation.length

                    return (
                      <ChildTableRow
                        i={i}
                        index={index}
                        monthly_column_keys={monthly_column_keys}
                        arrayLength={arrayLength}
                        inputRef={inputRefs.current[index]}
                        inputRefArr={inputRefs}
                        setItemToBeOrderd={setItemToBeOrderd}
                      />
                    )
                  })
                : null}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ProductAggregationReport
