import useApi from '../../../services/useApi'
import {useEffect, useState} from 'react'

const meta = {
  price_3: {
    name: 'Value Tier',
    active: true,
  },
  price_1: {
    name: 'Tier 1 Price List',
    active: true,
  },
  price_2: {
    name: 'Tier 2 Price List',
    active: true,
  },
  price_4: {
    name: 'Tier Pro',
    active: true,
  },
  price_5: {
    name: 'Partner',
    active: true,
  },
  price_6: {
    name: 'VIP',
    active: true,
  },
  price_7: {
    name: 'Distro',
    active: true,
  },
}

const metaOptions = Object.entries(meta)
  .filter(([, {active}]) => active)
  .map(([key, {name}]) => ({label: name, value: key}))

const options = [
  {label: 'Date Created', value: 'created_at'},
  {label: 'Year', value: 'year'},
  {label: 'Supplier', value: 'primary_supplier'},
  {label: 'Purchaser Name', value: 'purchaser_name'},
  {label: 'Classified As', value: 'classified_as'},
  {label: 'Classification', value: 'classification'},
  {label: 'Cost Price', value: 'cost'},
  {label: 'Quantity on Hold', value: 'quantity_on_hold'},
  {label: 'Quantity Pending', value: 'quantity_pending'},
  {label: 'Incoming Qty', value: 'quantity_incoming'},
  {label: 'Available Qty', value: 'quantity_available'},
  {label: 'Available + Incoming', value: 'total_quantity'},
  {label: 'Total Sold', value: 'total_sold'},
  {label: 'Wholesale', value: 'wholesale_price'},
  ...metaOptions,
  {label: 'Ratio', value: 'ratio'},
  {label: 'Total Cost', value: 'total_cost'},
  {label: 'Terms Consignment', value: 'terms_consignment'},
  {label: 'Action Taken', value: 'action_taken'},
]

const useGetSelectedDashboardTableColumns = () => {
  const [selectedColumns, setSelectedColumns] = useState<any[]>(options)

  const isColumnSelected = (column: string) => {
    const selectedColumnsArr = selectedColumns?.map((value: any) => value.value)
    return selectedColumnsArr.includes(column)
  }

  const Api = useApi()
  const {data: response, isFetching} = Api.useGetQuery(
    '/analytics/replenishment/columns?type=replenishment_dashboard'
  )

  useEffect(() => {
    const userSelectedColumns =
      options.filter((option) => response?.data?.columns?.includes(option.value)) || []
    if (!isFetching) {
      setSelectedColumns(response?.data?.is_filter_applied === true ? userSelectedColumns : options)
    }
  }, [isFetching, response])

  return {
    allTableColumns: options,
    selectedColumns: selectedColumns,
    setSelectedColumns: setSelectedColumns,
    isColumnSelected: isColumnSelected,
  }
}

export default useGetSelectedDashboardTableColumns
