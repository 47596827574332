import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import DeleteConfirmationModal from '../../../../components/modal/DeleteConfirmationModal'
import {ProductsTagsDropdown} from '../dropdown/ProductsTagsDropdown'
import ProductTagsCreateModal from './ProductTagsCreateModal'
import useProductTagsOperations from '../../hooks/useProductTagsOperations'
import {ProductsTagsContext} from '../../context'

const OverlayModal = ({row}: any) => {
  const {deleteProductTags, isDeleting} = useProductTagsOperations()
  const {isLoading, setCheckedRows} = useContext(ProductsTagsContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const handleDelete = () => {
    const parentSku = row?.sku?.split('|')?.[1]?.trim()
    deleteProductTags({
      data: [
        {
          sku: parentSku,
          variant_sku: row?.variantSku,
        },
      ],
    })
    setCheckedRows([])
  }
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <ProductsTagsDropdown
            setShowDeleteModal={setShowDeleteModal}
            setShowEditModal={setShowEditModal}
          />
        }
      />
      {showEditModal && (
        <ProductTagsCreateModal
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          isEdit={true}
          productData={row}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          uniqueID={row?.sku}
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleDelete}
          isOperationLoading={isDeleting}
          isDataLoading={isLoading}
        />
      )}
    </>
  )
}

export default OverlayModal
