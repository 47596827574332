import {useQuery} from 'react-query'
import {getMappedUserList} from '../services'
import {dataToSelectList} from '../../../utils/common'

const useGetAllMappedUsers = (): any => {
  const {
    data: response,
    isFetching,
    refetch,
  } = useQuery(`get-all-mapped-users`, () => {
    return getMappedUserList(`/analytics/replenishment/mapped/users`, {})
  })

  if (!response?.['data']) {
    return []
  }

  return {
    mappedUsers: dataToSelectList(response?.['data']?.['data']),
    isFetchingData: isFetching,
    userList: response?.['data']?.['data'],
    refetch,
  }
}

export default useGetAllMappedUsers
