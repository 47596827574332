import {KTCard} from '../../../_metronic/helpers'
import {ReorderingContext} from '../context'
import {Pagination} from '../../../utils/pagination'
import {ReorderSearchComponent} from '../sections/ReorderSearchComponent'
import {useGetReorderingData} from '../hooks/useGetReorderingData'
import ReorderingTable from '../sections/ReorderingTable'

function Reordering() {
  const {
    isFetchingCsvData,
    reorderingData,
    reorderFilters,
    pagination,
    monthly_column,
    onPageChange,
    isLoading,
    onSearch,
    onClearAll,
    onClickSorting,   
    onDownloadCsv,    
    onApplyFilters,
    reorderRefetch,
    onResetFilters    
  } = useGetReorderingData()

  const contextValue = {
    isFetchingCsvData,
    isLoading,
    reorderingData,
    pagination,
    monthly_column,
    onPageChange,
    onSearch,
    onClearAll,
    onClickSorting,    
    onDownloadCsv,
    reorderFilters: reorderFilters,    
    onApplyFilters,    
    reorderRefetch,
    onResetFilters
  }

  return (
    <ReorderingContext.Provider value={contextValue}>
      <KTCard>
        <ReorderSearchComponent />
        <ReorderingTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </KTCard>
    </ReorderingContext.Provider>
  )
}

export default Reordering
