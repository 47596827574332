import {useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {SupplierMappingDropdown} from '../dropdown/SupplierMappingDropdown'
import UpdateSupplierModal from './UpdateSupplierModal'
import DeleteConfirmationModal from '../../../../components/modal/DeleteConfirmationModal'

const OverlayModal = ({setDeleteModal, name, onRemove}: any) => {
  const [openModal, setOpenModal] = useState(false)
  const [modal, setModal] = useState(false)
  const handleModalOpen = () => {
    setOpenModal(true)
    document.body.click()
  }
  const onEdit = () => {
    setModal(true)
    document.body.click()
  }
  const onClose = () => {
    setOpenModal(false)
    setModal(false)
  }
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <SupplierMappingDropdown
            toggleModal={handleModalOpen}
            setDeleteModal={setDeleteModal}
            onEdit={onEdit}
          />
        }
      />
      <UpdateSupplierModal show={modal} onClose={onClose} user={name} />
      <DeleteConfirmationModal
        uniqueID={name}
        show={openModal}
        onClose={onClose}
        onDelete={(userName: any) => onRemove(userName)}
      />
    </>
  )
}

export default OverlayModal
