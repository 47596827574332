const TagsTableRow = ({row, actionComponent, checkBoxComponent}: any) => {
  return (
    <tr>
      {checkBoxComponent && <td>{checkBoxComponent(row)}</td>}
      <td>
        <div className='text-uppercase'>{row['tag']}</div>
      </td>
      <td>
        <div className='text-center'>{row['productCount']}</div>
      </td>

      {actionComponent && <td>{actionComponent(row)}</td>}
    </tr>
  )
}

export default TagsTableRow
