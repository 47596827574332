import ClassifiedAsTableActionModal from '../model/ClassifiedAsTableActionModal'

const ClassifiedAsTableRow = ({
  row,
  hasWritePermission,
}: {
  row: any
  hasWritePermission: boolean
}) => {
  return (
    <tr>
      <td>{row.classifiedAs}</td>
      <td>{row.productCount}</td>
      {hasWritePermission && <ClassifiedAsTableActionModal row={row} />}
    </tr>
  )
}

export default ClassifiedAsTableRow
