import {useState} from 'react'
import { SafetyStockContext } from './context'
import SafetyStockHeader from './sections/headers/SafetyStockHeader'
import SafetyStockTable from './sections/table/SafetyStockTable'
import { Pagination } from '../../utils/pagination'
import useGetSafetyStockListing from './hooks/useGetSafetyStockListing'
import { PageTitle } from '../../_metronic/layout/core'
import { supplierManagementBreadCrumbs } from './utils'


const SafetyStock = () => {
  const {
    safetyStockListing,
    isLoading,
    filters,
    onSearch,
    onPageChange,
    onSortingChange,
    pagination,
  } = useGetSafetyStockListing()

  const [showModal, setShowModal] = useState(false)
  const [checkedRows, setCheckedRows] = useState<any>([])

  const contextValue = {
    safetyStockListing,
    isLoading,
    filters,
    pagination,
    onSearch,
    onSortingChange,
    showModal,
    setShowModal,
    checkedRows,
    setCheckedRows,
  }

  return (
    <SafetyStockContext.Provider value={contextValue}>
      <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Safety Stock</PageTitle>
      <SafetyStockHeader />
      <SafetyStockTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </SafetyStockContext.Provider>
  )
}

export default SafetyStock
