import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getFilters} from '../services'
import useFilters from '../../../hook/useFilters'
import { useSelector } from 'react-redux'


export const useGetReorderingFilters = (): any => {
  const { global_user } = useSelector((state: any) => state.filters);
  const initialFilters = {
    primary_supplier: '',
    user: global_user?.value !== '' ? global_user?.value : '',
    hide_discontinued: false
  }
  
  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`get-reordering-filters`, () => {
    return getFilters(filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  if (!response?.['data']) {
    return []
  }   

  const onResetDropdownFilters = () => {
    setMultipleFilters({
      primary_supplier: '',
      user: '',
    })
  }
  
  const handleUserChange = (user: string) => {
    if (!user) setSingleFilter('user', '')
    else setSingleFilter('user', user)
  }

  return {
    reorderFilters: response?.['data']?.['data'] || {
      primary_supplier: [],
    },
    isFetchingDropdownData: isFetching,
    refetchFilters: refetch,
    handleUserChange,
    onResetDropdownFilters,
  }
}
