import ReplenishmentData from './pages/ReplenishmentData'
import Reordering from './pages/Reordering'
import {ReplenishmentPageBreadCrumbs} from './utils'
import ReplenishmentDashboard from './pages/ReplenishmentDashboard'
import Classification from './pages/Classification'
import {Navigation} from '../../components/Navigation'

function ReplenishmentPage() {
  return (
    <Navigation
      baseUrl='/analytics/replenishment/dashboard'
      breadcrumbs={ReplenishmentPageBreadCrumbs}
      defaultKey='dashboard'
      navigationData={[
        {
          key: 'dashboard',
          label: 'Dashboard',
          component: <ReplenishmentDashboard />,
        },
        {
          key: 'replenishment',
          label: 'Replenishment',
          component: <ReplenishmentData />,
        },
        {
          key: 'reorder',
          label: 'Reordering',
          component: <Reordering />,
        },
        {
          key: 'classification',
          label: 'Classification',
          component: <Classification />,
        },
      ]}
    />
    // <Routes>
    //   <Route
    //     element={
    //       <>
    //         <ReplenishmentPageNavigation />
    //         <Outlet />
    //       </>
    //     }
    //   >
    //     <Route
    //       path='dashboard'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={ReplenishmentPageBreadCrumbs}>Dashboard</PageTitle>
    //           <ReplenishmentDashboard />
    //         </>
    //       }
    //     />
    //     <Route
    //       path='/data'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={ReplenishmentPageBreadCrumbs}>Replenishment</PageTitle>
    //           <ReplenishmentData />
    //         </>
    //       }
    //     />
    //     <Route
    //       path='/reorder'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={ReorderBreadCrumbs}>Reordering</PageTitle>
    //           <Reordering />
    //         </>
    //       }
    //     />
    //     <Route
    //       path='/classification'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={ReorderBreadCrumbs}>Classification</PageTitle>
    //           <Classification />
    //         </>
    //       }
    //     />
    //     {/* <Route
    //       path='/supplier-mapping'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Supplier Management</PageTitle>
    //           <SupplierManagement />
    //         </>
    //       }
    //     />
    //     <Route
    //       path='/product-tags'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Product Tags</PageTitle>
    //           <ProductTagsManagement />
    //         </>
    //       }
    //     /> */}
    //     {/* <Route
    //       path='/product-tags-management'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Tags Management</PageTitle>
    //           <TagsManagementPage />
    //         </>
    //       }
    //     /> */}
    //     {/* <Route
    //       path='/backorder'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Backorder</PageTitle>
    //           <BackOrder />
    //         </>
    //       }
    //     /> */}
    //     {/* <Route
    //       path='/safety-stock'
    //       element={
    //         <>
    //           <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Safety Stock</PageTitle>
    //           <SafetyStock />
    //         </>
    //       }
    //     /> */}

    //     <Route index element={<Navigate to='/analytics/replenishment/dashboard' />} />
    //   </Route>
    // </Routes>
    // </Provider>
  )
}

export default ReplenishmentPage
