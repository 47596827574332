export const NotificationsType = [
  {module_name: 'Order', events: ['order_created', 'order_updated']},
  {module_name: 'Products', events: ['product_added', 'product_updated']},
  {module_name: 'Project Management', events: ['task_created', 'task_completed']},
  {module_name: 'Settings', events: ['user_created']},
  {module_name: 'Storefront', events: ['user_themes_updated']},
  {module_name: 'Customers', events: ['customer_created']},
  {module_name: 'Bulk Order', events: ['po_created', 'order_placed', 'unlock_product']},
  {module_name: 'Block Order', events: ['block_order_created']},
  {module_name: 'Jobs', events: ['job_failed']},
]
