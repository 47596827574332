import {KTCard} from '../../../_metronic/helpers'
import {ProductsTagsContext} from '../context'
import ProductTagsTable from '../sections/table/ProductsTagsTable'
import {useState} from 'react'
import {Pagination} from '../../../utils/pagination'
import ProductTagsHeader from '../sections/headers/ProductTagsHeader'
import useGetAllProductsWithTags from '../hooks/useGetAllProductsWithTags'
import {PageTitle} from '../../../_metronic/layout/core'
import { supplierManagementBreadCrumbs } from '../utils'

function ProductTags() {
  const {
    productsTagsList,
    isLoading,
    filters,
    onSearch,
    onSortingChange,
    pagination,
    onPageChange,
  } = useGetAllProductsWithTags()
  const [checkedRows, setCheckedRows] = useState<any>([])

  const contextValue = {
    productsTagsList: productsTagsList,
    isLoading: isLoading,
    checkedRows: checkedRows,
    setCheckedRows: setCheckedRows,
    filters: filters,
    onSearch: onSearch,
    onSortingChange: onSortingChange,
  }

  return (
    <KTCard>
      <ProductsTagsContext.Provider value={contextValue}>
        <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Product Tags</PageTitle>
        <ProductTagsHeader />
        <ProductTagsTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProductsTagsContext.Provider>
    </KTCard>
  )
}

export default ProductTags
