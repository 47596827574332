import {useContext, useState} from 'react'
import usePermission from '../../../../hook/usePermission'
import {TagsManagementContext} from '../../context'
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal'
import CreateTagModal from '../model/CreateTagModal'

const TagsManagementHeader = () => {
  const {hasPermission} = usePermission()
  const [modal, setModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {checkedRows, isLoading, isOperationLoading, onDeleteTag} =
    useContext(TagsManagementContext)

  const handleDelete = () => {
    onDeleteTag({tagId: checkedRows})
  }

  return (
    <>
      {hasPermission('analytics_replenishment_product tags', 'write') && (
        <div className='d-flex align-items-center justify-content-center gap-4 justify-content-md-end mb-10'>
          {checkedRows.length > 0 && (
            <button className='btn btn-danger' onClick={() => setShowDeleteModal(true)}>
              Delete
            </button>
          )}
          <button onClick={() => setModal(true)} className='btn btn-primary'>
            <i className='bi bi-plus-lg'></i>
            Create Tag
          </button>
        </div>
      )}

      <CreateTagModal show={modal} onClose={() => setModal(false)} />
      <ConfirmationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        actionName='Yes, Delete'
        body={
          'By deleting this tag, we will also remove it from all the products to which it is assigned. Are you sure you want to delete this tag?'
        }
        isDataLoading={isLoading}
        isOperationLoading={isOperationLoading}
        onAction={handleDelete}
      />
    </>
  )
}

export default TagsManagementHeader
