export function ProductsTagsDropdown({setShowDeleteModal, setShowEditModal}: any) {
  const handleDelete = () => {
    setShowDeleteModal(true)
    document.body.click()
  }

  const handleEdit = () => {
    setShowEditModal(true)
    document.body.click()
  }

  return (
    <div>
      <div className='px-2 py-1' onClick={handleEdit}>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>
      <div className='px-2 py-1'>
        <span onClick={handleDelete} className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Delete</label>
        </span>
      </div>
    </div>
  )
}
