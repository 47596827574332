import {useState} from 'react'
import useApi from '../../../services/useApi'

export const useLiquidationReportOperations = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCsv, setIsLoadingCsv] = useState(false)

  const updateLiquidationReport = (payload: any, id: any) => {
    return Api.patch(`/products/liquidation/update/${id}`, payload, {
      setLoading: setIsLoading,
      refetch: ['liquidation-products-listing'],
      isDisableSneckCase: true,
      isToast: false,
    })
  }

  const removeProduct = (payload: any, id: any) => {
    return Api.patch(`/products/liquidation/update/${id}`, payload, {
      setLoading: setIsLoading,
      successMessage: 'Product removed successfully.',
      refetch: ['liquidation-products-listing'],
      isDisableSneckCase: true,
    })
  }

  const onDownloadCsv = async (ids: any) => {
    const csvFilters = {
      product_ids: ids.join(','),
    }

    return Api.get(`/products/liquidation/report/csv`, {
      filters: csvFilters,
      setLoading: setIsLoadingCsv,
      isToast: true,
      isErrorPageRedirection: false,
    })
  }

  return {
    isOprationLoading: isLoading,
    updateLiquidationReport,
    removeProduct,
    onDownloadCsv,
    isLoadingCsv,
  }
}
