/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import $ from 'jquery'
import 'daterangepicker'

interface PredefinedDateRangeProps {
  cb: (start: moment.Moment | string, end: moment.Moment | string) => void
  getLbl?: (label: string) => void
  exclude?: string[]
  maxRange?: number
  defaultValue?: string
  isClearable?: boolean
  placeholder?: string
  includeCurrantDay?: boolean
  resetKey?: number
}

function PredefinedDateRange({
  cb,
  getLbl,
  exclude = [],
  maxRange,
  defaultValue = '',
  isClearable = false,
  placeholder = 'Select date',
  includeCurrantDay = false,
  resetKey,
}: PredefinedDateRangeProps) {
  const allRanges: {[name: string]: [any, any]} = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [
      moment().subtract(7, 'days'),
      includeCurrantDay ? moment() : moment().subtract(1, 'days'),
    ],
    'This Month': [
      moment().startOf('month'),
      includeCurrantDay ? moment() : moment().subtract(1, 'days'),
    ],
    'Last 30 Days': [
      moment().subtract(30, 'days'),
      includeCurrantDay ? moment() : moment().subtract(1, 'days'),
    ],
    'Last 60 Days': [
      moment().subtract(60, 'days'),
      includeCurrantDay ? moment() : moment().subtract(1, 'days'),
    ],
    'Last 90 Days': [
      moment().subtract(90, 'days'),
      includeCurrantDay ? moment() : moment().subtract(1, 'days'),
    ],
    'Current Year': [
      moment().subtract(moment().dayOfYear(), 'days'),
      includeCurrantDay ? moment() : moment().subtract(1, 'days'),
    ],
  }

  const dateRangePickerRef = useRef<HTMLInputElement>(null)
  const [selectedLabel, setSelectedLabel] = useState<string>(defaultValue)
  const [selectedDate, setSelectedDate] = useState<any>({startDate: '', endDate: ''})
  const [start, setStart] = useState<any>(defaultValue ? allRanges[defaultValue][0] : moment())
  const [end, setEnd] = useState<any>(defaultValue ? allRanges[defaultValue][1] : moment())

  const ranges = Object.entries(allRanges).reduce((acc, [key, value]) => {
    if (!exclude.includes(key)) {
      acc[key] = value
    }
    return acc
  }, {} as {[name: string]: [any, any]})

  const options = {
    startDate: start,
    endDate: end,
    maxDate: moment(),
    ranges: ranges,
    ...(maxRange && {dateLimit: {days: maxRange}}),
  }

  useEffect(() => {
    const dateRangePickerElement = $(dateRangePickerRef.current as HTMLInputElement)
    if (dateRangePickerElement.length) {
      dateRangePickerElement.daterangepicker(options, (start: any, end: any, label: any) => {
        const daysDiff = end.diff(start, 'days')

        if (maxRange && daysDiff > maxRange) {
          alert(`Please select a date range of ${maxRange} days or less`)
          dateRangePickerElement.data('daterangepicker')!.setStartDate(moment().startOf('month'))
          dateRangePickerElement.data('daterangepicker')!.setEndDate(moment())
          return
        }

        if (label === 'Custom Range') {
          const customRange = `${start.format('YYYY-MM-DD')} to ${end.format('YYYY-MM-DD')}`
          setSelectedLabel(customRange)
          getLbl?.(customRange)
          localStorage.setItem('dateRangeLabel', customRange)
        } else {
          setSelectedLabel(label)
          getLbl?.(label)
          localStorage.setItem('dateRangeLabel', label)
        }

        if (label === 'Current Year') {
          const startDate = moment().subtract(moment().dayOfYear() - 1, 'days')
          const endDate = includeCurrantDay ? moment() : moment().subtract(1, 'days')
          cb(startDate, endDate)
          setSelectedDate({startDate: startDate, endDate: endDate})
        } else {
          cb(start, end)
          setSelectedDate({startDate: start, endDate: end})
        }
      })
    }

    if (defaultValue) {
      if (defaultValue === 'Current Year') {
        const startDate = moment().subtract(moment().dayOfYear() - 1, 'days')
        const endDate = includeCurrantDay ? moment() : moment().subtract(1, 'days')
        cb(startDate, endDate)
        setSelectedDate({startDate: startDate, endDate: endDate})
      } else {
        cb(start, end)
        setSelectedDate({startDate: start, endDate: end})
      }
    }

    return () => {
      if (dateRangePickerElement.length) {
        dateRangePickerElement.data('daterangepicker')?.remove()
      }
    }
  }, [])

  const handelCancel = () => {
    setStart(moment())
    setEnd(moment())
    setSelectedLabel('')
    setSelectedDate({startDate: '', endDate: ''})
    cb('', '')
    reinitializeDatePicker()
  }

  const resetToDefault = () => {
    setStart(defaultValue ? allRanges[defaultValue][0] : moment())
    setEnd(defaultValue ? allRanges[defaultValue][1] : moment())
    setSelectedLabel(defaultValue)
    setSelectedDate({startDate: '', endDate: ''})
    // cb('', '')
    // reinitializeDatePicker()
  }

  useEffect(() => {
    resetToDefault()
  }, [resetKey])

  const reinitializeDatePicker = () => {
    const dateRangePickerElement = $(dateRangePickerRef.current as HTMLInputElement)
    const freshOptions = {
      ...options,
      startDate: moment(),
      endDate: moment(),
    }
    dateRangePickerElement.daterangepicker(freshOptions, (start: any, end: any, label: any) => {
      if (label === 'Custom Range') {
        const customRange = `${start.format('YYYY-MM-DD')} to ${end.format('YYYY-MM-DD')}`
        setSelectedLabel(customRange)
      } else {
        setSelectedLabel(label)
      }
      setSelectedDate({startDate: start, endDate: end})
      cb(start, end)
    })
  }

  return (
    <div>
      <div className='input-group' ref={dateRangePickerRef} id='kt_daterangepicker_4'>
        <input
          className='form-control form-control-solid'
          placeholder={placeholder}
          value={selectedLabel}
          readOnly
        />
        {selectedDate?.startDate && selectedDate?.endDate && isClearable && (
          <i
            className='bi bi-x position-absolute end-0 me-13 cursor-pointer fs-2 mt-3'
            onClick={(event) => {
              event.stopPropagation()
              handelCancel()
            }}
          ></i>
        )}
        <span
          className='input-group-text btn btn-active-light-primary btn-sm btn-icon'
          data-inbox='calendar-show'
        >
          <i className='la la-calendar fs-2'></i>
        </span>
      </div>
    </div>
  )
}

export default PredefinedDateRange
