import {useState} from 'react'
import useApi from '../../../services/useApi'

const useProductTagsOperations = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isValidatingSku, setIsValidatingSku] = useState(false)
  const [fetchingProductTags, setFetchingProductTags] = useState(false)

  const validateChildSku = (sku: string, setError: any) => {
    return Api.get(`analytics/tags/products/validate`, {
      filters: {product_sku: sku},
      setLoading: setIsValidatingSku,
      isToast: false,
      isErrorPageRedirection: false,
      setUseFormError: setError,
    })
  }

  const getProductTags = async (sku: string) => {
    return await Api.get(`/analytics/replenishment/tags/products?product_sku=${sku}`, {
      setLoading: setFetchingProductTags,
    })
  }

  const assignProductTags = (payload: any) => {
    return Api.post(
      `/analytics/replenishment/tags/products/add`,
      {data: payload},
      {
        setLoading: setIsLoading,
        successMessage: 'Product assigned to tag successfully.',
        refetch: 'product-tags-listing',
        onError: ({error, response}) => {
          if (response?.response?.data?.records_processed > 0) {
            return {
              refetch: 'product-tags-listing',
              error,
            }
          } else {
            return error
          }
        },
      }
    )
  }

  const assignMultiProductTags = async (data: any) => {
    return Api.post('/analytics/replenishment/tags/products', data, {
      setLoading: setIsLoading,
      successMessage: 'Product tags assigned successfully.',
      refetch: ['product-tags-listing', 'get-Replenishment-data'],
      isDisableSneckCase: true,
    })
  }

  const updateProductTags = (payload: any) => {
    return Api.put(`/analytics/replenishment/tags/products`, payload, {
      setLoading: setIsLoading,
      successMessage: 'Product tags updated successfully.',
      refetch: ['product-tags-listing', 'get-Replenishment-data'],
      isDisableSneckCase: true,
    })
  }

  const deleteProductTags = (payload: any) => {
    return Api.delete(`/analytics/replenishment/tags/products`, payload, {
      setLoading: setIsDeleting,
      successMessage: 'Product tags removed successfully.',
      refetch: 'product-tags-listing',
      isDisableSneckCase: true,
    })
  }

  return {
    validateChildSku: validateChildSku,
    getProductTags: getProductTags,
    assignMultiProductTags: assignMultiProductTags,
    assignProductTags: assignProductTags,
    updateProductTags: updateProductTags,
    deleteProductTags: deleteProductTags,
    isValidatingSku: isValidatingSku,
    fetchingProductTags: fetchingProductTags,
    isDeleting: isDeleting,
    isLoading: isLoading,
  }
}

export default useProductTagsOperations
