import {CSVImporter} from '../../../../components/CSVImporter'
import usePermission from '../../../../hook/usePermission'
import {useContext, useState} from 'react'
import {SafetyStockContext} from '../../context'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import SampleCSVDownload from '../../../../components/SampleCSVDownload/SampleCSVDownload'
import useSafetyStockOperations from '../../hooks/useSafetyStockOperations'
import SafetyStockSearchComponent from './SafetyStockSearchComponent'
import SafetyStockCreateModal from '../model/SafetyStockCreateModal'
import {dataToSafetyStockCSV, safetyStockCsvConfig, sampleSafetyStockCSVData} from '../../utils'
import {useGetDataForCSV} from '../../../../hook/useGetDataForCSV'
import moment from 'moment'
import Import from '../../../../images/Import.png'

const SafetyStockHeader = () => {
  const {hasPermission} = usePermission()
  const {fetchDataForCSV, isLoading: isLoadingCSV} = useGetDataForCSV()
  const {showModal, setShowModal, safetyStockListing, checkedRows, isLoading, setCheckedRows} =
    useContext(SafetyStockContext)
  const {
    createSafetyStockSKU,
    isLoading: isOperationLoading,
    deleteSafetyStockSKU,
    isDeleting,
  } = useSafetyStockOperations()
  const [errors, setErrors] = useState<string[] | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const defaultData = Object.values(safetyStockListing).map((item: any) => ({
    sku: item.child_sku,
  }))

  const handleUploadSuccess = async (data: any) => {
    const {error} = await createSafetyStockSKU(data)
    if (error) {
      setErrors(error)
    }
  }

  const handleDelete = () => {
    setShowDeleteModal(true)
  }

  const handleDeleteConfirm = () => {
    deleteSafetyStockSKU({ids: checkedRows})
    setCheckedRows([])
  }

  const handleDownLoadCSV = async () => {
    const formattedDateTime = moment().format('D_MMM_HH_mm').toLowerCase()
    const res: any = await fetchDataForCSV('/analytics/replenishment/safetystock/sku/qty')
    if (res?.status === 200) {
      const data = dataToSafetyStockCSV(res?.data)
      if (data?.length) {
        SampleCSVDownload(data || [], `Safety_Stock_${formattedDateTime}`)
      }
    }
  }

  return (
    <>
      {hasPermission('analytics_replenishment_safety stock', 'write') && (
        <div className='d-flex justify-content-between mb-10 '>
          <div className='d-flex align-items-center gap-4'>
            <SafetyStockSearchComponent />
            {checkedRows.length > 0 && (
              <button className='btn btn-danger' onClick={handleDelete}>
                Delete
              </button>
            )}
            <button
              className='btn btn-outline'
              onClick={handleDownLoadCSV}
              disabled={isLoadingCSV || !defaultData?.length}
            >
              <div className='d-flex align-items-center'>
                <img src={Import} alt='Import File' className='me-2 import-icon' />
                <div className='d-flex align-items-center'>
                  {isLoadingCSV ? 'Downloading...' : 'Export CSV'}
                  {isLoadingCSV && (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  )}
                </div>
              </div>
            </button>
          </div>

          <div className='d-flex align-items-center justify-content-center gap-4'>
            <button
              className='btn'
              onClick={() =>
                SampleCSVDownload(sampleSafetyStockCSVData, 'Sample Safety Stock File')
              }
            >
              <span className='text-decoration-underline'>Download Sample CSV</span>
            </button>
            <CSVImporter
              id={'replenishment-back-order'}
              config={safetyStockCsvConfig}
              existingData={defaultData}
              onSuccess={handleUploadSuccess}
              isLoading={isOperationLoading}
            />

            <button className='btn btn-primary' onClick={() => setShowModal(true)}>
              <i className='bi bi-plus-lg'></i>
              Add Safety Stock
            </button>

            <SafetyStockCreateModal showModal={showModal} setShowModal={setShowModal} />
          </div>
        </div>
      )}
      {errors && Array.isArray(errors) && (
        <ConfirmationModal
          show={errors ? true : false}
          onClose={() => setErrors(null)}
          title={'CSV Import Error!'}
          body={
            <ul>
              {errors.map((error: any, index: number) => (
                <li key={index} className='mb-3'>
                  {error?.key?.toUpperCase()}: {error?.message}
                </li>
              ))}
            </ul>
          }
          onAction={() => setErrors(null)}
          disableAction={true}
        />
      )}
      <ConfirmationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onAction={handleDeleteConfirm}
        isOperationLoading={isDeleting}
        isDataLoading={isLoading}
      />
    </>
  )
}

export default SafetyStockHeader
