import React, {useRef, useEffect} from 'react'
import ChildTableHeader from './ChildTableHeader'
import ChildTableRow from './ChildTableRow'

function DefaultProductChildTable({
  child_data,
  monthly_column,
  setItemToBeOrderd,
  isColumnSelected,
}: any) {
  const inputRefs = useRef([])

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, child_data.length)
    inputRefs.current = child_data.map(
      (_: any, i: number) => inputRefs.current[i] || React.createRef()
    )
  }, [child_data])

  const originalObject = monthly_column !== undefined ? monthly_column : {}
  const reversedObject = Object.fromEntries(Object.entries(originalObject).reverse())
  const monthly_column_keys = Object.keys(reversedObject)

  return (
    <div className='table-responsive remove-overflow'>
      <table className='table gs-0 gy-3 align-middle my-3 table-row-bordered border'>
        <ChildTableHeader monthly_column={monthly_column} isColumnSelected={isColumnSelected} />
        <tbody>
          {child_data?.length
            ? child_data.map((i: any, index: any) => {
                const arrayLength = child_data.length

                return (
                  <ChildTableRow
                    i={i}
                    index={index}
                    monthly_column_keys={monthly_column_keys}
                    arrayLength={arrayLength}
                    inputRef={inputRefs.current[index]}
                    inputRefArr={inputRefs}
                    setItemToBeOrderd={setItemToBeOrderd}
                    isColumnSelected={isColumnSelected}
                  />
                )
              })
            : null}
        </tbody>
      </table>
    </div>
  )
}

export default DefaultProductChildTable
