import {useContext, useEffect, useState} from 'react'
import {ReplenishmentContext} from '../../context'
import TableRow from './TableRow'
import {isEmpty, toggleSortingOrder} from '../../../../utils/common'
import Loading from '../../../loading'
import {Pagination} from '../../../../utils/pagination'
import {RenderBothArrow} from '../../../../utils/renderBothArrow'
import NoDataFound from '../../../../components/NoDataFound'
import useGetJobData from '../../../jobs/hooks/useGetJobData'
import usePermission from '../../../../hook/usePermission'
import {formatDate} from '../../../../utils/date'
import fullScreenIcon from '../../../../images/maximize.svg'
import showChildIcon from '../../../../images/3square.svg'
import columnsIcon from '../../../../images/grid.svg'
import minimizeIcon from '../../../../images/minimize.svg'
import hideChildIcon from '../../../../images/hide.svg'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {MultiSelect} from '../../../../components/MultiSelect/'
import useReplenishmentOperations from '../../hooks/useReplenishmentOperations'

function ReplenishmentTable({pagination, onPageChange}: any) {
  const {hasPermission} = usePermission()
  const hasWritePermission = hasPermission('analytics_replenishment_dashboard', 'write') ?? false
  const {saveTableColumns} = useReplenishmentOperations()
  const {jobData, isProcessing, runTask} = useGetJobData('update_analytics_tables')
  const {
    isFetchingReplenishmentData,
    replenishment,
    monthly_column,
    dataFilters,
    isColumnSelected,
    allTableColumns,
    selectedColumns,
    onClickSorting,
    register,
    setSelectedColumns,
  }: any = useContext(ReplenishmentContext)
  // const monthly_column_keys = monthly_column !== undefined ? Object.keys(monthly_column) : undefined

  const originalObject = monthly_column !== undefined ? monthly_column : {}
  const reversedObject = Object.fromEntries(Object.entries(originalObject).reverse())
  const monthly_column_keys = Object.keys(reversedObject)

  const [direction, setDirection] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [showChildTables, setShowChildTables] = useState(false)

  useEffect(() => {
    if (dataFilters) {
      const array = dataFilters['sort_by'].split('/')
      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [dataFilters])

  if (isFetchingReplenishmentData || isEmpty(replenishment)) {
    return (
      <div className='position-relative min-h-350px'>
        <Loading />
      </div>
    )
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleToggleAllChildTables = () => {
    setShowChildTables((prevState) => !prevState)
  }

  return (
    <div className='card-body p-0'>
      <div className={`table-modes ${expanded ? 'enable-expand-view' : ''}`}>
        <div className='table-mode-buttons mb-10 text-end d-flex align-items-center justify-content-between'>
          <div className='d-flex gap-10 align-items-center'>
            <div className='table-fullscreen-link'>
              <span
                className='cursor-pointer text-dark fw-bold text-active-primary d-flex align-items-center'
                onClick={handleExpandClick}
              >
                {expanded && (
                  <>
                    <img src={minimizeIcon} alt='minimize' className='w-25px h-25px me-2' />{' '}
                    Minimize
                  </>
                )}
                {!expanded && (
                  <>
                    <img src={fullScreenIcon} alt='fullscreen' className='w-25px h-25px me-2' />{' '}
                    Full Screen
                  </>
                )}
              </span>
            </div>

            <div className='table-child-link d-flex align-items-center'>
              {showChildTables ? (
                <span
                  className='cursor-pointer text-dark fw-bold text-active-primary d-flex align-items-center'
                  onClick={handleToggleAllChildTables}
                >
                  <img src={hideChildIcon} alt='hide child' className='w-25px h-25px me-2' />
                  Hide child
                </span>
              ) : (
                <span
                  className='cursor-pointer text-dark fw-bold text-active-primary d-flex align-items-center'
                  onClick={handleToggleAllChildTables}
                >
                  <img src={showChildIcon} alt='show child' className='w-25px h-25px me-2' />
                  Show Child
                </span>
              )}
            </div>

            <div className='me-10'>
              <OverlayComponent
                btnIcon={
                  <div className='d-flex d-flex align-items-center'>
                    <img src={columnsIcon} alt='columns' className='w-25px h-25px me-2' />
                    Columns
                  </div>
                }
                className='w-100'
                popoverClass='h-400px'
                placement={'bottom'}
                children={
                  <MultiSelect
                    id='replenishment-columns'
                    options={allTableColumns}
                    className='w-350px'
                    handleSelected={(values: any) => {
                      setSelectedColumns(values)
                      saveTableColumns(
                        values?.map((value: any) => value.value),
                        'replenishment'
                      )
                    }}
                    isOpen={true}
                    selectedValues={selectedColumns}
                  />
                }
              />
            </div>
          </div>

          {hasPermission('settings_jobs', 'write') && (
            <div className='position-relative d-flex align-items-center me-10'>
              <span className='me-2'>Last Sync:</span>
              <span className='me-3'>{formatDate(jobData.last_run_end_time, false)} </span>
              {!isProcessing && (
                <>
                  {
                    <i
                      className='fas fa-sync cursor-pointer fs-5'
                      onClick={() => runTask('update_analytics_tables')}
                    ></i>
                  }
                </>
              )}
              {isProcessing && (
                <div className='d-flex justify-content-center'>
                  <div className='spinner-border text-success' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className='table-responsive-wrapper'>
          <div className='table-responsive position-relative'>
            <div className='table-loader-wrapper'>
              <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-3 replenishment-sticky-table'>
                <thead>
                  <tr className='fw-semibold text-muted text-uppercase'>
                    <th className='min-w-40px w-40px head-col-sticky'></th>
                    <th className='min-w-50px w-50px head-col-sticky'></th>

                    <th className='min-w-150px w-150px head-col-sticky'>{'SKU'}</th>

                    <th
                      className={`min-w-400px w-400px head-col-sticky`}
                      onClick={() => onClickSorting('product_title', toggleSortingOrder(direction))}
                    >
                      <div className='d-flex align-items-center head-col-sticky'>
                        <span className='me-2'>{'Title'}</span>

                        {direction === 'asc' && sortBy === 'product_title' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'product_title' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>

                    {isColumnSelected('classification') && (
                      <th className='min-w-250px w-250px head-col-sticky'>{'Classification'}</th>
                    )}

                    {isColumnSelected('reserved_quantity') && (
                      <th className='min-w-150px w-150px text-center head-col-sticky'>
                        {'Blocked Qty'}
                      </th>
                    )}
                    {isColumnSelected('quantity_available') && (
                      <th className={`min-w-100px w-100px text-center head-col-sticky`}>
                        {'Available'}
                      </th>
                    )}
                    {isColumnSelected('safetystock_quantity') && (
                      <th className={`min-w-150px w-100px text-center head-col-sticky`}>
                        {'Safety Stock'}
                      </th>
                    )}
                    {isColumnSelected('quantity_incoming') && (
                      <th className={`min-w-100px w-100px text-center head-col-sticky`}>
                        {'Incoming'}
                      </th>
                    )}
                    {isColumnSelected('backorder_quantity') && (
                      <th className={`min-w-100px w-100px text-center head-col-sticky`}>
                        {'Backorder'}
                      </th>
                    )}
                    {isColumnSelected('total_sold_30') && (
                      <th
                        className={`min-w-150px w-150px text-center`}
                      >{`Total Sold (${dataFilters?.days_to_replenishment})`}</th>
                    )}
                    {isColumnSelected('incremental_quantity') && (
                      <th className={`min-w-180px w-180px text-center`}>{'Incremental Qty'}</th>
                    )}
                    {isColumnSelected('case_qty') && (
                      <th className={`min-w-150px w-150px text-center`}>{'Case Quantity'}</th>
                    )}
                    {isColumnSelected('suggested_order_qty') && (
                      <th
                        className={`min-w-250px w-250px`}
                        onClick={() =>
                          onClickSorting('suggested_order_qty', toggleSortingOrder(direction))
                        }
                      >
                        <div className='d-flex justify-content-center align-items-center'>
                          <span className='me-2'>{'Suggested Order Qty'}</span>

                          {direction === 'asc' && sortBy === 'suggested_order_qty' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && sortBy === 'suggested_order_qty' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                    )}
                    {isColumnSelected('suggested_order_qty_45') && (
                      <th
                        className={`min-w-250px w-250px`}
                        onClick={() =>
                          onClickSorting('suggested_order_qty_45', toggleSortingOrder(direction))
                        }
                      >
                        <div className='d-flex justify-content-center align-items-center'>
                          <span className='me-2'>{'Suggested Order Qty 45'}</span>

                          {direction === 'asc' && sortBy === 'suggested_order_qty_45' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && sortBy === 'suggested_order_qty_45' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                    )}
                    {isColumnSelected('suggested_order_qty_60') && (
                      <th
                        className={`min-w-250px w-250px`}
                        onClick={() =>
                          onClickSorting('suggested_order_qty_60', toggleSortingOrder(direction))
                        }
                      >
                        <div className='d-flex justify-content-center align-items-center'>
                          <span className='me-2'>{'Suggested Order Qty 60'}</span>

                          {direction === 'asc' && sortBy === 'suggested_order_qty_60' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && sortBy === 'suggested_order_qty_60' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                    )}
                    {isColumnSelected('to_order_qty') && (
                      <th className={`min-w-200px w-200px text-center`}>{'To Order Quantity'}</th>
                    )}
                    {isColumnSelected('item_order_qty') && (
                      <th className={`min-w-120px w-120px text-center`}>{'Item To Be Ordered'}</th>
                    )}
                    {monthly_column_keys &&
                      monthly_column_keys !== undefined &&
                      monthly_column_keys.map((column) => (
                        <th key={monthly_column[column]} className={`min-w-80px w-80px`}>
                          {monthly_column[column]}
                        </th>
                      ))}
                    {isColumnSelected('total_quantity') && (
                      <th className='min-w-120px w-120px text-center'>{'Available + Incoming'}</th>
                    )}
                    {isColumnSelected('days_out_of_stock') && (
                      <th className='min-w-175px w-200px text-center'>{'Out of Stock Days'}</th>
                    )}
                    {isColumnSelected('cost') && (
                      <th className={`min-w-100px w-100px`}>{'Cost'}</th>
                    )}
                    {isColumnSelected('total_cost') && (
                      <th className={`min-w-150px w-150px text-center`}>{'Total Cost'}</th>
                    )}
                    {/* <th className='min-w-200px'>{'Brand'}</th> */}
                    {isColumnSelected('primary_supplier') && (
                      <th
                        className={`min-w-300px w-300px`}
                        onClick={() =>
                          onClickSorting('primary_supplier', toggleSortingOrder(direction))
                        }
                      >
                        <div className='d-flex align-items-center'>
                          <span className='me-2'>{'Supplier Company Name'}</span>

                          {direction === 'asc' && sortBy === 'primary_supplier' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && sortBy === 'primary_supplier' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                    )}

                    {isColumnSelected('weeks_on_hand') && (
                      <th className={`min-w-150px w-150px text-center`}>{'Weeks On Hand'}</th>
                    )}
                    {isColumnSelected('turn_rate') && (
                      <th className={`min-w-100px w-100px text-center`}>{'Turn Rate'}</th>
                    )}

                    {hasWritePermission && (
                      <th className='min-w-80px w-80px text-center'>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {replenishment && replenishment.length > 0
                    ? replenishment.map((row: any) => (
                        <TableRow
                          row={row}
                          monthly_column_keys={monthly_column_keys}
                          monthly_column={monthly_column}
                          register={register}
                          key={row['sku']}
                          showChildTables={showChildTables}
                        />
                      ))
                    : !(
                        isFetchingReplenishmentData || isFetchingReplenishmentData === undefined
                      ) && (
                        <NoDataFound
                          colspan={selectedColumns?.length === 0 ? 26 : selectedColumns?.length + 3}
                        />
                      )}
                </tbody>
              </table>
              {(isFetchingReplenishmentData || isFetchingReplenishmentData === undefined) && (
                <Loading />
              )}
            </div>
          </div>
        </div>
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </div>
    </div>
  )
}

export default ReplenishmentTable
