import {createContext} from 'react'

export const ReplenishmentDashboardContext = createContext({
  replenishment: [],
  onFilter: (searchValue: any, searchKey: string) => {},
  dataFilters: {},
  isFetchingReplenishmentData: false,
  onSearch: (
    searchTerm: string,
    hide_discontinued: boolean,
    show_zero_qty_products: boolean,
    no_sold_days: boolean
  ) => {},
  pagination: {},
  monthly_column: {},
  meta_price_lists: {},
  onPageChange: (e: string) => {},
  refetch: () => {},
  onClickSorting: (sortBy: string, sortDirection: any) => {},
  onApplyFilters: (data: any) => {},
  onResetFilters: () => {},
  handleResetClick: () => {},
  register: () => {},
  selectedSkus: {skus: []},
  getProductTagsUsingSku: (sku: string) => {},
  tags: [],
  isFetchingTagData: false,
  onAddTag: (tag: string) => {},
  onMultyProductAddTags: (data: any) => {},
  onUpdateProductTags: (data: any) => {},
  allTableColumns: [] as any,
  selectedColumns: [] as any,
  setSelectedColumns: (newData: any) => {},
  isColumnSelected: (columnName: string) => {},
  mappedUsers: [],
  isFetchingData: false,
  isUserExist: false,
  onDownloadCsv: (data: any) => {},
  isFetchingCsvData: false,
  classifiedAsList: [],
  isLoading: false,
})

export const ReplenishmentContext = createContext({
  replenishment: [],
  classifications: [],
  onFilter: (searchValue: any, searchKey: string) => {},
  dataFilters: {},
  isFetchingReplenishmentData: false,
  onSearch: (
    searchTerm: string,
    hide_discontinued: boolean,
    show_zero_qty_products: boolean,
    no_sold_days: boolean
  ) => {},
  pagination: {},
  monthly_column: {},
  onPageChange: (e: string) => {},
  refetch: () => {},
  onClickSorting: (sortBy: string, sortDirection: any) => {},
  onApplyFilters: (data: any) => {},
  onResetFilters: () => {},
  handleResetClick: () => {},
  register: () => {},
  selectedSkus: {skus: []},
  getProductTagsUsingSku: (sku: string) => {},
  tags: [],
  isFetchingTagData: false,
  onAddTag: (tag: string) => {},
  onMultyProductAddTags: (data: any) => {},
  onUpdateProductTags: (data: any) => {},
  onDownloadCsv: (data: any) => {},
  isFetchingCsvData: false,
  allTableColumns: [] as any,
  selectedColumns: [] as any,
  setSelectedColumns: (newData: any) => {},
  isColumnSelected: (columnName: string) => {},
  onClassificationChange: (payload: any) => {},
})

export const ReorderingContext = createContext({
  isLoading: false,
  isFetchingCsvData: false,
  reorderFilters: {},
  reorderingData: [],
  pagination: {},
  monthly_column: {},
  onPageChange: (e: string) => {},
  onSearch: (searchTerm: string, searchKey: string) => {},
  onClearAll: () => {},
  onClickSorting: (sortBy: string, sortDirection: any) => {},
  onDownloadCsv: () => {},
  onApplyFilters: (data: any) => {},
  reorderRefetch: () => {},
  onResetFilters: () => {},
})

export const UserSupplierMappingContext = createContext({
  data: [],
  isLoading: false,
  onSave: (data: any) => {},
  onRemove: (userName: string) => {},
  onEditMapping: (userName: string) => {},
})

export const ProductsTagsContext = createContext({
  productsTagsList: [],
  isLoading: false,
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
})

export const TagsManagementContext = createContext({
  tags: [],
  isLoading: false,
  onAddTag: (data: any, setError: Function) => {},
  refetch: () => {},
  onDeleteTag: (data: any) => {},
  onUpdateTag: (tagId: string, newTag: string) => {},
  isOperationLoading: false,
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
})

export const BackOrderContext = createContext({
  backOrderListing: {},
  isLoading: true,
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
  showModal: false,
  setShowModal: (showModal: boolean) => {},
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
})

export const SafetyStockContext = createContext({
  safetyStockListing: {},
  isLoading: true,
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
  showModal: false,
  setShowModal: (showModal: boolean) => {},
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
})

export const ClassificationContext = createContext({
  ClassificationData: {},
  isLoading: true,
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
  isOperationLoading: false,
  onApprove: (payload: any) => {},
  onReject: (payload: any) => {},
  Classifications: [],
  onClassificationChange: (payload: any) => {},
  onClassificationFilterChange: (newClassification: any) => {},
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
})

export const ClassifiedAsPageContext = createContext({
  classifiedAsList: [],
  isLoading: false,
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},

  createClassifiedAs: (payload: any) => {},
  updateClassifiedAs: (payload: any, id: number) => {},
  deleteClassifiedAs: (id: number) => {},
  isLoadingOperations: false,
})
