import {parseLinkedTicketData} from '../../parsing'
import {Link} from 'react-router-dom'
import {useContext, useState} from 'react'
import {TicketDetailsPageContext} from '../../context'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {LinkedTicketsTableTypes} from '../../interface'

const LinkedTicketsTable = ({data, type}: LinkedTicketsTableTypes) => {
  const {projectId, removeLinkedTicket, isLoadingTicketOperation, isLoadingTicketDetails} =
    useContext(TicketDetailsPageContext)
  const [showModal, setShowModal] = useState(false)

  const onClickRemoveLinkedTicket = (data: any) => {
    const payload =
      type === 'parent' ? {parentCardId: data?.ticketId} : {childCardId: data?.ticketId}
    removeLinkedTicket(payload)
  }

  return (
    <div className='linked-tickets-container mb-5'>
      {data?.map((item: any) => {
        const ticket = parseLinkedTicketData(item)
        return (
          <>
            <div className='linked-ticket-item d-flex justify-content-between align-items-center p-3 border-bottom'>
              <div className='d-flex align-items-center gap-5'>
                <Link
                  to={`/projects/all-projects/${projectId}/ticket/${ticket?.ticketId}/${ticket?.ticketInitial}`}
                >
                  #{ticket?.ticketInitial}
                </Link>
                <span>{ticket?.ticketName}</span>
              </div>

              <div className='d-flex align-items-center'>
                <button
                  className='btn btn-link text-hover-danger text-muted'
                  onClick={() => setShowModal(true)}
                >
                  <i className='fa-solid fa-link-slash'></i> Remove
                </button>
              </div>
            </div>

            {showModal && (
              <ConfirmationModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onAction={() => onClickRemoveLinkedTicket(ticket)}
                isOperationLoading={isLoadingTicketOperation}
                isDataLoading={isLoadingTicketDetails}
                body={
                  <>
                    <p>Do you want to remove this ticket as {type} ticket?</p>
                  </>
                }
              />
            )}
          </>
        )
      })}
    </div>
  )
}

export default LinkedTicketsTable
