import {CSVImporter} from '../../../../components/CSVImporter'
import usePermission from '../../../../hook/usePermission'
import {useContext, useState} from 'react'
import BackOrderCreateModal from '../model/BackOrderCreateModal'
import {BackOrderContext} from '../../context'
import useBackOrderOperations from '../../hooks/useBackOrderOperations'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import SampleCSVDownload from '../../../../components/SampleCSVDownload/SampleCSVDownload'
import {backOrderCsvConfig, dataToBackOrderCSV, sampleBackOrderCSVData} from '../../utils'
import BackOrderSearchComponent from './BackOrderSearchComponent'
import {useGetDataForCSV} from '../../../../hook/useGetDataForCSV'
import moment from 'moment'
import Import from '../../../../images/Import.png'

const BackOrderHeader = () => {
  const {hasPermission} = usePermission()
  const {fetchDataForCSV, isLoading: isLoadingCSV} = useGetDataForCSV()
  const {showModal, setShowModal, backOrderListing, checkedRows, isLoading, setCheckedRows} =
    useContext(BackOrderContext)
  const {
    createBackOrderSKU,
    isLoading: isOperationLoading,
    deleteBackOrderSKU,
    isDeleting,
  } = useBackOrderOperations()
  const [errors, setErrors] = useState<string[] | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const defaultData = Object.values(backOrderListing).map((item: any) => ({
    sku: item.child_sku,
  }))

  const handleUploadSuccess = async (data: any) => {
    const {error} = await createBackOrderSKU(data)
    if (error) {
      setErrors(error)
    }
  }

  const handleDelete = () => {
    setShowDeleteModal(true)
  }

  const handleDeleteConfirm = () => {
    deleteBackOrderSKU({ids: checkedRows})
    setCheckedRows([])
  }

  const handleDownLoadCSV = async () => {
    const formattedDateTime = moment().format('D_MMM_HH_mm').toLowerCase()
    const res: any = await fetchDataForCSV('/analytics/replenishment/backorder/sku/qty')
    if (res?.status === 200) {
      const data = dataToBackOrderCSV(res?.data)
      if (data?.length) {
        SampleCSVDownload(data || [], `Back_Order_${formattedDateTime}`)
      }
    }
  }

  return (
    <>
      {hasPermission('analytics_replenishment_backorder', 'write') && (
        <div className='d-flex justify-content-between mb-10 '>
          <div className='d-flex align-items-center gap-4'>
            <BackOrderSearchComponent />
            {checkedRows.length > 0 && (
              <button className='btn btn-danger' onClick={handleDelete}>
                Delete
              </button>
            )}
            <button
              className='btn btn-outline'
              onClick={handleDownLoadCSV}
              disabled={isLoadingCSV || !defaultData?.length}
            >
              <div className='d-flex align-items-center'>
                <img src={Import} alt='Import File' className='me-2 import-icon' />
                <div className='d-flex align-items-center'>
                  {isLoadingCSV ? 'Downloading...' : 'Export CSV'}
                  {isLoadingCSV && (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  )}
                </div>
              </div>
            </button>
          </div>

          <div className='d-flex align-items-center justify-content-center gap-4'>
            <button
              className='btn'
              onClick={() => SampleCSVDownload(sampleBackOrderCSVData, 'Sample Backorder File')}
            >
              <span className='text-decoration-underline'>Download Sample CSV</span>
            </button>
            <CSVImporter
              id={'replenishment-back-order'}
              config={backOrderCsvConfig}
              existingData={defaultData}
              onSuccess={handleUploadSuccess}
              isLoading={isOperationLoading}
            />

            <button className='btn btn-primary' onClick={() => setShowModal(true)}>
              <i className='bi bi-plus-lg'></i>
              Add SKU
            </button>

            <BackOrderCreateModal showModal={showModal} setShowModal={setShowModal} />
          </div>
        </div>
      )}
      {errors && Array.isArray(errors) && (
        <ConfirmationModal
          show={errors ? true : false}
          onClose={() => setErrors(null)}
          title={'CSV Import Error!'}
          body={
            <ul>
              {errors.map((error: any, index: number) => (
                <li key={index} className='mb-3'>
                  {error?.key?.toUpperCase()}: {error?.message}
                </li>
              ))}
            </ul>
          }
          onAction={() => setErrors(null)}
          disableAction={true}
        />
      )}
      <ConfirmationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onAction={handleDeleteConfirm}
        isOperationLoading={isDeleting}
        isDataLoading={isLoading}
      />
    </>
  )
}

export default BackOrderHeader
