import './styles/gantt.scss'
import './styles/ganttChartTheme.scss'
import {useEffect, useRef} from 'react'
import {Chart} from './components/Chart'
import GanttErrorBoundary from './components/GanttErrorBoundary'
import {highlightTime} from './utils'
import {GanttChartProps} from './types'
import FullScreenWrapper from './components/FullScreenWrapper'
import Loading from '../../modules/loading'

const GanttChart = ({
  readOnly = false,
  data,
  links = [],
  scales,
  tableColumns: columns = [],
  taskDrag = true,
  taskResize = true,
  columnResize = true,
  sort = true,
  filter = true,
  keyboard = true,
  undo = true,
  zoom = true,
  multiselect = true,
  cellHeight = 38,
  cellWidth = 100,
  scaleHeight = 36,
  onChange,
  onSortChartRows,
  childMoveBetweenGroups = false,
  isTaskCreatable = false,
  fullScreen = false,
  taskTemplate,
  weekends = [6, 0],
  highlightWeekend = false,
  startDate,
  endDate,
  cellBorders = 'columns',
  init: externalInit,
  chartApisRef,
  isLoading = false,
  enableRowSelection = true,
}: GanttChartProps) => {
  const apiRef = useRef<any>()

  function init(api: any) {
    apiRef.current = api
    chartApisRef.current = api
    api.intercept('show-editor', () => !!isTaskCreatable)
    api.intercept('select-task', () => !!enableRowSelection)
    externalInit && externalInit(api)
    api.on('update-task', (ev: any) => {
      eventHandlers.onChange(ev)
    })
    api.on('move-task', (ev: any) => {
      eventHandlers.onSortChartRows(ev)
    })
  }

  // Event handlers with proper typing
  const eventHandlers = {
    onChange: (event: any) => {
      if (onChange) onChange(event)
    },
    onSortChartRows: (event: any) => {
      if (onSortChartRows) onSortChartRows(event)
    },
  }

  // Load icons dynamically to avoid SSR issues
  useEffect(() => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://cdn.svar.dev/fonts/wxi/wx-icons.css'
    document.head.appendChild(link)
    return () => {
      document.head.removeChild(link)
    }
  }, [])

  const chartRenderer = () => {
    return (
      <Chart
        init={init}
        tasks={data}
        links={links}
        {...(scales ? {scales} : {})}
        columns={columns}
        readonly={readOnly}
        drag={taskDrag}
        resize={taskResize}
        columnResize={columnResize}
        sort={sort}
        filter={filter}
        keyboard={keyboard}
        undo={undo}
        zoom={zoom}
        multiselect={multiselect}
        cellHeight={cellHeight}
        cellWidth={cellWidth}
        scaleHeight={scaleHeight}
        childMoveBetweenGroups={childMoveBetweenGroups}
        fullScreen={fullScreen}
        taskTemplate={taskTemplate}
        weekends={weekends}
        highlightTime={highlightWeekend ? highlightTime : false}
        startDate={startDate}
        endDate={endDate}
        cellBorders={cellBorders}
      />
    )
  }

  return (
    <GanttErrorBoundary>
      <div className={`wx-willow-theme ${isLoading ? 'position-relative min-h-350px' : ''}`}>
        {isLoading ? (
          <Loading />
        ) : !isLoading && data.length > 0 ? (
          fullScreen ? (
            <FullScreenWrapper hotkey='ctrl+shift+f'>{chartRenderer()}</FullScreenWrapper>
          ) : (
            chartRenderer()
          )
        ) : (
          <div className='text-center fw-bold'>No chart data found.</div>
        )}
      </div>
    </GanttErrorBoundary>
  )
}

export default GanttChart
