import {useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {Controller, useForm} from 'react-hook-form'
import ReactSelect from '../../../../components/ReactSelect'
import useGetUserList from '../../../user/hooks/useGetUserList'
import useGetAllSuppliers from '../../hooks/useGetAllSuppliers'
import {UserSupplierMappingContext} from '../../context'
import {extractValues} from '../../utils'
import {dataToSelectList} from '../../../../utils/common'
import MultipleSelect from '../../../../components/MultiSelect'

const defaultValues = {
  name: '',
  suppliers: [],
}

function UserSupplierModal({show, onClose}: any) {
  const {onSave} = useContext(UserSupplierMappingContext)

  const {
    control,
    reset,
    handleSubmit,
    register,
    setValue,
    formState: {errors},
  } = useForm<any>({
    defaultValues,
  })

  const {users} = useGetUserList(true)
  const {suppliers} = useGetAllSuppliers()

  const onSubmit = async (formData: any) => {
    const allValues = extractValues(formData['suppliers'])
    const selectedName = formData['name']['value']
    const matchedUser = users.find((user: any) => user.name.trim() === selectedName.trim())
    
    const reqObj: any = {
      user_name: selectedName,
      suppliers: allValues,
      email_id: matchedUser ? matchedUser.username : null,
    }

    const res: any = await onSave(reqObj)
    if (res) onClose()
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_create_supplier_mapping'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
      onHide={onClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{'Create Mapping'}</h4>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => {
              onClose()
              reset()
            }}
          >
            <i className='bi bi-x fs-1 text-dark'></i>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body'>
            <div className=''>
              <Controller
                name='name'
                control={control}
                render={({field: {onChange, value, name}}) => (
                  <ReactSelect
                    name={name}
                    value={value}
                    label={'Select User'}
                    options={dataToSelectList(users) || []}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className=''>
              <div className='form-label'>{'Select Suppliers'}</div>
              <MultipleSelect
                defaultValues={[]}
                options={dataToSelectList(suppliers) || []}
                handleSelected={(e: any) => setValue('suppliers', e)}
                {...register('suppliers', {required: true})}
              />
              {errors?.suppliers?.type === 'required' || errors?.name?.type === 'required' ? (
                <span className='mt-1 form-error text-danger'>
                  Name and Supplier are the required fields.
                </span>
              ) : null}
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={() => {
                onClose()
                reset()
              }}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Add
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default UserSupplierModal
