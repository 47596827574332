import {useContext, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {TagsManagementContext} from '../../context'
import {yupResolver} from '@hookform/resolvers/yup'
import {createTagValidationSchema as createSchema} from '../../validations'

function UpdateTagModal({show, onClose, tagId, oldName}: any) {
  const {onUpdateTag, refetch, isLoading, isOperationLoading} = useContext(TagsManagementContext)
  const defaultValues = {
    tags: oldName,
  }
  const {
    reset,
    handleSubmit,
    register,
    formState: {errors},
  } = useForm<any>({
    defaultValues,
    resolver: yupResolver(createSchema),
  })

  const onSubmit = async (formData: any) => {
    const NewName = formData?.tags
    onUpdateTag(tagId, NewName)
  }

  useEffect(() => {
    if (isLoading) {
      reset()
      onClose()
      refetch()
    }
  }, [isLoading, onClose, refetch, reset])

  const cancelCreate = () => {
    reset()
    onClose()
  }

  if (!show) {
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_update_tag'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
      onHide={onClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>{'Update Tag'}</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={cancelCreate}>
            <i className='bi bi-x fs-1 text-dark'></i>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body'>
            <div className='mb-5'>
              <label className='form-label required form-label'>Name</label>
              <input
                type='text'
                id='name'
                className={`text-uppercase form-control ${errors?.tags?.message ? 'is-invalid' : ''}`}
                {...register('tags')}
              />
              <div className='form-error invalid-feedback'>
                {errors?.tags?.message && <>{errors?.tags?.message}</>}
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={cancelCreate}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              {!isOperationLoading && <span className='indicator-label'>Update</span>}
              {isOperationLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Updating...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default UpdateTagModal
