import {useForm, Controller} from 'react-hook-form'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {yupResolver} from '@hookform/resolvers/yup'
import {assignProductTagsValidationSchema} from '../../validations'
import InputText from '../../../../components/InputText/InputText'
import {useContext, useEffect, useState} from 'react'
import {ProductsTagsContext, ReplenishmentContext} from '../../context'
import {TagPicker} from 'rsuite'
import useGetProductTags from '../../hooks/useGetProductTags'
import useProductTagsOperations from '../../hooks/useProductTagsOperations'

const ProductTagsCreateModal = ({
  showModal,
  setShowModal,
  isEdit = false,
  productData,
  getDataFromAPI = false,
  isAssignToMultiProducts = false,
  isFromAction = false,
}: any) => {
  const {tags} = useGetProductTags()
  const {
    validateChildSku,
    assignProductTags,
    assignMultiProductTags,
    updateProductTags,
    isValidatingSku,
    isLoading,
    getProductTags,
    fetchingProductTags,
  } = useProductTagsOperations()
  const {isLoading: isDataLoading} = useContext(ProductsTagsContext)
  const [isSkuValid, setIsSkuValid] = useState(false)
  const {isFetchingReplenishmentData, handleResetClick} = useContext(ReplenishmentContext)

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    watch,
    clearErrors,
    control,
  } = useForm<any>({
    resolver: yupResolver(assignProductTagsValidationSchema(isAssignToMultiProducts, isEdit)),
    defaultValues: {
      sku: '',
      tags: [],
    },
    shouldFocusError: false,
  })

  useEffect(() => {
    if (isEdit && productData && !isFromAction) {
      if (getDataFromAPI) {
        const fetchProductTags = async () => {
          const {data} = await getProductTags(productData.tableSku)
          reset({
            sku: productData.tableSku,
            tags: data?.map((tag: any) => tag?.tag),
          })
        }
        fetchProductTags()
      } else {
        reset({
          sku: productData.tableSku,
          tags: productData?.tags?.map((tags: any) => tags),
        })
      }
    } else if (isFromAction) {
      if (getDataFromAPI) {
        const fetchProductTags = async () => {
          const {data} = await getProductTags(productData.sku)
          const tags = data?.map((tag: any) => tag?.tags).flat()
          reset({
            sku: productData.sku,
            tags: tags,
          })
        }
        fetchProductTags()
      } else {
        reset({
          sku: productData.sku,
          tags: productData?.tags?.map((tags: any) => tags),
        })
      }
    }
    if (watch('sku') === '') {
      setIsSkuValid(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, productData, getDataFromAPI])

  const handleModalClose = () => {
    reset()
    clearErrors()
    setIsSkuValid(false)
    setShowModal(false)
  }

  const handleModalAction = async (data: any) => {
    if (isEdit) {
      updateProductTags({
        [data.sku]: data.tags,
      })
      handleResetClick()
    } else {
      if (isAssignToMultiProducts) {
        const payload = productData.reduce((acc: any, sku: string) => {
          acc[sku] = data.tags
          return acc
        }, {})
        assignMultiProductTags(payload)
      } else {
        if (!isSkuValid) {
          const {status} = await validateChildSku(data?.sku?.trim(), setError)
          if (status !== 200) return
        }
        setIsSkuValid(true)
        assignProductTags([{...data, sku: data?.sku.trim()}])
      }
    }
    handleResetClick()
  }

  const handleFocusTagPicker = async () => {
    const skuValue = watch('sku')
    if (!isEdit && skuValue && !isSkuValid && !errors?.sku) {
      clearErrors('sku')
      const {status} = await validateChildSku(skuValue.trim(), setError)
      if (status === 200) {
        setIsSkuValid(true)
        clearErrors('sku')
      } else {
        setIsSkuValid(false)
      }
    }
  }

  return (
    <ConfirmationModal
      show={showModal}
      onClose={handleModalClose}
      onAction={handleSubmit(handleModalAction)}
      title={isEdit ? 'Update Tags' : 'Assign Tags'}
      actionName={isEdit ? 'Save' : 'Assign'}
      actionBtnClass='btn-primary'
      isOperationLoading={isLoading}
      isDataLoading={isDataLoading || isFetchingReplenishmentData}
      isDisabled={isLoading || Object.keys(errors).length > 0}
      body={
        <form>
          {!isAssignToMultiProducts && (
            <div className='position-relative'>
              <InputText
                id='SKU'
                name='sku'
                label='SKU'
                register={register('sku')}
                error={errors?.sku}
                className='mb-3'
                disabled={isEdit}
                onChange={() => {
                  clearErrors('sku')
                  setIsSkuValid(false)
                }}
              />
              {isValidatingSku ? (
                <div className='position-absolute top-50 end-0 translate-middle-y me-3 mt-4'>
                  <span className='spinner-border spinner-border-md align-middle ms-2 text-success w-20px h-20px'></span>
                </div>
              ) : isSkuValid ? (
                <div className='position-absolute top-50 end-0 translate-middle-y me-3 mt-4'>
                  <i className='fa-solid fa-circle-check text-success fs-3'></i>
                </div>
              ) : null}
            </div>
          )}
          <label htmlFor='product-tags' className='form-label'>
            Tags
          </label>
          <Controller
            name='tags'
            control={control}
            render={({field}) => (
              <>
                <TagPicker
                  {...field}
                  id='product-tags'
                  data={tags}
                  loading={fetchingProductTags}
                  labelKey='tag'
                  valueKey='tag'
                  creatable
                  className={`w-100 text-uppercase ${
                    errors?.tags ? 'is-invalid border border-danger' : ''
                  }`}
                  menuStyle={{zIndex: 1100}}
                  menuClassName='text-uppercase'
                  onSelect={(value) => {
                    field.onChange(value)
                  }}
                  onCreate={(value, item) => {
                    const newTags = [...field.value, value]
                    field.onChange(newTags)
                  }}
                  onClean={() => {
                    field.onChange([])
                  }}
                  onFocus={handleFocusTagPicker}
                />
                {errors?.tags && (
                  <div className='invalid-feedback'>{errors.tags.message as string}</div>
                )}
              </>
            )}
          />
        </form>
      }
    />
  )
}

export default ProductTagsCreateModal
