export function TagsDropdown({toggleModal, onEdit}: any) {
  return (
    <div>
      <div className='px-2 py-2'>
        <span onClick={onEdit} className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>
      <div className='px-2 py-2'>
        <span onClick={toggleModal} className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Delete</label>
        </span>
      </div>
    </div>
  )
}
