import {useState} from 'react'
import useApi from '../../../services/useApi'

const useClassifiedAsOperations = () => {
  const Api = useApi()
  const [isLoading, setLoading] = useState(false)

  const createClassifiedAs = (payload: any) => {
    return Api.post(`/analytics/replenishment/classified-as`, payload, {
      successMessage: 'Classified as created successfully',
      setLoading: setLoading,
      refetch: 'classified-as-list',
    })
  }

  const updateClassifiedAs = (payload: any, id: number) => {
    return Api.patch(`/analytics/replenishment/classified-as/${id}`, payload, {
      successMessage: 'Classified as updated successfully',
      setLoading: setLoading,
      refetch: 'classified-as-list',
    })
  }

  const deleteClassifiedAs = (id: number) => {
    return Api.delete(
      `/analytics/replenishment/classified-as/${id}`,
      {},
      {
        successMessage: 'Classified as deleted successfully',
        setLoading: setLoading,
        refetch: 'classified-as-list',
      }
    )
  }

  return {
    createClassifiedAs,
    updateClassifiedAs,
    deleteClassifiedAs,
    isLoading,
  }
}

export default useClassifiedAsOperations
