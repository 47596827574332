import { PageTitle } from '../../_metronic/layout/core'
import { Pagination } from '../../utils/pagination'
import { ClassifiedAsPageContext } from './context'
import useClassifiedAsOperations from './hooks/useClassifiedAsOperations'
import useGetClassifiedAsList from './hooks/useGetClassifiedAsList'
import ClassifiedAsPageHeader from './sections/headers/ClassifiedAsPageHeader'
import ClassifiedAsTable from './sections/table/ClassifiedAsTable'
import { supplierManagementBreadCrumbs } from './utils'


const ClassifiedAs = () => {
  const {
    classifiedAsList,
    isLoading,
    filters,
    pagination,
    onSearch,
    onSortingChange,
    onPageChange,
  } = useGetClassifiedAsList()
  const {
    createClassifiedAs,
    updateClassifiedAs,
    deleteClassifiedAs,
    isLoading: isLoadingOperations,
  } = useClassifiedAsOperations()
  const contextValue = {
    classifiedAsList,
    isLoading,
    filters,
    onSearch,
    onSortingChange,

    createClassifiedAs,
    updateClassifiedAs,
    deleteClassifiedAs,
    isLoadingOperations,
  }

  return (
    <ClassifiedAsPageContext.Provider value={contextValue}>
      <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Classified As</PageTitle>
      <ClassifiedAsPageHeader />
      <ClassifiedAsTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </ClassifiedAsPageContext.Provider>
  )
}

export default ClassifiedAs
