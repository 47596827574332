import React from 'react'
import {OrderHistoryTable} from '../sections/table/OrderHistoryTable'
import {useGetOrderHistory} from '../hooks/useGetOrderHistory'
import {useParams} from 'react-router-dom'
import { OrderHistoryPageContext } from '../contexts'
import { Pagination } from '../../../utils/pagination'

const OrderHistory = () => {
  const params = useParams<any>()
  const productId = params?.id as string
  const {OrderHistoryData, ProductName, isLoading,orderFilters,onSortingChange,onPageChange,pagination} = useGetOrderHistory(productId)

  const contextValue = {
    orderHistoryData: OrderHistoryData,
    productName: ProductName,
    isLoading,
    orderFilters,
    onSortingChange
  }

  return (
    <OrderHistoryPageContext.Provider value={contextValue}>
      <div className='mb-10 fw-bold fs-5'>{ProductName}</div>
      <OrderHistoryTable />
      <Pagination pagination={pagination} onPageChange={onPageChange}/>
    </OrderHistoryPageContext.Provider>
  )
}

export default OrderHistory
