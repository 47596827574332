import {createContext} from 'react'

export const AnalyticsDetailsContext = createContext({
  dateRangeLable: '',
})

export const OrderHistoryPageContext = createContext({
  orderHistoryData: [],
  isLoading: false,
  orderFilters: {},
  onSortingChange: (key: string, value: string) => {},
})

export const PriceChangeLogPageContext = createContext({
  PriceChangeLogData: [],
  isLoading: false,
  priceChangeLogFilters: {},
  onSortingChange: (key: string, value: string) => {},
})