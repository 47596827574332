import {useContext, useEffect, useState} from 'react'
import useUpdatePurchasingOrderQuantity from '../../hooks/useUpdatePOQuantity'
import TableCellInput from '../../../../components/Table/TableCellInput'
import {formatInputPrice} from '../../../../utils/common'
import { isProductDiscontinued} from '../../utils'
import {ReplenishmentContext} from '../../context'
import OverlayModal from '../model/OverlayModal'
import { Select } from '../../../../components/Select/'

function ChildTableRow({
  i,
  index,
  monthly_column_keys,
  arrayLength,
  inputRef,
  inputRefArr,
  setItemToBeOrderd,
  isColumnSelected,
}: any) {
  const [updatedQty, setQty] = useState(i['item_order_qty'])
  const [currentQty, setCurrentQty] = useState()
  const [isVisible, setVisible] = useState(false)
  const [isChecked, setChecked] = useState(false)
  const {register, isFetchingReplenishmentData,onClassificationChange,classifications}: any = useContext(ReplenishmentContext)
  const {updatePurchasingOrderQuantity} = useUpdatePurchasingOrderQuantity()

  useEffect(() => {
    setQty(i['item_order_qty'])
  }, [i])

  useEffect(() => {
    setChecked(false)
  }, [isFetchingReplenishmentData])

  return (
    <tr
      className={`${
        i?.['suggested_order_qty'] > 0
          ? `bg-light-success`
          : i?.['suggested_order_qty'] < 0
          ? `bg-light-danger`
          : ``
      }`}
      key={i['sku']}
    >
      <td className='body-col-sticky'>
        <div></div>
      </td>
      <td className='body-col-sticky'>
        <input
          type='checkbox'
          id={i['sku']}
          value={i['sku']}
          checked={isChecked}
          className='form-check-input form-check-input-smallbg'
          {...register('skus')}
          onClick={(e) => {
            setChecked(!isChecked)
          }}
        />
      </td>
      <td className='body-col-sticky'>{i['sku']}</td>

      <td className='body-col-sticky'>{i['product_title']}</td>

      {isColumnSelected('classification') && (
        <td className='body-col-sticky'>
            <Select
              id={'classification_to_be'}
              className='w-200px'
              placeholder={'Select Classification'}
              options={classifications}
              isNullable={true}
              placeholderValue={''}
              value={i['classification'] || ''}
              onChange={(e) =>
                onClassificationChange({new_classification: e.target.value, sku: i?.sku})
              }
            />
        </td>
      )}

      {isColumnSelected('reserved_quantity') && (
        <td className='body-col-sticky text-center'>{i['reserved_quantity'] || '-'}</td>
      )}
      {isColumnSelected('quantity_available') && (
        <td className='body-col-sticky text-center'>{i['quantity_available'] || '0'}</td>
      )}
      {isColumnSelected('safetystock_quantity') && (
        <td className='body-col-sticky text-center'>{i['safetystock_quantity'] || '0'}</td>
      )}
      {isColumnSelected('quantity_incoming') && (
        <td className='body-col-sticky text-center'>{i['quantity_incoming'] || '0'}</td>
      )}
      {isColumnSelected('backorder_quantity') && (
        <td className='body-col-sticky text-center'>{i['backorder_quantity'] || '0'}</td>
      )}
      {isColumnSelected('total_sold_30') && (
        <td className='text-center'>{i['total_sold_30'] || '0'}</td>
      )}
      {isColumnSelected('incremental_quantity') && (
        <td className='text-center'>{i['incremental_quantity'] || '0'}</td>
      )}
      {isColumnSelected('case_qty') && (
        <td className='text-center'>{i['case_qty']}</td>
      )}
      {isColumnSelected('suggested_order_qty') && (
        <td>
          <div className='text-center'>{i['suggested_order_qty'] || '0'}</div>
        </td>
      )}
      {isColumnSelected('suggested_order_qty_45') && (
        <td>
          <div className='text-center'>{i['suggested_order_qty_45'] || '0'}</div>
        </td>
      )}
      {isColumnSelected('suggested_order_qty_60') && (
        <td>
          <div className='text-center'>{i['suggested_order_qty_60'] || '0'}</div>
        </td>
      )}
      {isColumnSelected('to_order_qty') && (
        <td
          className={`text-center ${
            i['to_order_qty'] >= 0 ? `bg-light-warning text-center` : 'bg-light-danger'
          }`}
        >
          {i['to_order_qty'] || '0'}
        </td>
      )}
      {isColumnSelected('item_order_qty') && (
        <td className={`${i['item_order_qty'] > 0 || updatedQty > 0 ? `bg-light-success` : ''}`}>
          <div
            className={`order-quantity-input cursor-pointer text-center`}
            onClick={() => setVisible(true)}
            ref={inputRef}
          >
            {isVisible ? (
              <TableCellInput
                inputType='number'
                onCellChange={(updatedQty: any) => {
                  if (updatedQty && currentQty !== updatedQty) {
                    updatePurchasingOrderQuantity(
                      i['sku'],
                      updatedQty,
                      i['sale_history_months'],
                      i['days_to_replenishment'],
                      i['suggested_order_qty'],
                      i['total_sold_30'],
                      i['weeks_on_hand'],
                      i['turn_rate'],
                      i['to_order_qty'],
                      i['quantity_available'],
                      i['quantity_incoming'],
                      i['incremental_quantity'],
                      i['product_title']
                    )
                    setQty(updatedQty)
                    setVisible(false)
                    setCurrentQty(updatedQty)
                    setItemToBeOrderd(
                      (prev: any) =>
                        Number(prev) -
                        (currentQty !== undefined
                          ? Number(currentQty)
                          : Number(i['item_order_qty'])) +
                        Number(updatedQty)
                    )
                  }
                }}
                min={0}
                exitEvent={(isFocusedOut) => (isFocusedOut ? setVisible(false) : null)}
                defaultValue={updatedQty || i['item_order_qty']}
                currentIndex={index}
                refArray={inputRefArr}
              />
            ) : updatedQty ? (
              <span
                className='border p-3 bg-body w-100px'
                style={{display: 'inline-block', borderRadius: '4px'}}
              >
                {updatedQty}
              </span>
            ) : (
              <span
                className='border p-3 bg-body w-100px'
                style={{display: 'inline-block', borderRadius: '4px'}}
              >
                {i['item_order_qty']}
              </span>
            )}
          </div>
        </td>
      )}

      {monthly_column_keys &&
        monthly_column_keys !== undefined &&
        monthly_column_keys.map((column: any) => (
          <td>
            <div className=''>{i[column]}</div>
          </td>
        ))}
      {isColumnSelected('total_quantity') && (
        <td className='text-center'>
          {parseInt(i['quantity_incoming']) + parseInt(i['quantity_available'])}
        </td>
      )}
      {isColumnSelected('days_out_of_stock') && (
        <td className='text-center'>
          {isProductDiscontinued(i['product_title']) ? '-' : i['days_out_of_stock'] || 0}
        </td>
      )}
      {isColumnSelected('cost') && <td>{formatInputPrice(i['cost'], true, false) || '-'}</td>}
      {isColumnSelected('total_cost') && (
        <th className={`min-w-150px w-150px text-center`}>
          {updatedQty !== undefined ? (i['cost'] * updatedQty).toFixed(2) : i['total_cost'] || '0'}
        </th>
      )}
      {isColumnSelected('primary_supplier') && <td>{i['primary_supplier'] || '-'}</td>}


      {isColumnSelected('weeks_on_hand') && (
        <td className='text-center'>{i['weeks_on_hand'].toFixed(2) || '-'}</td>
      )}
      {isColumnSelected('turn_rate') && (
        <td className='text-center'>{i['turn_rate'].toFixed(2) || '-'}</td>
      )}
      <td className='text-start'>
        <div className='d-flex justify-content-center flex-shrink-0'>
          <OverlayModal row={i} sku={i['sku']} />
        </div>
      </td>
    </tr>
  )
}

export default ChildTableRow
