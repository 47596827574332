import {useEffect, useState} from 'react'
import { PageTitle } from '../../_metronic/layout/core'
import { supplierManagementBreadCrumbs } from './utils'
import useGetAllMappedUsers from './hooks/useGetAllMappedUsers'
import useGetUserSupplierMapping from './hooks/useGetUserSupplierMapping'
import { useAppSelector } from '../../redux/useTypedSelector'
import { dataToSideBarStoreList } from '../../utils/common'
import { toSnakeCase } from '../../utils/string'
import usePermission from '../../hook/usePermission'
import { KTCard } from '../../_metronic/helpers'
import { UserSupplierMappingContext } from './context'
import UserSupplierModal from './sections/model/AddSupplierModal'
import UserSupplierMappingTable from './sections/table/UserSupplierMappingTable'

function SupplierManagement() {
  const {userList, refetch} = useGetAllMappedUsers()
  const {userSupplierMappingList, isFetchingData, onSaveSupplierMapping, onRemove, onEditMapping} =
    useGetUserSupplierMapping(refetch)
  const {user} = useAppSelector((state) => state.auth)
  const stores = dataToSideBarStoreList(user['stores'], user['default_store'])
  const activeStoreId = localStorage.getItem('x-store-id')
  const [activeStoreName, setActiveStoreName] = useState('')

  useEffect(() => {
    setActiveStoreName(stores.find((store: any) => store['storeId'] === activeStoreId)['name'])
    // eslint-disable-next-line
  }, [])

  const emailIds = userList
    ?.map((item: any) => item.email_id)
    ?.filter((email: any) => email !== null)
  const isUserExist =
    user?.username !== 'imran@midwestgoods.com'
      ? Boolean(emailIds?.includes(user?.username))
      : false

  useEffect(() => {
    if (activeStoreName) {
      localStorage.setItem(
        `is_supplier_${toSnakeCase(activeStoreName)}`,
        JSON.stringify(isUserExist)
      )
      localStorage.setItem(`is_supplier`, JSON.stringify(isUserExist))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserExist])

  const {hasPermission} = usePermission()

  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const onClose = () => {
    setModal(false)
  }

  const contextValue = {
    data: userSupplierMappingList,
    isLoading: isFetchingData,
    onSave: onSaveSupplierMapping,
    onRemove: onRemove,
    onEditMapping,
  }

  return (
    <KTCard>
      <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Supplier Management</PageTitle>
      <UserSupplierMappingContext.Provider value={contextValue}>
        {hasPermission('analytics_replenishment_supplier mapping', 'write') && (
          <div className='mb-10 text-end'>
            <button onClick={toggleModal} className='btn btn-primary'>
              <i className='bi bi-plus-lg'></i>
              Create Supplier Group
            </button>
          </div>
        )}
        <UserSupplierModal show={modal} onClose={onClose} />
        <UserSupplierMappingTable data={userSupplierMappingList} isLoading={isFetchingData} />
      </UserSupplierMappingContext.Provider>
    </KTCard>
  )
}

export default SupplierManagement
