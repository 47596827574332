import Api from '../../../services/Api'

export const getTableData = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}
export const getCsvData = (filters: any) => {
  const url = '/analytics/replenishment/po/reorder/csvdata'
  return Api.get(`${url}`, filters).then((d: any) => {
    return d?.data
  })
}

export const getChildAggregationData = ({filters}: any) => {
  const url = '/analytics/replenishment/aggregate/child/data'
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}

export async function createSupplierMapping(data: any) {
  const url = `/analytics/replenishment/supplier/mapping`
  const response = await Api.post(url, data, null, null)
  return response
}

export async function updateSupplierMapping(data: any) {
  const url = `/analytics/replenishment/sppliers`
  const response = await Api.put(url, data, null, null)
  return response
}

export async function removeMapping(userName: string) {
  const url = `/analytics/replenishment/supplier/mapping`
  const response = await Api.delete(url, {user_name: userName}, {}, null)
  return response
}

export const getMappedUserList = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d
  })
}

export const getSingleUserMappingDetails = (userName: string) => {
  const url = `/analytics/replenishment/sppliers?user=${userName}`
  return Api.get(`${url}`, {}).then((d: any) => {
    return d?.data?.data || {}
  })
}