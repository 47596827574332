import React from 'react'

const FlavorTableRow = ({row}: any) => {
  return (
    <tr>
      <td>{row.variant_options || row.variants_sku}</td>
      <td className='text-center'>
        {row.current_inventory % 1 !== 0 ? row.current_inventory.toFixed(2) : row.current_inventory}
      </td>
      <td className='text-center'>{row.total_purchased_qty}</td>
    </tr>
  )
}

export default FlavorTableRow
