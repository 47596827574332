import {useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {ReplenishmentDataDropdown} from '../dropdown/ReplenishmentDataDropdown'
import ProductTagsCreateModal from './ProductTagsCreateModal'

const OverlayModal = ({sku}: any) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={<ReplenishmentDataDropdown setShowModal={setShowModal} />}
      />
      {showModal ? (
        <ProductTagsCreateModal
          showModal={showModal}
          setShowModal={setShowModal}
          isEdit={true}
          getDataFromAPI={true}
          productData={{
            sku: sku,
          }}
          isFromAction={true}
        />
      ) : null}
    </>
  )
}

export default OverlayModal
