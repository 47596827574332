import {updateRowData} from '../services'
import useToastify from '../../../hook/useToastify'

function useUpdateDashBoardRowData() {
  const {toastMessage} = useToastify()

  const updateDashBoardRowData = async (
    sku: string,
    action_taken?: string | null,
    classified_as?: string | null,
    terms_consignment?: string | null
  ) => {
    const reqBody = Object.entries({
      classified_as_id: classified_as,
      terms_consignment,
      action_taken,
    }).reduce((acc, [key, value]) => {
      if (key === 'classified_as_id' && value === '') {
        acc[key] = null
      } else if (value !== null && value !== undefined) {
        acc[key] = value
      }
      return acc
    }, {} as Record<string, any>)

    const res = await updateRowData(`analytics/replenishment/dashboard/save/${sku}`, reqBody)

    if (res.status === 200) {
    } else {
      toastMessage('error', res?.['data']?.['message'])
    }
  }

  return {updateDashBoardRowData}
}

export default useUpdateDashBoardRowData
