import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {
  createSupplierMapping,
  getTableData,
  removeMapping,
  updateSupplierMapping,
} from '../services'
import useFilters from '../../../hook/useFilters'
import {dataToUserSupplierMappingList} from '../utils'
import useToastify from '../../../hook/useToastify'

const initialFilters = {
  page: 1,
  limit: 10,
}

const useGetUserSupplierMapping = (refetchUsers: any): any => {
  const {filters} = useFilters(initialFilters)
  const {toastMessage} = useToastify()

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`get-user-supplier-listing`, () => {
    return getTableData(`/analytics/replenishment/supplier/mapping`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSaveSupplierMapping = async (data: any) => {
    const res = await createSupplierMapping(data)
    if (res.status === 200) {
      toastMessage('success', `Data saved successfully.`)
      refetch()
      refetchUsers()
      return true
    } else {
      toastMessage('error', res.data.message)
      return false
    }
  }

  const onRemove = async (userName: string) => {
    const res = await removeMapping(userName)
    if (res.status === 200) {
      toastMessage('success', `Data removed successfully.`)
      refetch()
      refetchUsers()
    }
  }

  const onEditMapping = async (data: any) => {
    const res = await updateSupplierMapping(data)
    if (res.status === 200) {
      toastMessage('success', `Data updated successfully.`)
      refetch()
      refetchUsers()
      return true
    } else {
      toastMessage('error', res.data.message)
      return false
    }
  }

  return {
    userSupplierMappingList: dataToUserSupplierMappingList(response?.['data']?.['data']),
    isFetchingData: isFetching,
    onSaveSupplierMapping,
    onRemove,
    onEditMapping,
  }
}

export default useGetUserSupplierMapping
