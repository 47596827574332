import TagsTableRow from './TagsTableRow'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import {TagsManagementContext} from '../../context'
import {useContext} from 'react'
import OverlayModalTags from '../model/OverlayModalTags'
import { parseTagsTableRowData } from '../../utils'

function TagsTable({data, isLoading}: any) {
  const {setCheckedRows, onSortingChange} = useContext(TagsManagementContext)
  const sortableColumns = [
    {key: 'tag', label: 'Title', headerStyle: 'w-100'},
    {
      key: 'count',
      label: 'Product Count',
      headerStyle: 'min-w-300px',
      style: 'justify-content-center',
    },
    {
      key: 'action',
      label: 'Action',
      render: (row: any) => <OverlayModalTags row={row} />,
    },
  ]

  return (
    <DynamicTable
      id='tags-management-table'
      data={data}
      sortableColumns={sortableColumns}
      TableRow={TagsTableRow}
      onSortingChange={onSortingChange}
      loading={isLoading}
      permissionPath='analytics_replenishment_product tags'
      checkedRowId='id'
      setCheckedRows={setCheckedRows}
      rowParsingFunction={parseTagsTableRowData}
    />
  )
}

export default TagsTable
