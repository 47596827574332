import {useState, useEffect} from 'react'
import {getChildAggregationData} from '../services'

interface ChildAggregatedDataProps {
  sku: string
  hide_discontinued: boolean
  sale_history_months: number
  days_to_replenishment: number
  additionalFilters?: any
}



const useGetChildAggregateChildData = ({sku, hide_discontinued, sale_history_months, days_to_replenishment}: ChildAggregatedDataProps): any => {
  const filters = {
    page: 1,
    limit: 100,
    brand: '',
    classification: '',
    primary_supplier: '',
    date_range: '',
    sort_by: 'title/1',
    searchKey: '',
    searchValue: '',
    sale_history_months: 6,
    days_to_replenishment: 30,
    days_to_replenishment_45: 45,
    days_to_replenishment_60: 60,
    hide_discontinued: true,
  }

  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [meta, setMeta] = useState({})
  const [message, setMessage] = useState('')

  useEffect(() => {
    const fetchData = async (filters: any) => {
      try {
        if (sku) {
          setLoading(true)

          filters['sku'] = sku
          filters['hide_discontinued'] = hide_discontinued
          filters['sale_history_months'] = sale_history_months
          filters['days_to_replenishment'] = days_to_replenishment

          const apiResponse = await getChildAggregationData({filters})

          if (apiResponse.status === 200) {
            setData(apiResponse?.['data']?.['data'] || [])
            setMeta(apiResponse?.['data']?.['meta'] || {})
          }

          if (apiResponse.status === 204) {
            setMessage('Child skus are not available.')
          }

          setLoading(false)
        }
        // ...
      } catch (error) {
        console.error(error)
        // ...
      }
    }

    fetchData(filters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku,hide_discontinued])

  return {
    filters,
    message,
    childAggregation: data,
    isLoading: isLoading,
    monthly_column: meta,
  }
}

export default useGetChildAggregateChildData
