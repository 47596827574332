import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {BackOrderActionsDropdown} from '../dropdown/BackOrderActionsDropdown'
import BackOrderCreateModal from './BackOrderCreateModal'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {BackOrderContext} from '../../context'
import useBackOrderOperations from '../../hooks/useBackOrderOperations'

const BackOrderActionModal = ({row}: any) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {deleteBackOrderSKU, isDeleting} = useBackOrderOperations()
  const {isLoading} = useContext(BackOrderContext)

  const handleDelete = (productId: number) => {
    deleteBackOrderSKU({ids: [productId]})
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <BackOrderActionsDropdown
            setShowModal={setShowModal}
            setShowDeleteModal={setShowDeleteModal}
          />
        }
        rootClose={true}
      />

      <BackOrderCreateModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={true}
        productData={row}
      />

      <ConfirmationModal
        uniqueID={row?.id}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onAction={handleDelete}
        isOperationLoading={isDeleting}
        isDataLoading={isLoading}
      />
    </>
  )
}

export default BackOrderActionModal
