import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

export const useGetLiquidationReport = () => {
  const Api = useApi()

  const initialFilters = {
    page: true,
    limit: 100,
    sort_by: 'name/1',
    search: '',
  }

  const {filters, onPageChange, onSortingChange, onSearch} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data: response, isFetching: isLoading} = Api.useGetQuery('/products/liquidation/report', {
    queryId: 'liquidation-products-listing',
    filters: filters,
  })

  return {
    LiquidationProducts: response?.data || [],
    pagination: response?.meta?.pagination || {},
    isLoading: isLoading,
    filters: filters,
    onSearch,
    onPageChange: onPageChange,
    onSortingChange: onSortingChange,
  }
}
