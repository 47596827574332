import {useState, useRef, useEffect} from 'react'
import useToastify from '../../hook/useToastify'

interface TableCellInputProps {
  inputType: 'string' | 'number'
  onCellChange: (newValue: any) => void
  exitEvent: (isFocusedOut: boolean) => void
  min?: number
  defaultValue: number | string
  currentIndex?: number
  refArray?: any
  className?: string
}

const TableCellInput = ({
  inputType,
  onCellChange,
  exitEvent,
  min,
  defaultValue,
  currentIndex = 0,
  refArray,
  className = '',
}: TableCellInputProps) => {
  const {toastMessage} = useToastify()

  const [editedValue, setEditedValue] = useState(defaultValue === 0 ? '' : defaultValue)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const startEdit = () => {
    setEditedValue('')
  }

  const saveEdit = () => {
    if (inputRef?.current?.value) {
      const newValue = inputRef.current.value

      if (+newValue < 0) {
        toastMessage('error', 'Please enter a valid quantity.')
        setEditedValue(defaultValue)
        exitEvent(true)
        return false
      }

      if (defaultValue !== +newValue) {
        onCellChange(newValue)
      }

      exitEvent(true)
    } else {
      exitEvent(true)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      // Pass 'false' to indicate that 'Esc' key was pressed without focusing out.
      exitEvent(true)
    } else if (event.key === 'Enter') {
      saveEdit()
      let nextInput = currentIndex + 1
      if (nextInput <= refArray.current.length - 1 && refArray.current[nextInput].current) {
        refArray.current[nextInput].current.click()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <input
      className={`form-input onfocus-edit-input bg-white ${className}`}
      type={inputType}
      ref={inputRef}
      value={editedValue}
      onClick={startEdit}
      onChange={(e) => setEditedValue(e.target.value)}
      onBlur={saveEdit}
      onMouseDown={(e) => e.preventDefault()}
      autoFocus
      min={min}
    />
  )
}

export default TableCellInput
