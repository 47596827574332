import Date from '../../../../components/Date/Date'
import Initials from '../../../../components/Initials'
import {formatPrice} from '../../../../utils/common'

const PriceChangeLogTableRow = ({row}: any) => {
  return (
    <tr className='hover:bg-gray-50 transition-colors'>
      <td className=''>
        <Date date={row.updated_at} />
      </td>
      <td>
        <div className='d-flex'>
          <div className='symbol symbol-circle symbol-35px me-3'>
            <Initials text={row.updated_by_name[0]} />
          </div>
          <div className='d-flex flex-column'>
            <div className='align-items-center text-dark fw-semibold'>{row.updated_by_name}</div>
            <div className='align-items-center text-muted'>{row.updated_by}</div>
          </div>
        </div>
      </td>
      <td className='text-end'>{row?.old_price ? formatPrice(row?.old_price, false) : '-'}</td>
      <td className='text-end'>{row?.new_price ? formatPrice(row?.new_price, false) : '-'}</td>
    </tr>
  )
}

export default PriceChangeLogTableRow
