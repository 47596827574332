import {useContext} from 'react'
import {LiquidationReportPageContext} from '../../context'

const ProductReportActionModal = ({id}: any) => {
  const {removeProduct} = useContext(LiquidationReportPageContext)

  const handleEditClick = () => {
    document.body.click()
    removeProduct({is_liquidated: false}, id)
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={handleEditClick}
      >
        Remove from Liquidation
      </span>
    </div>
  )
}

export default ProductReportActionModal
