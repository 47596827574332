import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useContext} from 'react'
import Loading from '../../../loading'
import {ProductsListPageContext} from '../../context'
import FlavorTable from '../tables/flavorDetailsTable/FlavorTable'
import useGetFlavorsListing from '../../hooks/useGetFlavorsListing'
import {useParams} from 'react-router-dom'

const FlavorsDetailsModal = ({show, onClose, customerId, bopId}: any) => {
  const params = useParams()
  const bop_id = bopId ? bopId : (params?.['bop_id'] as string)
  const {flavorsData, isLoading} = useGetFlavorsListing(bop_id, customerId)
  const {setProductId, isLoadingPoDetails} = useContext(ProductsListPageContext)
  setProductId(customerId)

  if (!show) {
    setProductId('')
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_create_navigation'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l mw-900px'
      aria-hidden='true'
      onHide={onClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Flavors</h4>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body table-loader-wrapper overflow-scroll pt-0 mh-600px'>
          {isLoadingPoDetails ? (
            <Loading />
          ) : (
            <p>
              <FlavorTable data={flavorsData} loading={isLoading} />
            </p>
          )}
        </div>

        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-outline btn-active-light-primary'
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default FlavorsDetailsModal
