/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState, useRef} from 'react'
import useUpdateDashBoardRowData from '../../hooks/useUpdateDashBoardRowData'
import {parseDashboardTableRowData} from '../../utils'
import {ReplenishmentDashboardContext} from '../../context'
import usePermission from '../../../../hook/usePermission'
import Select from '../../../../components/Select/Select'

function ReplenishmentDashboardTableRow({row: rowData, monthly_column_keys, register}: any) {
  const row: any = parseDashboardTableRowData(rowData)
  const [actionTaken, setActionTaken] = useState(row['action_taken'])
  const [isVisible, setVisible] = useState(false)
  const {updateDashBoardRowData} = useUpdateDashBoardRowData()
  const {isColumnSelected, meta_price_lists, classifiedAsList}: any = useContext(
    ReplenishmentDashboardContext
  )
  const [classifiedAsValue, setClassifiedAsValue] = useState(row['classified_as'] || '')
  const [termsConsignmentValue, setTermsConsignmentValue] = useState(row['terms_consignment'] || '')
  const {hasPermission} = usePermission()
  const [isChecked, setChecked] = useState(false)

  const isPermission = hasPermission('analytics_replenishment_dashboard', 'write')

  const metaArray = Object.entries(meta_price_lists).map(([key, value]: any, index: number) => ({
    key,
    value: rowData?.price_lists?.[index]?.[`price_${index + 1}`],
    active: value.active,
  }))

  useEffect(() => {
    // Synchronize the internal state with row data when rowData changes
    setClassifiedAsValue(row['classified_as'] || '')
    setTermsConsignmentValue(row['terms_consignment'] || '')
    setActionTaken(row['action_taken'])
  }, [rowData])

  const lastCallTimeRef = useRef<number>(0)

  const handleClassifiedAsChange = (e: any) => {
    const now = Date.now()
    if (now - lastCallTimeRef.current < 100) {
      return
    }
    lastCallTimeRef.current = now
    const selectedValue = e.target.value
    setClassifiedAsValue(selectedValue)
    updateDashBoardRowData(row['parent_sku'], null, selectedValue)
  }

  // Handler for changing the terms_consignment dropdown value
  const handleTermsConsignmentChange = (e: any) => {
    const selectedValue = e.target.value
    setTermsConsignmentValue(selectedValue)
    updateDashBoardRowData(row['parent_sku'], null, null, selectedValue)
  }

  return (
    <>
      <tr>
        <td className='body-col-sticky'>
          <input
            type='checkbox'
            id={row['parent_sku']}
            value={row['parent_sku']}
            checked={isChecked}
            className='form-check-input form-check-input-smallbg'
            {...register('skus')}
            onClick={(e) => {
              setChecked(!isChecked)
            }}
          />
        </td>

        <td className='body-col-sticky'>{row['parent_sku']}</td>

        <td className='body-col-sticky'>{row['product_title']}</td>

        {isColumnSelected('created_at') && <td>{row['created_at']}</td>}

        {isColumnSelected('year') && <td>{row['year']}</td>}

        {isColumnSelected('primary_supplier') && <td>{row['primary_supplier']}</td>}

        {isColumnSelected('purchaser_name') && <td>{row['purchaser_name']}</td>}

        {isColumnSelected('classified_as') && (
          <td>
            <Select
              id='classified_as'
              options={classifiedAsList}
              labelKey='name'
              valueKey='id'
              value={classifiedAsValue}
              valueType='number'
              placeholder='Select Option'
              isNullable={true}
              onChange={handleClassifiedAsChange}
              disabled={!isPermission}
            />
          </td>
        )}

        {isColumnSelected('classification') && <td>{row['classification']}</td>}

        {isColumnSelected('cost') && <td className='text-center'>{row['cost']}</td>}

        {isColumnSelected('quantity_on_hold') && (
          <td className='text-center'>{row['quantity_on_hold']}</td>
        )}

        {isColumnSelected('quantity_pending') && (
          <td className='text-center'>{row['quantity_pending']}</td>
        )}

        {isColumnSelected('quantity_incoming') && (
          <td>
            <div className='text-center'>{row['quantity_incoming']}</div>
          </td>
        )}

        {isColumnSelected('quantity_available') && (
          <td>
            <div className='text-center'>{row['quantity_available']}</div>
          </td>
        )}

        {isColumnSelected('total_quantity') && (
          <td className='text-center'>{row['total_quantity']}</td>
        )}

        {monthly_column_keys &&
          monthly_column_keys.map((column: any) => <td key={column}>{rowData[column]}</td>)}

        {isColumnSelected('total_sold') && <td className='text-center'>{row['total_sold']}</td>}

        {isColumnSelected('wholesale_price') && (
          <th className='min-w-125px w-125px text-center bg-light-warning'>
            {row['wholesale_price'] ? `$${row['wholesale_price']}` : row['wholesale_price']}
          </th>
        )}

        {metaArray?.map((item: any, index: number) => {
          const metaItem = metaArray.find((meta) => meta.key === `price_${index + 1}`)
          const isInactive = metaItem && !metaItem.active
          return isColumnSelected(metaItem?.key) ? (
            <td key={index} className={`text-center ${isInactive ? 'bg-light' : ''}`}>
              {item?.value ? '$' + item?.value : '-'}
            </td>
          ) : null
        })}

        {isColumnSelected('ratio') && <td>{row['ratio']}</td>}

        {isColumnSelected('total_cost') && <td>{row['total_cost']}</td>}

        {isColumnSelected('terms_consignment') && (
          <td className='min-w-150px w-150px'>
            <select
              className='form-select'
              value={termsConsignmentValue}
              onChange={handleTermsConsignmentChange}
              disabled={!isPermission}
            >
              <option value=''>Select Option</option>
              <option value='consignment'>Consignment</option>
              <option value='paid'>Paid</option>
              <option value='partially paid'>Partially Paid</option>
            </select>
          </td>
        )}

        {isColumnSelected('action_taken') && (
          <td
            className={`${
              row['action_taken'] !== '' || actionTaken !== '' ? `bg-light-success` : ''
            }`}
          >
            <div
              className='order-quantity-input cursor-pointer h-35px pe-3 d-flex justify-content-between'
              onClick={() => setVisible(true)}
            >
              {isVisible ? (
                <input
                  className='form-input onfocus-edit-input bg-white h-35px border border-gray-300 w-100 mw-350px '
                  type='text'
                  value={actionTaken}
                  onChange={(e) => setActionTaken(e.target.value)}
                  onBlur={() => {
                    updateDashBoardRowData(row['parent_sku'], actionTaken)
                    setVisible(false)
                  }}
                  autoFocus
                  maxLength={128}
                  disabled={!isPermission}
                />
              ) : (
                (actionTaken === '' || actionTaken !== '') && (
                  <span className='border border-gray-300 p-3 bg-body h-35px overflow-hidden lh-2 w-100 mw-350px actionTaken-input'>
                    {actionTaken}
                  </span>
                )
              )}
              {/* <OverlayComponent
                btnIcon={<i className='fa-solid fa-circle-info'></i>}
                className='btn-active-light-primary rounded-circle w-25px h-25px'
                trigger={['hover', 'focus']}
                children={<CustomerInfoOverlay customerDetails={{}} />}
              /> */}
            </div>
          </td>
        )}
      </tr>
    </>
  )
}

export default ReplenishmentDashboardTableRow
