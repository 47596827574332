import {useContext} from 'react'
import {SafetyStockContext} from '../../context'
import {DynamicTable} from '../../../../components/DynamicTable'
import SafetyStockActionModal from '../model/SafetyStockActionModal'
import SafetyStockTableRow from './SafetyStockTableRow'

const SafetyStockTable = () => {
  const {safetyStockListing, isLoading, filters, onSortingChange, checkedRows, setCheckedRows} =
    useContext(SafetyStockContext)

  const columns = [
    {key: 'product_name', headerStyle: 'w-100', label: 'Product Name', isSorted: true},
    {key: 'child_sku', headerStyle: 'min-w-150px', label: 'SKU', isSorted: true},
    {
      key: 'qty',
      label: 'Safety Stock',
      headerStyle: 'min-w-150px',
      style: 'justify-content-center',
      isSorted: true,
    },
    {
      headerStyle: 'min-w-175px',
      label: 'Modified By',
      isSorted: false,
    },
    {
      key: 'action',
      label: 'Action',
      render: (row: any) => <SafetyStockActionModal row={row} />,
    },
  ]

  return (
    <DynamicTable
      id='safety-stock-table'
      data={safetyStockListing}
      filters={filters}
      sortableColumns={columns}
      onSortingChange={onSortingChange}
      loading={isLoading}
      TableRow={SafetyStockTableRow}
      checkedRowId={checkedRows}
      setCheckedRows={setCheckedRows}
      permissionPath={'analytics_replenishment_safety stock'}
    />
  )
}

export default SafetyStockTable
