import { isEmpty } from '../../../utils/common'
import {capitalizeFirstLetter} from '../../../utils/string'

export const POsHistoryTableColumns = [
  {key: 'po_id', label: 'PO ID', headerStyle: 'min-w-100px', isSorted: false},
  {key: 'company', label: 'COMPANY NAME', headerStyle: 'min-w-200px', isSorted: true},
  {key: 'state', label: 'STATE', headerStyle: 'min-w-120px', isSorted: true},
  {key: 'customer_name', label: 'CUSTOMER', headerStyle: 'min-w-225px', isSorted: false},
  {
    key: 'customer_rep_name',
    label: 'CUSTOMER REPRESENTATIVE',
    headerStyle: 'min-w-250px',
    isSorted: false,
  },
  {
    key: 'total_req_qty',
    label: 'TOTAL REQ. QTY.',
    headerStyle: 'min-w-180px text-center',
    isSorted: true,
  },
  {
    key: 'total_pending_qty',
    label: 'Total Pending QTY',
    headerStyle: 'min-w-200px',
    isSorted: true,
  },
  {
    key: 'total_price',
    label: 'TOTAL PRICE ($)',
    headerStyle: 'min-w-150px text-center',
    isSorted: true,
  },
  {
    key: 'reorder',
    label: 'FIRST OR REORDER',
    headerStyle: 'min-w-175px',
    isSorted: true,
  },
  {key: 'status', label: 'STATUS', headerStyle: 'min-w-120px', isSorted: false},
  {key: 'created_at', label: 'CREATED AT', headerStyle: 'min-w-150px', isSorted: true},
]

export const customerOptions = (poCustomers: any) => {
  if(isEmpty(poCustomers)) return []
  return (
    Object?.entries(poCustomers)
      ?.map(([key, value]: any) => ({
        label: capitalizeFirstLetter(value),
        value: key,
      }))
      ?.sort((a, b) => a?.label?.localeCompare(b?.label)) || []
  )
}

export const customerRepsOptions = (poCustomerReps: any) => {
  if(isEmpty(poCustomerReps)) return []
  return (
    Object?.entries(poCustomerReps)
      ?.map(([key, value]: any) => ({
        label: capitalizeFirstLetter(value),
        value: key,
      }))
      ?.sort((a, b) => a?.label?.localeCompare(b?.label)) || []
  )
}

export const POsStatusOptions = [
  {label: 'Completed', value: 'completed'},
  {label: 'Deleted', value: 'deleted'},
  {label: 'Draft', value: 'draft'},
  {label: 'Partially Fulfilled', value: 'partially fulfilled'},
  {label: 'Pending', value: 'pending'},
]


export const columns = [
  {
    key: 'name',
    label: 'Customer Name',
    headerStyle: 'min-w-250px',
    isSorted: true,
  },
  {
    key: 'company_name',
    label: 'Company Name',
    headerStyle: 'min-w-200px',
    isSorted: true,
  },
  {
    key: 'state',
    label: 'STATE',
    headerStyle: 'min-w-120px',
    isSorted: true,
  },
  {
    key: 'rep_name',
    label: 'Customer Rep',
    headerStyle: 'min-w-150px',
    isSorted: true,
  },
  {
    key: 'tag_labels',
    label: 'Customer Tag',
    headerStyle: 'min-w-150px',
    isSorted: true,
  },
  // {
  //   key: 'order_count',
  //   label: 'BO Orders',
  //   headerStyle: 'min-w-120px',
  //   style: 'justify-content-center',
  //   isSorted: true,
  // },
  {
    key: 'open_orders',
    label: 'Open Bulk Orders',
    headerStyle: 'min-w-200px',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'purchased_flavours',
    label: 'Flavors',
    headerStyle: 'min-w-100px',
    style: 'justify-content-center',
    isSorted: true,
  },
  // {
  //   key: 'order_total',
  //   label: 'BO Revenue',
  //   headerStyle: 'min-w-150px',
  //   isSorted: true,
  // },
  {
    key: 'product_order_count',
    label: 'This Product Orders',
    headerStyle: 'min-w-225px ',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'product_order_total',
    label: 'This Product revenue - 2 Years',
    headerStyle: 'min-w-175px ',
    isSorted: true,
  },
  {
    key: 'lifetime_order_total',
    label: 'Total Revenue - 2 Year',
    headerStyle: 'min-w-225px bg-light',
    isSorted: true,
  },
  {
    key: 'product_last_order_date',
    label: 'Last BC Order Date',
    headerStyle: 'min-w-200px',
    isSorted: true,
  },
]

export const flavorsColumns = [
  {
    key: 'variant_options',
    label: 'Flavors',
    headerStyle: 'min-w-350px',
    isSorted: true,
  },
  {
    key: 'current_inventory',
    label: 'current stock case count',
    headerStyle: 'min-w-275px',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'total_purchased_qty',
    label: 'Purchased QTY.',
    headerStyle: 'min-w-175px',
    style: 'justify-content-center',
    isSorted: true,
  },
]