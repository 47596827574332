import {createContext} from 'react'

export const TagsManagementContext = createContext({
  tags: [],
  isLoading: false,
  onAddTag: (data: any, setError: Function) => {},
  refetch: () => {},
  onDeleteTag: (data: any) => {},
  onUpdateTag: (tagId: string, newTag: string) => {},
  isOperationLoading: false,
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
})

export const ProductsTagsContext = createContext({
  productsTagsList: [],
  isLoading: false,
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
})
