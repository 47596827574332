import {useContext} from 'react'
import {UserSupplierMappingContext} from '../../context'
import {parseUserSupplierTableRowData} from '../../utils'
import usePermission from '../../../../hook/usePermission'
import OverlayModalSupplier from '../model/OverlayModalSupplier'

const UserSupplierTableRow = ({row: rowData}: any) => {
  const row = parseUserSupplierTableRowData(rowData)
  const {onRemove} = useContext(UserSupplierMappingContext)
  const {hasPermission} = usePermission()

  return (
    <tr>
      <td className='pt-4'>
        <div className='fw-semibold'>{row['name']}</div>
      </td>
      <td>
        <div>
          {row['suppliers'].map((i: any) => (
            <span className='badge badge-light m-1 p-3 text-gray-800'>{i}</span>
          ))}
        </div>
      </td>
      {hasPermission('analytics_replenishment_supplier mapping', 'write') && (
        <td className='text-start'>
          <div className=''>
            <OverlayModalSupplier name={row['name']} onRemove={onRemove} />
          </div>
        </td>
      )}
    </tr>
  )
}

export default UserSupplierTableRow
