import {createContext} from 'react'

export const BackOrderContext = createContext({
  backOrderListing: {},
  isLoading: true,
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
  showModal: false,
  setShowModal: (showModal: boolean) => {},
  checkedRows: [],
  setCheckedRows: (checkedRows: any) => {},
})