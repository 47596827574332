import {useEffect} from 'react'
import Select from 'react-select'
import {simulateEvent} from '../utils/form'

interface ReactSelectProps {
  options: any
  value?: any
  label?: string
  placeholder?: string
  name?: string
  defaultValue?: any
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  isMulti?: boolean
  backspaceRemovesValue?: boolean
  isRequired?: boolean
  closeMenuOnSelect?: boolean
  onChange: (e: any) => void
}

function ReactSelect(props: ReactSelectProps) {
  const {
    options,
    value,
    label,
    placeholder,
    name,
    defaultValue,
    isDisabled,
    isLoading,
    isClearable = true,
    isSearchable = true,
    isMulti = false,
    backspaceRemovesValue = true,
    isRequired = false,
    closeMenuOnSelect = true,
    onChange,
  } = props

  const handleChange = (e: any) => {
    onChange && onChange(simulateEvent(name, e))
  }

  useEffect(() => {
    if (typeof value === 'string' && options?.length) {
      const optionVal = options?.find((o: any) => o.value === value)
      optionVal && onChange(isMulti ? [optionVal] : optionVal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options?.length])

  return (
    <>
      {label ? <div className={`${isRequired ? 'required' : ''} form-label`}>{label}</div> : null}
      <div className='select-2-custom-wrapper'>
        <Select
          {...props}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isSearchable={isSearchable}
          options={options}
          isMulti={isMulti}
          backspaceRemovesValue={backspaceRemovesValue}
          closeMenuOnSelect={closeMenuOnSelect}
          styles={{
            // ... (other style properties)
            option: (provided, state) => ({
              ...provided,
              width: '100%',
              // Change background color for selected items
              backgroundColor: state.isSelected ? '#F7F7F7' : 'transparent',
              borderRadius: '5px',
              color: '#000000',
            }),
            // ... (other style properties)
          }}
        />
      </div>
    </>
  )
}

export default ReactSelect
