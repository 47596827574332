import {useContext} from 'react'
import {ClassificationContext} from '../../context'
import {DynamicTable} from '../../../../components/DynamicTable'
import ClassificationTableRow from './ClassificationTableRow'
import ClassificationActionModal from '../model/ClassificationActionModal'
const ClassificationTable = () => {
  const {ClassificationData, isLoading, filters, onSortingChange, checkedRows, setCheckedRows} =
    useContext(ClassificationContext)

  const columns = [
    {
      key: 'sku',
      headerStyle: 'min-w-150px',
      label: 'SKU',
      isSorted: true,
      sortable: true,
    },
    {
      key: 'product_title',
      headerStyle: 'min-w-400px',
      label: 'Product Name',
      isSorted: true,
      sortable: true,
    },
    {
      key: 'old_classification',
      headerStyle: 'min-w-150px',
      label: 'Classification',
      isSorted: true,
      sortable: true,
    },
    {
      key: 'classification_to_be',
      label: 'Classification To Be',
      headerStyle: 'min-w-200px',
    },
    {
      key: 'created_by',
      headerStyle: 'min-w-150px',
      label: 'Requested By',
      isSorted: true,
      sortable: true,
    },
    {
      key: 'created_at',
      headerStyle: 'min-w-175px',
      label: 'Requested Date',
      isSorted: true,
      sortable: true,
    },
    {
      key: 'action',
      label: 'Action',
      render: (row: any) => <ClassificationActionModal row={row} />,
    },
  ]

  return (
    <DynamicTable
      id='classification-table'
      data={ClassificationData}
      filters={filters}
      sortableColumns={columns}
      onSortingChange={onSortingChange}
      loading={isLoading}
      TableRow={ClassificationTableRow}
      checkedRowId={checkedRows}
      setCheckedRows={setCheckedRows}
      permissionPath={'analytics_replenishment_dashboard'}
    />
  )
}

export default ClassificationTable
