import {KTCard} from '../../../_metronic/helpers'
import useGetProductTags from '../hooks/useGetProductTags'
import ReplenishmentDashboardTable from '../sections/table/ReplenishmentDashboardTable'
import ReplenishmentDashboardSearchComponent from '../sections/ReplenishmentDashboardSearchComponent'
import {ReplenishmentDashboardContext} from '../context'
import {useGetReplenishmentDashboard} from '../hooks/useGetReplenishmentDashboard'
import useGetSelectedDashboardTableColumns from '../hooks/useGetSelectedDashboardTableColumns'
import useGetAllMappedUsers from '../hooks/useGetAllMappedUsers'
import {useAppSelector} from '../../../redux/useTypedSelector'
import useGetClassifiedAsList from '../hooks/useGetClassifiedAsList'

function ReplenishmentDashboard() {
  const {mappedUsers, isFetchingData} = useGetAllMappedUsers()
  const {user} = useAppSelector((state) => state.auth)
  const isUserExist =
    user?.role_id === '66f4f1aacb3b3b19fd774f98'
      ? false
      : JSON.parse(localStorage.getItem('is_supplier') || 'false')

  const {
    replenishment,
    filters,
    isFetchingReplenishmentData,
    pagination,
    monthly_column,
    meta_price_lists,
    refetch,
    onFilter,
    onSearch,
    onPageChange,
    onClickSorting,
    onApplyFilters,
    onResetFilters,
    handleUserFitler,
    register,
    selectedSkus,
    handleResetClick,
    getProductTagsUsingSku,
    onMultyProductAddTags,
    onUpdateProductTags,
    onDownloadCsv,
    isFetchingCsvData,
  } = useGetReplenishmentDashboard()

  const {tags, isFetchingTagData, onAddTag} = useGetProductTags()
  const {classifiedAsList, isLoading} = useGetClassifiedAsList()
  const {allTableColumns, selectedColumns, setSelectedColumns, isColumnSelected} =
    useGetSelectedDashboardTableColumns()

  const contextValue = {
    replenishment: replenishment,
    dataFilters: filters,
    isFetchingReplenishmentData,
    pagination,
    monthly_column,
    meta_price_lists,
    onPageChange,
    onFilter,
    onSearch,
    refetch,
    onClickSorting,
    onApplyFilters,
    onResetFilters,
    handleUserFitler,
    register,
    selectedSkus,
    handleResetClick,
    getProductTagsUsingSku,
    tags: tags,
    isFetchingTagData,
    onAddTag,
    onMultyProductAddTags,
    onUpdateProductTags,
    allTableColumns,
    selectedColumns,
    setSelectedColumns,
    isColumnSelected,
    isUserExist,
    mappedUsers,
    isFetchingData,
    onDownloadCsv,
    isFetchingCsvData,

    classifiedAsList,
    isLoading,
  }

  return (
    <KTCard>
      <ReplenishmentDashboardContext.Provider value={contextValue}>
        <ReplenishmentDashboardSearchComponent />
        <ReplenishmentDashboardTable pagination={pagination} onPageChange={onPageChange} />
      </ReplenishmentDashboardContext.Provider>
    </KTCard>
  )
}

export default ReplenishmentDashboard
