import {GanttChart} from '../../../../components/GanttChart'
import {useContext, useEffect, useRef, useState} from 'react'
import {GanttChartPageContext, ProjectDetailsContext} from '../../context'
import {ganttChartConfig, getChartPayload, mapGanttData, reCalcParentProgress} from '../../utils'
import {isEmpty} from '../../../../utils/common'
import {debounce} from '../../../../utils/debounce'
import useModulesOperations from '../../hooks/boardSettings/useModulesOperations'

const Chart = () => {
  const {projectId} = useContext(ProjectDetailsContext)
  const {
    hasGanttChartPermission,
    ganttChartData,
    isLoadingGanttChartData,
    chartView,
    setChartState,
    updateGanttChart,
    sortGanttChartRows,
  } = useContext(GanttChartPageContext)
  const {updateModuleTableSorting} = useModulesOperations()
  const {tableColumns, features, zoomConfig} = ganttChartConfig(projectId, chartView)
  const [chartData, setChartData] = useState(ganttChartData)
  const chartAPIRef = useRef<any>(null)

  const onChange = (data: any) => {
    if (data?.task?.type === 'summary') return
    debouncedUpdate(data, 'chart-update')
  }

  const onSortChartRows = (data: any) => {
    debouncedUpdate({data, chartAPIRef}, 'row-sorting')
  }

  const debouncedUpdate = debounce((data: any, type: string) => {
    switch (type) {
      case 'chart-update':
        const updatePayload = getChartPayload(data, type)
        updateGanttChart(updatePayload, data?.id)
        break
      case 'row-sorting':
        if (data?.data?.parent === 0) {
          const sortPayload = getChartPayload(data, 'parent-row-sorting')
          updateModuleTableSorting(sortPayload)
        } else {
          const sortPayload = getChartPayload(data, 'child-row-sorting')
          sortGanttChartRows(sortPayload)
        }
        break
    }
  }, 500)

  const init = (api: any) => {
    setChartState(api?.getState())
    api.on('update-task', (ev: any) => {
      reCalcParentProgress(ev?.id, api)
    })
  }

  useEffect(() => {
    if (!isLoadingGanttChartData && !isEmpty(ganttChartData)) {
      setChartData(mapGanttData(ganttChartData?.data))
    } else if (isLoadingGanttChartData) {
      setChartData([])
    }
  }, [ganttChartData, isLoadingGanttChartData])

  return (
    <GanttChart
      data={chartData}
      tableColumns={tableColumns}
      isLoading={isLoadingGanttChartData}
      taskDrag={features.taskDrag}
      taskResize={features.taskResize}
      columnResize={features.columnResize}
      sort={features.sort}
      filter={features.filter}
      taskTemplate={features.taskTemplate}
      fullScreen={features.fullScreen}
      highlightWeekend={features.highlightWeekend}
      zoom={zoomConfig}
      init={init}
      onChange={onChange}
      onSortChartRows={onSortChartRows}
      chartApisRef={chartAPIRef}
      readOnly={!hasGanttChartPermission}
    />
  )
}
export default Chart
