import React, { useContext } from 'react';
import OrderHistoryTableRow from './OrderHistoryTableRow';
import {OrderHistoryPageContext} from '../../contexts'
import {DynamicTable} from '../../../../components/DynamicTable' // Assuming this is a reusable table component
import { sortableColumnsForOrderHistory } from '../../utils';
// import { dataToOrders } from '../../utils'; // A utility function to process order data
// import OverlayModal from '../modals/OverlayModal';

const OrderHistoryTable = () => {
  const {orderHistoryData,isLoading, orderFilters,onSortingChange} = useContext(OrderHistoryPageContext)

   return (
    <DynamicTable
      id='order-history-table'
      data={orderHistoryData}
      sortableColumns={sortableColumnsForOrderHistory}
      TableRow={OrderHistoryTableRow}
      loading={isLoading}
      filters={orderFilters}
      onSortingChange={onSortingChange}
      permissionPath={'orders'}
    />
  )
}

export { OrderHistoryTable };
