import {updatePOQty} from '../services'
import useToastify from '../../../hook/useToastify'
import {useGetReorderingData} from './useGetReorderingData'

function useUpdatePurchasingOrderQuantity() {
  const {toastMessage} = useToastify()
  const {reorderRefetch} = useGetReorderingData()

  const updatePurchasingOrderQuantity = async (
    sku: string,
    qty: string | number,
    sale_history_months: string | number,
    days_to_replenishment: string | number,
    suggested_order_qty: string | number,
    total_sold_30: string | number,
    weeks_on_hand: string | number,
    turn_rate: string | number,
    to_order_qty: string | number,
    quantity_available: string | number,
    quantity_incoming: string | number,
    incremental_quantity: string | number,
    title: string
  ) => {
    const reqbBody = {
      sku: sku,
      quantity: +qty,
      sale_history_months: +sale_history_months,
      days_to_replenishment: +days_to_replenishment,
      suggested_order_qty: suggested_order_qty,
      total_sold: total_sold_30,
      weeks_on_hand: weeks_on_hand,
      turn_rate: turn_rate,
      to_order_qty: to_order_qty,
      quantity_available: quantity_available,
      quantity_incoming: quantity_incoming,
      incremental_quantity: incremental_quantity,
    }

    const res = await updatePOQty(reqbBody)

    if (res.status === 200) {
      // toastMessage('success', `Reordering list updated successfully.`)
      reorderRefetch()
    } else {
      toastMessage('error', res?.['data']?.['message'])
    }
  }

  return {updatePurchasingOrderQuantity}
}

export default useUpdatePurchasingOrderQuantity
