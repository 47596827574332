import { useState } from 'react'
import {KTCard} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {TagsManagementContext} from '../context'
import TagsManagementHeader from '../sections/headers/TagsManagementHeader'
import TagsTable from '../sections/table/TagsTable'
import {supplierManagementBreadCrumbs} from '../utils'
import useGetProductTags from '../hooks/useGetProductTags'

function TagsMaster() {
  const {
    tags,
    isFetchingTagData,
    refetch,
    onAddTag,
    onDeleteTag,
    onUpdateTag,
    isOperationLoading,
    onSortingChange,
  } = useGetProductTags()

  const [checkedRows, setCheckedRows] = useState([])

  const contextValue = {
    tags: tags,
    isLoading: isFetchingTagData,
    onAddTag,
    refetch,
    onDeleteTag,
    onUpdateTag,
    isOperationLoading,
    checkedRows,
    setCheckedRows,
    onSortingChange,
  }

  return (
    <KTCard>
      <TagsManagementContext.Provider value={contextValue}>
        <PageTitle breadcrumbs={supplierManagementBreadCrumbs}>Tags Management</PageTitle>
        <TagsManagementHeader />
        <TagsTable data={tags} isLoading={isFetchingTagData} />
      </TagsManagementContext.Provider>
    </KTCard>
  )
}

export default TagsMaster
