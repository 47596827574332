import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import store from '../redux/store'
import useToastify from '../hook/useToastify'
import {useNavigate} from 'react-router-dom'
import {formatErrors} from '../utils/formatErrors'
import {useQuery, useQueryClient, UseQueryOptions} from 'react-query'
import {convertKeysToSnakeCase} from '../utils/common'

interface ApiOptions {
  filters?: Record<string, any>
  config?: AxiosRequestConfig
  onError?: (response: any) => void
  successMessage?: Function | string
  errorMessage?: Function | string
  onSuccess?: (response: any) => void
  isToast?: boolean
  disableLoading?: boolean
  setLoading?: (state: boolean) => void
  isCaching?: boolean
  setUseFormError?: Function
  isErrorPageRedirection?: boolean
  headers?: Record<string, string>
  isFile?: boolean
  queryId?: string | number | any[]
  refetch?: string | string[] | any[]
  navigate?: string
  fileKey?: string
  dirtyFields?: any
  onLoadingStart?: () => void
  onLoadingEnd?: () => void
  isDisableSneckCase?: boolean
}

const useApi = () => {
  const {toastMessage} = useToastify()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const apiInstance = axios.create({
    baseURL: process.env.REACT_APP__AD_API_URL,
  })

  apiInstance.interceptors.request.use((config: any) => {
    const {access_token, tenant_id} = store.getState()?.auth?.user || {}
    const storeId = localStorage.getItem('x-store-id')

    config.headers = {
      ...config.headers,
      'X-AUTH-TOKEN': access_token,
      HTTP_ORIGIN: process.env.REACT_APP_HTTP_ORIGIN,
      'X-STORE-ID': storeId,
      'X-TENANT-ID': tenant_id,
    }

    return config
  })

  const handleError = (error: any, options: any) => {
    const {
      isToast = true,
      onError,
      isErrorPageRedirection = true,
      errorMessage,
      setUseFormError,
      isDisableSneckCase,
    } = options
    const status = error?.response?.status
    const message = formatErrors(error, isDisableSneckCase) || 'Something went wrong'

    if (isErrorPageRedirection) {
      if (status === 404) {
        navigate('/error/404')
        return
      } else if (status === 403) {
        navigate('/error/403')
        return
      } else if ([500, 502, 503, 504].includes(status)) {
        navigate('/error/500')
        return
      }
    }

    setUseFormError?.(message?.key, {type: 'focus', message: message?.message}, {shouldFocus: true})

    let finalErrorMessage: string | undefined
    if (typeof errorMessage === 'function') {
      finalErrorMessage = errorMessage({error: message, status: status})
    } else if (typeof errorMessage === 'string') {
      finalErrorMessage = errorMessage
    } else if (typeof message === 'string' && isToast) {
      finalErrorMessage = message
    }

    if (finalErrorMessage && isToast) {
      toastMessage('error', finalErrorMessage)
    }

    if (onError) {
      const onErrorResult = onError({response: error, error: message})
      if (onErrorResult && typeof onErrorResult === 'object' && 'refetch' in onErrorResult) {
        queryClient.invalidateQueries({queryKey: [onErrorResult.refetch], exact: false})
      }
      if (onErrorResult && typeof onErrorResult === 'object' && 'error' in onErrorResult) {
        return onErrorResult.error
      }
      return onErrorResult !== undefined ? onErrorResult : message
    }

    return message
  }

  const handleSuccess = (
    response: AxiosResponse,
    onSuccess?: ApiOptions['onSuccess'],
    successMessage?: Function | string
  ) => {
    let finalSuccessMessage: string | undefined

    if (typeof successMessage === 'function') {
      finalSuccessMessage = successMessage({response: response?.data, status: response?.status})
    } else if (typeof successMessage === 'string') {
      finalSuccessMessage = successMessage
    }

    if (finalSuccessMessage) {
      toastMessage('success', finalSuccessMessage)
    }

    if (onSuccess) {
      const onSuccessResult = onSuccess(response)
      return onSuccessResult !== undefined ? onSuccessResult : response
    }
  }

  const createRequest = async (
    method: 'get' | 'delete' | 'post' | 'put' | 'patch',
    endpoint: string,
    data?: any,
    options?: ApiOptions
  ) => {
    options?.onLoadingStart?.()
    try {
      const {
        filters: params,
        config,
        setLoading,
        disableLoading = false,
        onSuccess,
        successMessage,
        headers,
        isFile = false,
        fileKey = 'file',
        isDisableSneckCase = false,
      } = options || {}

      if (setLoading && !disableLoading) setLoading(true)

      let mergedHeaders = {
        ...config?.headers,
        ...headers,
      }

      let requestData = data

      if (isFile) {
        mergedHeaders = {
          ...mergedHeaders,
          'Content-Type': 'multipart/form-data',
        }

        // Create FormData if isFile is true
        const formData = new FormData()
        if (data instanceof File) {
          formData.append(fileKey, data)
        } else if (typeof data === 'object') {
          Object.keys(data).forEach((key) => {
            if (data[key] instanceof File) {
              formData.append(fileKey, data[key])
            } else {
              formData.append(fileKey, JSON.stringify(data[key]))
            }
          })
        }
        requestData = formData
      }

      if (options?.dirtyFields && Object.keys(options?.dirtyFields).length > 0) {
        const onlyUpdatedData = Object.keys(options?.dirtyFields).reduce((acc: any, key) => {
          const isDirty =
            options.dirtyFields[key] === true ||
            typeof options.dirtyFields[key] === 'object' ||
            Array.isArray(options.dirtyFields[key])
          if (isDirty) {
            acc[key] = requestData[key]
          }
          return acc
        }, {})
        requestData = onlyUpdatedData
      }

      const response: AxiosResponse = await apiInstance.request({
        method,
        url: endpoint,
        data: isFile || isDisableSneckCase ? requestData : convertKeysToSnakeCase(requestData),
        params,
        ...config,
        headers: mergedHeaders,
      })

      if (response?.status < 400) {
        handleSuccess(response, onSuccess, successMessage)
      }

      if (response?.status < 400 && options?.refetch) {
        if (Array.isArray(options.refetch)) {
          options.refetch.forEach((queryKey) => {
            if (Array.isArray(queryKey)) {
              queryClient.invalidateQueries({queryKey: queryKey, exact: false})
            } else {
              queryClient.invalidateQueries({queryKey: [queryKey], exact: false})
            }
          })
        } else {
          queryClient.invalidateQueries({queryKey: [options.refetch], exact: false})
        }
      }

      if (response?.status < 400 && options?.navigate) {
        navigate(options?.navigate)
      }

      return {
        data: response?.data?.data || response?.data || [],
        meta: response?.data?.data?.meta || response?.data?.meta || [],
        pagination:
          response?.data?.data?.meta?.pagination || response?.data?.meta?.pagination || {},
        status: response?.status || '0',
      }
    } catch (error: any) {
      return {error: handleError(error, options), status: error?.response?.status}
    } finally {
      const {disableLoading = false} = options || {}
      if (options?.setLoading && !disableLoading) options.setLoading(false)
      options?.onLoadingEnd?.()
    }
  }

  const useWithGetQuery = (
    endpoint: string,
    options?: ApiOptions,
    queryOptions?: UseQueryOptions<any, any, any, any>
  ) => {
    const {isCaching = true, queryId, filters} = options || {}
    const queryKey = [queryId ? queryId : endpoint, isCaching ? filters : null]
    return useQuery(queryKey, () => createRequest('get', endpoint, null, options), {
      keepPreviousData: true,
      ...queryOptions,
      select: (data) => data || [],
    })
  }

  return {
    get: (endpoint: string, options?: ApiOptions) => createRequest('get', endpoint, null, options),
    post: (endpoint: string, data: any, options?: ApiOptions) =>
      createRequest('post', endpoint, data, options),
    put: (endpoint: string, data: any, options?: ApiOptions) =>
      createRequest('put', endpoint, data, options),
    patch: (endpoint: string, data: any, options?: ApiOptions) =>
      createRequest('patch', endpoint, data, options),
    delete: (endpoint: string, data: any, options?: ApiOptions) =>
      createRequest('delete', endpoint, data, options),
    useGetQuery: (
      endpoint: string,
      options?: ApiOptions,
      queryOptions?: UseQueryOptions<any, any, any, any>
    ) => useWithGetQuery(endpoint, options, queryOptions),
  }
}

export default useApi
