/* eslint-disable no-empty-pattern */
import { useState } from "react"
import usePermission from "../../../hook/usePermission"
import { useGetLiquidationReport } from "../hooks/useGetLiquidationReport"
import { useLiquidationReportOperations } from "../hooks/useLiquidationReportOperations"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { LiquidationReportPageContext } from "../context"
import LiquidationReportHeader from "../sections/headers/LiquidationReportHeader"
import LiquidationReportTable from "../sections/tables/LiquidationReportTable"
import { Pagination } from "../../../utils/pagination"


const LiquidationReport = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('products_product reports', 'write')
  const [checkedRows, setCheckedRows] = useState<any>([])

  const {LiquidationProducts, filters, isLoading, onPageChange, onSortingChange, pagination,onSearch} =
    useGetLiquidationReport()

  const {updateLiquidationReport, removeProduct, onDownloadCsv, isLoadingCsv, isOprationLoading} =
    useLiquidationReportOperations()

  const LiquidationReportBreadCrumbs: Array<PageLink> = [
    {
      title: 'All Products',
      path: '/products/all-products/all',
      isSeparator: false,
      isActive: false,
    },
  ]

  const contextValue: any = {
    hasProjectSettingsPermission,
    LiquidationReportsData: LiquidationProducts,
    isLoading: isLoading,
    onSortingChange: onSortingChange,
    filters: filters,
    pagination: pagination,
    onPageChange: onPageChange,
    checkedRows,
    setCheckedRows,
    updateLiquidationReport,
    removeProduct,
    isOprationLoading,
    onDownloadCsv,
    isLoadingCsv,
    onSearch
  }

  return (
    <LiquidationReportPageContext.Provider value={contextValue}>
      <PageTitle breadcrumbs={LiquidationReportBreadCrumbs}>Liquidation</PageTitle>
      <LiquidationReportHeader />
      <LiquidationReportTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </LiquidationReportPageContext.Provider>
  )
}

export default LiquidationReport
