import React from 'react'
import {PriceChangeLogTable} from '../sections/table/PriceChangeLogTable'
import { PriceChangeLogPageContext } from '../contexts'
import { useGetPriceChangeLog } from '../hooks/useGetPriceChangeLog'
import { useParams } from 'react-router-dom'

const PriceChangeLog = () => {
const params = useParams<any>()
  const productId = params?.id as string
  const {PriceChangeLogData, ProductName, isLoading,priceChangeLogFilters,onSortingChange} = useGetPriceChangeLog(productId)

  const contextValue = {
    PriceChangeLogData,
    productName: ProductName,
    isLoading,
    priceChangeLogFilters,
    onSortingChange
  }

  return (
    <PriceChangeLogPageContext.Provider value={contextValue}>
      <div className='mb-10 fw-bold fs-5'>
        {ProductName}
      </div>
      <PriceChangeLogTable />
    </PriceChangeLogPageContext.Provider>
  )
}

export default PriceChangeLog