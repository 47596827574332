import {DynamicTable} from '../../../../components/DynamicTable'
import {classifiedAsTableColumns, classifiedAsTableRowData} from '../../utils'
import ClassifiedAsTableRow from './ClassifiedAsTableRow'
import {useContext} from 'react'
import {ClassifiedAsPageContext} from '../../context'

const ClassifiedAsTable = () => {
  const {classifiedAsList, filters, onSortingChange, isLoading} =
    useContext(ClassifiedAsPageContext)

  return (
    <DynamicTable
      data={classifiedAsList}
      sortableColumns={classifiedAsTableColumns}
      TableRow={ClassifiedAsTableRow}
      permissionPath='analytics_replenishment_classified as'
      filters={filters}
      onSortingChange={onSortingChange}
      loading={isLoading}
      rowParsingFunction={classifiedAsTableRowData}
    />
  )
}

export default ClassifiedAsTable
