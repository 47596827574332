import {useContext} from 'react'
import {ClassificationContext} from '../../context'
import {Select} from '../../../../components/Select/'
import Date from '../../../../components/Date/Date'

const ClassificationTableRow = ({row, actionComponent,checkBoxComponent}: any) => {
  const {Classifications, onClassificationChange} = useContext(ClassificationContext)
  return (
    <tr>
      {checkBoxComponent && <td>{checkBoxComponent(row)}</td>}
      <td>{row.sku}</td>
      <td>{row.product_title}</td>
      <td>{row.old_classification}</td>
      <td>
        <Select
          id={'new_classification'}
          placeholder={'Select Classification'}
          options={Classifications}
          isNullable={true}
          placeholderValue={''}
          value={row.new_classification || ''}
          onChange={(e) =>
            onClassificationChange({request_ids: [row?.id], new_classification: e.target.value})
          }
        />
      </td>
      <td>{row.created_by}</td>
      <td>
        <Date date={row.created_at} />
      </td>
      {actionComponent && <td>{actionComponent(row)}</td>}
    </tr>
  )
}

export default ClassificationTableRow
