import usePermission from '../../../../hook/usePermission'
import {useContext, useState} from 'react'
import {ClassificationContext} from '../../context'
import Search from '../../../../components/Search'
import MultipleSelect from '../../../../components/MultiSelect'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'

const ClassificationHeader = () => {
  const {hasPermission} = usePermission()
  const {
    onSearch,
    onClassificationFilterChange,
    Classifications,
    onApprove,
    onReject,
    checkedRows,
    setCheckedRows,
  } = useContext(ClassificationContext)

  const [loadingState, setLoadingState] = useState({
    isApproving: false,
    isRejecting: false,
  })
  const [showModal, setShowModal] = useState(false)
  const [actionType, setActionType] = useState('')

  const handleMultiSelectClassificationChange = (selectedOptions: any) => {
    const commaSeparatedValues = selectedOptions.map((option: any) => option.value).join(',')
    onClassificationFilterChange(commaSeparatedValues)
  }

  const handleApprove = async () => {
    setLoadingState((prev) => ({...prev, isApproving: true}))
    document.body.click()
    const res: any = await onApprove({approved: true, request_ids: checkedRows})
    if (res.status === 200) {
      setCheckedRows([])
    }
    setLoadingState((prev) => ({...prev, isApproving: false}))
  }

  const handleReject = async () => {
    setLoadingState((prev) => ({...prev, isRejecting: true}))
    document.body.click()
    const res: any = await onReject({approved: false, request_ids: checkedRows})
    if (res.status === 200) {
      setCheckedRows([])
    }
    setLoadingState((prev) => ({...prev, isRejecting: false}))
  }

  const handleModalOpen = (action: string) => {
    setActionType(action)
    setShowModal(true)
  }

  const handleModalAction = () => {
    if (actionType === 'approve') {
      handleApprove()
    } else if (actionType === 'reject') {
      handleReject()
    }
    setShowModal(false)
  }

  return (
    <>
      {hasPermission('analytics_replenishment_dashboard', 'write') && (
        <div className='d-flex justify-content-between mb-10 '>
          <div className='d-flex align-items-center gap-4'>
            <Search
              onSearch={(searchTerm: string) => {
                onSearch && onSearch(searchTerm)
              }}
            />
            {checkedRows.length > 0 && (
              <>
                <button
                  className='btn btn-primary'
                  onClick={() => handleModalOpen('approve')}
                  disabled={loadingState.isApproving || loadingState.isRejecting}
                >
                  {!loadingState.isApproving ? (
                    <span className='indicator-label'>Approve All</span>
                  ) : null}
                  {loadingState.isApproving && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Approving...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  className='btn btn-danger'
                  onClick={() => handleModalOpen('reject')}
                  disabled={loadingState.isApproving || loadingState.isRejecting}
                >
                  {!loadingState.isRejecting ? (
                    <span className='indicator-label'>Reject All</span>
                  ) : null}
                  {loadingState.isRejecting && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Rejecting...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </>
            )}
          </div>

          <div className='d-flex align-items-center '>
            <label className={'fw-semibold m-3'}>{'Classifications to be'}</label>
            <div className=' w-200px'>
              <MultipleSelect
                options={Classifications}
                handleSelected={handleMultiSelectClassificationChange}
                hasSelectAll={false}
                placeholder='Select Classification'
              />
            </div>
          </div>
        </div>
      )}
      {/* Confirmation Modal */}
      <ConfirmationModal
        onClose={() => setShowModal(false)}
        show={showModal}
        body={
          <p>
            {actionType === 'approve'
              ? 'Are you sure you want to Approve all classification for selected items?'
              : 'Are you sure you want to Reject all classification for selected items?'}
          </p>
        }
        onAction={handleModalAction}
        isDataLoading={loadingState.isApproving || loadingState.isRejecting}
        isOperationLoading={loadingState.isApproving || loadingState.isRejecting}
      />
    </>
  )
}

export default ClassificationHeader
