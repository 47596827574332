import {Link} from 'react-router-dom'
import {getNotificationLinkPath, renderNotificationMessage} from './utils'

const NotificationTimeline = ({
  data,
  toggleReadStatus,
  toggleDrawer,
  isReadIndicator = true,
}: any) => {
  const {id, is_read} = data

  const clickToReadMessage = () => {
    toggleDrawer?.()
    toggleReadStatus?.(id, is_read)
  }

  return (
    <div className='ad-notification-item'>
     <div className='d-flex'>
     <Link
        to={getNotificationLinkPath(data)}
        className='timeline-item text-dark d-flex w-100'
        onClick={clickToReadMessage}
      >
        {/* <div className='timeline-line border-gray-400 ms-5'></div> */}
        {/* <div className='timeline-icon symbol symbol-circle symbol-40px'>
          <div className='symbol-label bg-white border border-dashed border-gray-400'>
            <KTSVG path={`${notificationIconBaseUrl}${svgFileName(module)}`} />
          </div>
        </div> */}
        <div className='ad-notification-message-wrapper mb-10 d-flex justify-content-between w-100'>
          <div className='ad-notification-message'>
            <p className='mb-0'>{renderNotificationMessage(data)}</p>
          </div>
          {isReadIndicator && (
            <div className='p-2'>
              <div className='w-10px h-10px d-flex justify-content-center align-items-center'>
                <span
                  className={`badge badge-circle w-8px h-8px ${
                    is_read ? 'badge-secondary' : ' badge-success '
                  }`}
                  title={`${is_read ? 'Mark as unread' : 'Mark as read'}`}
                  onClick={(e) => {
                    e.preventDefault() // Prevent navigation on badge click
                    e.stopPropagation() //
                    toggleReadStatus(id, is_read)
                  }}
                ></span>
              </div>
            </div>
          )}
        </div>
      </Link>
     </div>
    </div>
  )
}

export default NotificationTimeline
