import {useState} from 'react'
import useApi from '../../../services/useApi'

const useReplenishmentOperations = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const saveTableColumns = (columns: any, type: any) => {
    const payload = {
      columns: columns,
      type: type,
    }
    return Api.post('/analytics/replenishment/columns', payload, {
      setLoading: setIsLoading,
    })
  }

  return {
    saveTableColumns: saveTableColumns,
    isLoading: isLoading,
  }
}

export default useReplenishmentOperations
