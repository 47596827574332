import useApi from '../../../services/useApi'

const useGetFlavorsListing = (productId: any, customerId: any) => {
  const Api = useApi()

  const {data: response, isFetching} = Api.useGetQuery(
    `/bulkOrders/reports/product/${productId}/customer/${customerId}/flavours`,
    {
      queryId: 'flavors-listing',
    }
  )

  return {
    flavorsData: response?.data || {},
    isLoading: isFetching,
  }
}

export default useGetFlavorsListing
