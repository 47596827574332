import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import ClassifiedAsTableActionDropdown from '../dropdown/ClassifiedAsTableActionDropdown'
import ClassifiedAsModal from './ClassifiedAsModal'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ClassifiedAsPageContext} from '../../context'

const ClassifiedAsTableActionModal = ({row}: {row: any}) => {
  const {deleteClassifiedAs, isLoadingOperations, isLoading} = useContext(ClassifiedAsPageContext)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <ClassifiedAsTableActionDropdown
            setShowEditModal={setShowEditModal}
            setShowDeleteModal={setShowDeleteModal}
          />
        }
      />

      {showEditModal && (
        <ClassifiedAsModal
          isEdit={true}
          showModal={showEditModal}
          onClose={() => setShowEditModal(false)}
          row={row}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onAction={() => deleteClassifiedAs(row?.id)}
          isDataLoading={isLoading}
          isOperationLoading={isLoadingOperations}
          body={
            <p>
              Do you really want to delete this classified as? <br />
              This action is irreversible.
            </p>
          }
        />
      )}
    </>
  )
}

export default ClassifiedAsTableActionModal
