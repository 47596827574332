import {createContext} from 'react'

export const ClassifiedAsPageContext = createContext({
  classifiedAsList: [],
  isLoading: false,
  filters: {},
  onSearch: (searchTerm: string) => {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},

  createClassifiedAs: (payload: any) => {},
  updateClassifiedAs: (payload: any, id: number) => {},
  deleteClassifiedAs: (id: number) => {},
  isLoadingOperations: false,
})
