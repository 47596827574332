/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {Select} from '../Select/'
import NotificationTimeline from '../NotificationMessage/NotificationTimeline'
import {useGetNotification} from '../../hook/AppNotification/useGetNotification'
import {useGetNotificationTypeDropdown} from '../../hook/AppNotification/useGetNotificationTypeDropdown'
import Loading from '../../modules/loading'
import NoDataFound from '../NoDataFound'
import useNotificationOperation from '../../hook/AppNotification/useNotificationOperation'
import InfiniteScrollWrapper from '../InfiniteScrollWrapper'

const FloatingNotificationButton = () => {
  const {Notifications, onChangeNotificationType, loading, onLoadMore, refetch, pagination} =
    useGetNotification()
  const {NotificationsType} = useGetNotificationTypeDropdown()
  const {updateNotificationStatus} = useNotificationOperation()

  // const playSound = async (soundUrl: string): Promise<void> => {
  //   const audio = new Audio('/media/sounds/notificationSound.mp3')
  //   try {
  //     await audio.play()
  //   } catch (error) {
  //     console.error('Failed to play sound:', error)
  //   }
  // }

  // const [notifications, setNotifications] = useState<any>(Notifications)

  // useEffect(() => {
  //   setNotifications(Notifications)
  // }, [Notifications])

  // const handleClick = async () => {
  //   // Add a user interaction here if needed
  //   setTimeout(() => {
  //     playSound('/media/sounds/notificationSound.mp3')
  //     console.log('Timeout executed after sound played')
  //   }, 500)
  // }

  useEffect(() => {
    // const socket = io('https://mwg-stage.atlantixdigital.com/', {
    //   transports: ['websocket'],
    //   path: '/admin/api/socket.io',
    // })
    // socket.on('connect', () => {
    //   console.log('Connected to server')
    //   // console.log('yeah yeah')
    //   // setData('hey its me')
    // })
    // socket.on('server_message', (data: any) => {
    //   console.log('herehere')
    //   try {
    //     console.log('Received message from server:', data)
    //     // mutation.mutate({newItem: data['data'], message: messages[0]})
    //   } catch (error) {
    //     console.error('Error handling server_message:', error)
    //   }
    // })
    // // socket.on('get_message', (data: any) => {
    // //   console.log('Received message from server:', data)
    // //   mutation.mutate({newItem: data['data'], message: messages[0]})
    // // })
    // return () => {
    //   console.log('hello')
    //   socket.disconnect()
    // }
  }, [])

  const toggleReadStatus = (id: string, isRead: any) => {
    updateNotificationStatus(id, {is_read: !isRead})
  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    refetch()
    setIsDrawerOpen(!isDrawerOpen)
  }

  const onClose = () => {
    setIsDrawerOpen(false)
  }

  return (
    <div className=''>
      {/* Notification Button */}
      {/* <button onClick={() => handleClick()} className='d-none'>
        S
      </button> */}
      <div className='position-relative' onClick={toggleDrawer}>
        <div className='cursor-pointer w-30px h-30px d-flex align-items-center justify-content-center'>
          <i className='fa-regular fa-bell fs-2'></i>

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>

          {/* Badge to display unread notification count */}
          {pagination?.unread_count > 0 && (
            <div className='badge badge-circle bg-danger text-white position-absolute top-2 start-100 translate-middle'>
              {pagination?.unread_count}
            </div>
          )}
        </div>
      </div>

      {/* Notification Drawer */}
      {isDrawerOpen && (
        <div className={`ad-drawer-section ${isDrawerOpen ? 'ad-drawer-active' : ''}`}>
          <div className='ad-drawer-section-overlay' onClick={onClose}></div>
          <div className='ad-drawer-section-inner'>
            <div className='h-100 px-8 '>
              {/* Header */}
              <div className='py-5 border-bottom mb-5 d-flex justify-content-between align-items-center'>
                <h4 className='modal-title fw-bolder'>Notifications</h4>
                <i className='fa-solid fa-xmark cursor-pointer' onClick={onClose}></i>
              </div>

              <div className='mb-10'>
                <div className='w-250px'>
                  <Select
                    id={'NotificationsType'}
                    options={[...NotificationsType]}
                    placeholder='All Notifications'
                    valueKey='module_name'
                    labelKey='module_name'
                    isNullable={true}
                    onChange={(e) => onChangeNotificationType(e.target.value)}
                  />
                </div>
              </div>

              {/* Notification Timeline Body */}
              <div className='app-drawer-nav-body position-relative'>
                <InfiniteScrollWrapper
                  name='ip'
                  loadMore={onLoadMore}
                  data={Notifications}
                  height={`calc(100% - 5px)`}
                >
                  <div className='timeline timeline-border-dashed'>
                    {Notifications && Notifications.length > 0
                      ? Notifications.map((activity: any) => (
                          <NotificationTimeline
                            data={activity}
                            toggleReadStatus={toggleReadStatus}
                            toggleDrawer={toggleDrawer}
                          />
                        ))
                      : !loading && <NoDataFound colspan={0} />}
                  </div>
                </InfiniteScrollWrapper>
                {loading && <Loading />}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FloatingNotificationButton
