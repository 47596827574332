import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getTableData} from '../services'
import useFilters from '../../../hook/useFilters'

const initialFilters = {
  page: 1,
  limit: 10,
}

const useGetAllSuppliers = (): any => {
  const {filters} = useFilters(initialFilters)

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`get-all-supplier-listing`, () => {
    return getTableData(`/analytics/replenishment/supplier/data`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  if (!response?.['data']) {
    return []
  }

  return {
    suppliers: response?.['data']?.['data'],
    isFetchingData: isFetching,
  }
}

export default useGetAllSuppliers
