import {Link, useLocation, useParams} from 'react-router-dom'
import usePermission from '../../../hook/usePermission'
// import useProductExists from '../hooks/useProductExists'

function AnalyticsPageNavigation() {
  const params = useParams<any>()
  const location = useLocation()
  const productId = params?.id as string
  const decodedLocationPath = decodeURIComponent(location.pathname)
  const {hasPermission} = usePermission()

  return (
    <div className=''>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10'>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (decodedLocationPath === `/analytics/sold-products/product-report/${productId}` &&
                'active')
            }
            to={`/analytics/sold-products/product-report/${productId}`}
          >
            Sold Products
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (decodedLocationPath === `/analytics/sold-products/report/${productId}` && 'active')
            }
            to={`/analytics/sold-products/report/${productId}`}
          >
            Customer Reports
          </Link>
        </li>
        {/* {exists['data'] && ( */}
        {hasPermission('analytics_consignments', 'read') ? (
          <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (decodedLocationPath === `/analytics/consignments/all/${productId}` && 'active')
              }
              to={`/analytics/consignments/all/${productId}`}
            >
              Consignments
            </Link>
          </li>
        ) : null}
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (decodedLocationPath === `/analytics/sold-products/order-history/${productId}` &&
                'active')
            }
            to={`/analytics/sold-products/order-history/${productId}`}
          >
            Order History
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (decodedLocationPath === `/analytics/sold-products/price-change-log/${productId}` &&
                'active')
            }
            to={`/analytics/sold-products/price-change-log/${productId}`}
          >
            Price Change Log
          </Link>
        </li>
        {/* )} */}
        {/* <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (decodedLocationPath === `/analytics/on-hold/${productId}` &&
                'active')
            }
            to={`/analytics/on-hold/${productId}`}
          >
            On Hold
          </Link>
        </li> */}
      </ul>
    </div>
  )
}

export default AnalyticsPageNavigation
