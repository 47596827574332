import {Route, Routes, Outlet} from 'react-router-dom'
import ReportDashboard from '../sections/ReportDashboard'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import PDANavigation from '../components/PDANavigation'

const PDABreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Sold Products',
    path: '/analytics/sold-products/product-report',
    isSeparator: false,
    isActive: false,
  },
]

const CustomersReport = () => {
  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <PDANavigation />
              <Outlet />
            </>
          }
        >
          <Route
            path=''
            element={
              <>
                <PageTitle breadcrumbs={PDABreadCrumbs}>Dashboard</PageTitle>
                <ReportDashboard />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  )
}

export default CustomersReport
