import {createContext} from 'react'

export const LiquidationReportPageContext = createContext({
  hasProjectSettingsPermission: false,
  LiquidationReportsData: [],
  isLoading: false,
  onSortingChange: (key: any, value: any) => {},
  filters: {} as any,
  pagination: {},
  onPageChange: (page: number) => {},
  checkedRows: [],
  setCheckedRows: (value: any) => {},
  updateLiquidationReport: (payload: any, id: any) => {},
  removeProduct: (payload: any, id: any) => {},
  isOprationLoading: false,
  onDownloadCsv: (data: any) => {},
  isLoadingCsv: false,
  onSearch: (searchTerm: any) => {},
})