import React,{useContext, useEffect, useState,useRef} from 'react'
import {ReorderingContext} from '../context'
import ReorderingTableRow from './ReorderingTableRow'
import {toggleSortingOrder} from '../../../utils/common'
import Loading from '../../loading'
import {RenderBothArrow} from '../../../utils/renderBothArrow'
import NoDataFound from '../../../components/NoDataFound'

function ReorderingTable() {
  const {reorderingData, isLoading, onClickSorting} = useContext(ReorderingContext)
  const {reorderFilters, monthly_column}: any = useContext(ReorderingContext)

  const monthly_column_keys = monthly_column !== undefined ? Object.keys(monthly_column) : undefined
  const [direction, setDirection] = useState('')
  const [sortBy, setSortBy] = useState('')

  const inputRefs = useRef([])

  useEffect(() => {
    if (reorderFilters) {
      const array = reorderFilters['sort_by'].split('/')

      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [reorderFilters])

  useEffect(() => {
    if (reorderingData?.length) {
      inputRefs.current = reorderingData.map(
        (_: any, i: number) => inputRefs.current[i] || React.createRef()
      )
    }
  }, [reorderingData])

  return (
    <div className='card-body p-0'>
      <div className='table-responsive position-relative'>
        <div className='table-loader-wrapper'>
          <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted text-uppercase'>
                <th className='min-w-150px'>{'SKU'}</th>
                <th
                  className={`min-w-400px w-400px`}
                  onClick={() => onClickSorting('title', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center'>
                    <span className='me-2'>{'Title'}</span>

                    {direction === 'asc' && sortBy === 'title' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'title' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-250px w-250px`}
                  onClick={() => onClickSorting('primary_supplier', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center'>
                    <span className='me-2'>{'Supplier Company Name'}</span>

                    {direction === 'asc' && sortBy === 'primary_supplier' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'primary_supplier' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-200px w-200px`}
                  onClick={() => onClickSorting('quantity', toggleSortingOrder(direction))}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Item To Be Ordered'}</span>

                    {direction === 'asc' && sortBy === 'quantity' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'quantity' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-175px w-175px`}
                  onClick={() => onClickSorting('total_cost', toggleSortingOrder(direction))}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Total Cost'}</span>

                    {direction === 'asc' && sortBy === 'total_cost' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'total_cost' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-150px w-150px`}
                  onClick={() =>
                    onClickSorting('quantity_available', toggleSortingOrder(direction))
                  }
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Available'}</span>

                    {direction === 'asc' && sortBy === 'quantity_available' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'quantity_available' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-150px w-150px`}
                  onClick={() => onClickSorting('quantity_incoming', toggleSortingOrder(direction))}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Incoming'}</span>

                    {direction === 'asc' && sortBy === 'quantity_incoming' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'quantity_incoming' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-175px w-175px`}
                  onClick={() => onClickSorting('total_sold_30', toggleSortingOrder(direction))}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Total Sold (30)'}</span>

                    {direction === 'asc' && sortBy === 'total_sold_30' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'total_sold_30' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-200px w-200px`}
                  onClick={() => onClickSorting('reorder_point', toggleSortingOrder(direction))}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Reorder Point'}</span>

                    {direction === 'asc' && sortBy === 'reorder_point' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'reorder_point' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-225px w-225px`}
                  onClick={() =>
                    onClickSorting('incremental_quantity', toggleSortingOrder(direction))
                  }
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Incremental Quantity'}</span>

                    {direction === 'asc' && sortBy === 'incremental_quantity' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'incremental_quantity' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-300px w-300px`}
                  onClick={() =>
                    onClickSorting('suggested_order_qty', toggleSortingOrder(direction))
                  }
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Suggested Order Quantity'}</span>

                    {direction === 'asc' && sortBy === 'suggested_order_qty' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'suggested_order_qty' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-200px w-200px`}
                  onClick={() => onClickSorting('to_order_qty', toggleSortingOrder(direction))}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'To Order Quantity'}</span>

                    {direction === 'asc' && sortBy === 'to_order_qty' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'to_order_qty' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                {monthly_column_keys &&
                  monthly_column_keys !== undefined &&
                  monthly_column_keys.map((column) => (
                    <th
                      key={monthly_column[column]}
                      className={`min-w-100px w-100px`}
                      onClick={() => onClickSorting(column, toggleSortingOrder(direction))}
                    >
                      <div className='d-flex align-items-center'>
                        <span className='me-2'>{monthly_column[column]}</span>

                        {direction === 'asc' && sortBy === column ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === column ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                  ))}
                <th
                  className={`min-w-125px w-125px`}
                  onClick={() => onClickSorting('cost', toggleSortingOrder(direction))}
                >
                  <div className='d-flex align-items-center'>
                    <span className='me-2'>{'Cost'}</span>

                    {direction === 'asc' && sortBy === 'cost' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'cost' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th className='min-w-200px text-center'>{'Sale History Months'}</th>
                <th className='min-w-200px text-center'>{'Days to Replenish'}</th>
                <th className='min-w-250px'>{'Brand'}</th>
                <th className='min-w-200px'>{'Classification'}</th>
                <th
                  className={`min-w-175px w-175px`}
                  onClick={() => onClickSorting('weeks_on_hand', toggleSortingOrder(direction))}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Week on Hand'}</span>

                    {direction === 'asc' && sortBy === 'weeks_on_hand' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'weeks_on_hand' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th
                  className={`min-w-150px w-150px`}
                  onClick={() => onClickSorting('turn_rate', toggleSortingOrder(direction))}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <span className='me-2'>{'Turn Rate'}</span>

                    {direction === 'asc' && sortBy === 'turn_rate' ? (
                      <i className='bi bi-caret-up-fill fs-8'></i>
                    ) : direction === 'desc' && sortBy === 'turn_rate' ? (
                      <i className='bi bi-caret-down-fill fs-8'></i>
                    ) : (
                      <RenderBothArrow />
                    )}
                  </div>
                </th>
                <th className='min-w-250px'>{'Modified By'}</th>
                <th className='min-w-150px'>{'Modified Date'}</th>
              </tr>
            </thead>
            <tbody>
              {reorderingData && reorderingData.length > 0
                ? reorderingData.map((row: any, index: any) => (
                    <ReorderingTableRow
                      row={row}
                      monthly_column_keys={monthly_column_keys}
                      index={index}
                      inputRef={inputRefs.current[index]}
                      inputRefArr={inputRefs}
                    />
                  ))
                : !(isLoading || isLoading === undefined) && <NoDataFound colspan={24} />}
            </tbody>
          </table>
          {isLoading || isLoading === undefined ? <Loading /> : null}
        </div>
      </div>
    </div>
  )
}

export default ReorderingTable
