import {useContext} from 'react'
import Accordion from '../../../components/Accordion'
import LinkedTicketsTable from './tables/LinkedTicketsTable'
import {TicketDetailsPageContext} from '../context'
import {isEmpty} from '../../../utils/common'

const LinkedTicketsAccordion = () => {
  const {ticketDetails} = useContext(TicketDetailsPageContext)

  return (
    <Accordion
      defaultOpen={'All'}
      className='mb-6'
      headerClass='mb-4'
      headerToggleClass='p-3 rounded-1 text-gray-800 border border-bottom'
      bodyClass='p-0 px-2'
      items={[
        {
          id: 'parent-tickets',
          title: 'Parent Tickets',
          content: <LinkedTicketsTable data={ticketDetails?.parent_cards} type='parent' />,
          show: !isEmpty(ticketDetails?.parent_cards),
        },
        {
          id: 'sub-tickets',
          title: 'Sub Tickets',
          content: <LinkedTicketsTable data={ticketDetails?.child_cards} type='child' />,
          show: !isEmpty(ticketDetails?.child_cards),
        },
      ]}
    />
  )
}

export default LinkedTicketsAccordion
