
import moment from 'moment'
import {useGetDataForCSV} from '../../../../hook/useGetDataForCSV'
import Import from '../../../../images/Import.png'
import usePermission from '../../../../hook/usePermission'
import { useContext, useState } from 'react'
import { ProductsTagsContext } from '../../context'
import useProductTagsOperations from '../../hooks/useProductTagsOperations'
import { convertKeysToSnakeCase } from '../../../../utils/common'
import { dataToProductTagsCSV, productTagsCsvConfig, sampleProductTagsCSVData } from '../../utils'
import { SampleCSVDownload } from '../../../../components/SampleCSVDownload'
import { CSVImporter } from '../../../../components/CSVImporter'
import ProductTagsCreateModal from '../model/ProductTagsCreateModal'
import { ConfirmationModal } from '../../../../components/ConfirmationModal'
import ProductTagsSearchComponent from './ProductTagsSearchComponent'

const ProductTagsHeader = () => {
  const {hasPermission} = usePermission()
  const {fetchDataForCSV, isLoading: isLoadingCSV} = useGetDataForCSV()
  const {checkedRows, isLoading, productsTagsList, setCheckedRows} = useContext(ProductsTagsContext)
  const [showModal, setShowModal] = useState(false)
  const {
    assignProductTags,
    isLoading: isOperationLoading,
    deleteProductTags,
    isDeleting,
  } = useProductTagsOperations()

  const [errors, setErrors] = useState<string[] | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleUploadSuccess = async (data: any) => {
    const {error} = await assignProductTags(data)
    if (error) {
      setErrors(error)
    }
  }

  const defaultData = productsTagsList.flatMap((product: any) => ({
    sku: product?.variant_sku ?? product?.variant_sku ?? product?.sku ?? '',
    tags: product?.tags,
  }))

  const handleDelete = () => {
    setShowDeleteModal(true)
  }

  const handleDeleteConfirm = async () => {
    deleteProductTags({data: convertKeysToSnakeCase(checkedRows)})
    setCheckedRows([])
  }

  const handleDownLoadCSV = async () => {
    const formattedDateTime = moment().format('D_MMM_HH_mm').toLowerCase()
    const res: any = await fetchDataForCSV('/analytics/replenishment/tags/products')
    if (res?.status === 200) {
      const data = dataToProductTagsCSV(res?.data)
      if (data?.length) {
        SampleCSVDownload(data || [], `Product_Tags_${formattedDateTime}`)
      }
    }
  }

  return (
    <>
      {hasPermission('analytics_replenishment_product tags', 'write') && (
        <div className='d-flex justify-content-between mb-10 '>
          <div className='d-flex align-items-center gap-4'>
            <ProductTagsSearchComponent />
            {checkedRows.length > 0 && (
              <button className='btn btn-danger' onClick={handleDelete}>
                Delete
              </button>
            )}

            <button
              className='btn btn-outline'
              onClick={handleDownLoadCSV}
              disabled={isLoadingCSV || !productsTagsList?.length}
            >
              <div className='d-flex align-items-center'>
                <img src={Import} alt='Import File' className='me-2 import-icon' />
                <div className='d-flex align-items-center'>
                  {isLoadingCSV ? 'Downloading...' : 'Export CSV'}
                  {isLoadingCSV && (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  )}
                </div>
              </div>
            </button>
          </div>

          <div className='d-flex align-items-center justify-content-center gap-4'>
            <button
              className='btn'
              onClick={() =>
                SampleCSVDownload(sampleProductTagsCSVData, 'Sample product tags File')
              }
            >
              <span className='text-decoration-underline'>Download Sample CSV</span>
            </button>
            <CSVImporter
              id={'replenishment-product-tags'}
              config={productTagsCsvConfig}
              existingData={defaultData}
              onSuccess={handleUploadSuccess}
              isLoading={isOperationLoading}
            />

            <button className='btn btn-primary' onClick={() => setShowModal(true)}>
              <i className='bi bi-plus-lg'></i>
              Assign a Tag
            </button>

            <ProductTagsCreateModal
              showModal={showModal}
              setShowModal={setShowModal}
              isEdit={false}
            />
          </div>
        </div>
      )}
      {errors && Array.isArray(errors) && (
        <ConfirmationModal
          show={errors ? true : false}
          onClose={() => setErrors(null)}
          title={'CSV Import Error!'}
          body={
            <ul>
              {errors.map((error: any, index: number) => (
                <li key={index} className='mb-3'>
                  {error?.key?.toUpperCase()}: {error?.message}
                </li>
              ))}
            </ul>
          }
          onAction={() => setErrors(null)}
          disableAction={true}
        />
      )}
      <ConfirmationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onAction={handleDeleteConfirm}
        isOperationLoading={isDeleting}
        isDataLoading={isLoading}
      />
    </>
  )
}

export default ProductTagsHeader
