import {useContext, useEffect, useState} from 'react'
import Search from '../../../../components/Search'
import Import from '../../../../images/Import.png'
import {LiquidationReportPageContext} from '../../context'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import usePermission from '../../../../hook/usePermission'
import useGetJobData from '../../../jobs/hooks/useGetJobData'
import { formatDate } from '../../../../utils/date'

const LiquidationReportHeader = () => {
  const {onDownloadCsv, isLoadingCsv, LiquidationReportsData, onSearch, checkedRows} = useContext(
    LiquidationReportPageContext
  )
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const {hasPermission} = usePermission()
  let job_name = 'update_products'
  const {jobData, refetchJobData, runTask} = useGetJobData(job_name)

  useEffect(() => {
    let intervalId: any = null

    if (jobData?.status === 'running') {
      intervalId = setInterval(() => {
        refetchJobData()
      }, 5000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData?.status === 'running', refetchJobData])

  async function downloadCsvFile() {
    const res: any = await onDownloadCsv(checkedRows)
    if (res?.status === 200) {
      setShowModal(true)
      setMessage(res?.data?.message)
    }
  }

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div>
        <Search
          onSearch={(searchTerm: string) => {
            onSearch && onSearch(searchTerm)
          }}
        />
      </div>
      <div className='d-flex justify-content-between'>
        {hasPermission('settings_jobs', 'write') && (
          <div className='position-relative d-flex align-items-center me-10'>
            <span className='me-2'>Last Sync:</span>
            <span className='me-3'>{formatDate(jobData.last_run_end_time, false)} </span>
            {jobData?.status !== 'running' ? (
              <div className='d-flex justify-content-center flex-shrink-0'>
                {
                  <i
                    className='fas fa-sync cursor-pointer fs-5'
                    onClick={() => runTask('update_products')}
                  ></i>
                }
              </div>
            ) : (
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-success' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          <button
            className='btn btn-outline'
            onClick={downloadCsvFile}
            disabled={isLoadingCsv || !LiquidationReportsData?.length}
          >
            <div className='d-flex align-items-center'>
              <img src={Import} alt='Import File' className='me-2 import-icon' />
              <div className='d-flex align-items-center'>
                {isLoadingCsv ? 'Downloading...' : 'Export CSV'}
                {isLoadingCsv && (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                )}
              </div>
            </div>
          </button>
        </div>
      </div>
      <ConfirmationModal
        show={showModal}
        onClose={() => {
          setShowModal(false)
          setMessage('')
        }}
        title={'Generating Your CSV File'}
        disableAction={true}
        onAction={() => {
          setShowModal(false)
          setMessage('')
        }}
        okayBtnClass={'btn-primary'}
        body={
          <div>
            <p>We are generating the data you requested for download as a CSV file.</p>
            <p>
              This process may take a few minutes. Once complete, the CSV will be sent to your
              email: <b>{message.split(':')[1]}</b>
            </p>
          </div>
        }
      />
    </div>
  )
}

export default LiquidationReportHeader
