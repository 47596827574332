const ClassifiedAsTableActionDropdown = ({setShowEditModal, setShowDeleteModal}: any) => {
  const handleEdit = () => {
    setShowEditModal(true)
    document.body.click()
  }

  const handleDelete = () => {
    setShowDeleteModal(true)
    document.body.click()
  }

  return (
    <div>
      <div className='px-2 py-1'>
        <span className='text-dark fw-bold text-hover-primary fs-6' onClick={handleEdit}>
          <label className='cursor-pointer'>Edit</label>
        </span>
      </div>
      <div className='px-2 py-1'>
        <span className='text-dark fw-bold text-hover-danger fs-6' onClick={handleDelete}>
          <label className='cursor-pointer'>Delete</label>
        </span>
      </div>
    </div>
  )
}

export default ClassifiedAsTableActionDropdown
