import * as yup from 'yup'

export const createTagValidationSchema = yup.object().shape({
  tags: yup.string().required('Name is required.'),
})

export const createBackOrderValidationSchema = yup.object().shape({
  sku: yup.string().required('SKU is required.'),
  qty: yup.string().required('Quantity is required.'),
})

export const createSafetyStockValidationSchema = yup.object().shape({
  sku: yup.string().required('SKU is required'),
  qty: yup.string().required('Quantity is required'),
})

export const assignProductTagsValidationSchema = (
  isAssignToMultiProducts: boolean,
  isEdit: boolean
) =>
  yup.object().shape({
    sku:
      isAssignToMultiProducts || isEdit ? yup.string() : yup.string().required('SKU is required.'),
    tags: yup.array().min(1, 'Tag is required. Please select at least one tag.'),
  })

export const ClassifiedAsValidationSchema = (classifiedAsList: any, isEdit: boolean, row: any) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Name is required')
      .trim()
      .test('unique-name', 'This classified name already exists', function (value) {
        if (!value) return true

        const normalizedValue = value.toLowerCase().trim()
        const isDuplicate = classifiedAsList.some((item: any) => {
          if (isEdit && row?.id === item.id) return false
          return item?.name?.toLowerCase().trim() === normalizedValue
        })

        return !isDuplicate
      }),
  })
