import { generateColors } from '../../../utils/chartColors'
import {MONTHS_SHORT} from '../../../utils/date'

interface DataItem {
  quantity: number
  revenue: number
}

interface ProductData {
  product_name: string
  quantity: number
  avg_price: number
  revenue: number
  orders: number
  data: Record<string, Record<string, DataItem>>
  state_quantity: Record<string, {quantity: number}>
}

interface SaleData {
  name: string
  datasets: {
    label: string
    data: (string | number)[]
    backgroundColor: string
  }[]
  labels: string[]
  stats: {
    revenue: string
    orders: number
    avgPrice: string
    qtySold: number
  }
}

const blueShades = ['rgba(0, 92, 230, 0.2)', 'rgba(0, 92, 230, 0.5)', 'rgba(30, 100, 350, 0.7)']

export function dataToProductSale(data: ProductData, period: string): SaleData {
  const sales: SaleData = {
    name: data?.product_name,
    datasets: [],
    labels: [],
    stats: {
      revenue: data?.revenue ? `$${data.revenue}` : '-',
      orders: data?.orders,
      avgPrice: data?.avg_price ? `$${data.avg_price}` : '-',
      qtySold: data?.quantity,
    },
  }

  if (!data?.data) {
    return sales
  }

  const periodLabels: Set<string> = new Set()
  const datasets: SaleData['datasets'] = []

  const dataEntries: any = Object.entries(data.data)

  // Loop through the dataset names (Distributor, Retailer, etc.)
  for (const [index, [datasetName, datasetValues]] of dataEntries.entries()) {
    const dataset: SaleData['datasets'][0] = {
      label: datasetName,
      data: [],
      backgroundColor: blueShades[index % blueShades.length], // Ensuring unique color for each dataset
    }

    // Loop through the periods inside each dataset
    for (const [periodKey, value] of Object.entries(datasetValues)) {
      if (typeof value === 'object' && value !== null) {
        const item = value as DataItem

        // Format data as 'quantity (revenue)' for the chart
        dataset.data.push(`${item.quantity} (${item.revenue})`)
        periodLabels.add(periodKey) // Add period key to labels
      }
    }

    datasets.push(dataset)
  }

  sales.datasets = datasets
  sales.labels = Array.from(periodLabels)

  // Sort and format labels if period is 'Month'
  if (period === 'Month') {
    sales.labels.sort((a, b) => {
      const monthIndexA = +a.split('_')[0]
      const monthIndexB = +b.split('_')[0]
      return monthIndexA - monthIndexB
    })

    // Convert period labels to short month names (e.g., Jan, Feb)
    sales.labels = sales.labels.map((label) => {
      const monthIndex = +label.split('_')[0]
      return MONTHS_SHORT[monthIndex - 1] // Assuming MONTHS_SHORT is ['Jan', 'Feb', ...]
    })
  }

  return sales
}

interface BarChartArgs {
  data: (string | number)[]
}

export function BarChartData(data: BarChartArgs) {
  const DistributorData = {
    labels: [data?.toString()],
    datasets: [
      {
        label: 'Qty',
        backgroundColor: 'rgba(0, 92, 230, 0.2)',
        borderColor: 'rgba(0, 92, 230, 1)',
        borderWidth: 0,
        hoverBackgroundColor: 'rgba(0, 92, 230, 1)',
        hoverBorderColor: 'rgba(33, 87, 141, 1)',
        hoverBorderWidth: 0,
        barThickness: 10,
        data: data && [data],
      },
    ],
  }
  return DistributorData
}

export const barChartOptions = {
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
      },
      max: 100,
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
}

interface PieChartArgs {
  piedata: (string | number)[]
  pielabel: (string | number)[]
}

export function PieCharData({piedata, pielabel}: PieChartArgs) {
  const DistributorData = {
    labels: pielabel || [],
    datasets: [
      {
        label: 'Qty',
        data: piedata || [],
        backgroundColor: generateColors(piedata?.length),
        borderWidth: 1,
        spacing: 2,
      },
    ],
  }
  return DistributorData
}

export const pieChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  cutout: '85%',
  tooltip: {
    enabled: false,
  },
}

interface LineChartArgs {
  linedata: (string | number)[]
  linelabel: (string | number)[]
}

export function dataToLineChart({linedata, linelabel}: LineChartArgs) {
  const labels = linelabel
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'My First Dataset',
        data: linedata,
        fill: true,
        borderColor: 'rgba(0, 92, 230)',
        backgroundColor: function (context: any) {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, context.chart.height)
          gradient.addColorStop(0, 'rgba(0, 92, 230, 0.2)')
          gradient.addColorStop(0.4, 'rgba(30, 144, 255, 0.2)')
          gradient.addColorStop(0.8, 'rgba(135, 206, 250, 0.2)')
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0.2)')
          return gradient
        },
        tension: 0.45,
      },
    ],
  }
  return data
}
export const lineChartOptions = {
  scales: {
    x: {
      display: false, // Hide x-axis
    },
    y: {
      display: false, // Hide y-axis
    },
  },
  elements: {
    line: {
      borderWidth: 2,
      shadowColor: 'rgba(0, 0, 0, 0.2)',
      shadowBlur: 10,
    },
    point: {
      radius: 0, // Set the radius to 0 to hide the points
      hitRadius: 0,
      hoverRadius: 0,
    },
  },
  plugins: {
    legend: {
      display: false, // Hide legend
    },
  },
  tooltips: {
    enabled: false, // Disable tooltips
  },
}

export const parseMainTableRowData = (row: any) => {
  return {
    state: row?.state || '-',
    distributor: row?.distributor || 0,
    retailer: row?.retailer || 0,
    customers: row?.customers,
    total_quantity_by_range: row?.total_quantity_by_range,
    percentage: row?.percentage,
    month_4: row?.month_4,
    month_3: row?.month_3,
    month_2: row?.month_2,
    month_1: row?.month_1,
  }
}

export const parseAgrigatedProductRowData = (row: any) => {
  return {
    id: row?.product_id,
    product_name: row?.product_name || '-',
    available_quantity: row?.available_quantity || 0,
    sold_quantity: row?.sold_quantity || 0,
    total: row?.total || 0,
    month_4: row?.month_4,
    month_3: row?.month_3,
    month_2: row?.month_2,
    month_1: row?.month_1,
  }
}

export const parsePriceTableRowData = (row: any) => {
  return {
    price: `$${row?.price || '-'}`,
    quantity: row?.quantity,
    percentage: row?.percentage,
    month_4: row?.month_4,
    month_3: row?.month_3,
    month_2: row?.month_2,
    month_1: row?.month_1,
  }
}

export const parseSKUReportTableRowData = (row: any) => {
  return {
    variants: row?.variants || '-',
    state: row?.state || 'All state',
    total_quantity_by_range: row?.total_quantity_by_range,
    percentage: row?.percentage,
    month_4: row?.month_4,
    month_3: row?.month_3,
    month_2: row?.month_2,
    month_1: row?.month_1,
  }
}

export const parseTopCoustomerTableRowData = (row: any) => {
  return {
    company: row?.company || '-',
    state: row?.state || '-',
    customer_group: row?.customer_group || '-',
    customer_type: row?.customer_type || '-',
    total_quantity_by_range: row?.total_quantity_by_range,
    percentage: row?.percentage,
    month_4: row?.month_4,
    month_3: row?.month_3,
    month_2: row?.month_2,
    month_1: row?.month_1,
  }
}

export const parseOverviewData = (overviewData: any) => {
  return {
    order_count: overviewData?.order_count || 0,
    total_customers: `${overviewData?.total_customers || 0} Customers`,
    sold_quantity: overviewData?.sold_quantity || 0,
    average_price: `$${overviewData?.average_price || 0} APP`,
    earning: `$${overviewData?.earning?.toLocaleString('en-IN') || 0}`,
  }
}

export function processCustomerOverviewData(payload: any) {
  let orders: {type: string; value: string}[] = []
  let soldQuantity: {type: string; value: string}[] = []
  let earnings: {type: string; value: string}[] = []
  let percentages: {[key: string]: string} = {}

  payload?.forEach((customer: any) => {
    let customerType = customer.customer_type

    orders.push({type: customerType, value: `${customer.order_count}`})
    soldQuantity.push({type: customerType, value: `${customer.sold_quantity}`})
    earnings.push({type: customerType, value: `$${customer.earning.toLocaleString('en-IN') || 0}`})

    percentages[customerType] = `${customerType} (${customer.sold_quantity_percent}%)`
  })

  return {
    orders: orders,
    soldQuantity: soldQuantity,
    earnings: earnings,
    percentages: percentages,
  }
}



export const dataToBarChart = (data: any, labels: any, totalPercentage: any) => {
  const datasets = Object.keys(data)
    .reverse()
    .map((label: string) => {
      const percentages = data[label]
      return {
        label,
        data: labels?.map((monthLabel: string, index: number) => {
          const monthKey = monthLabel?.replace('/', '_')
          return totalPercentage[monthKey]
            ? totalPercentage[monthKey] * (percentages[index] / 100)
            : 0
        }),
        backgroundColor:
          label === 'Retailer' ? '#4A90E2' : label === 'Distributor' ? '#F55A4E' : '#50E3C2',
        barPercentage: 0.3,
        categoryPercentage: 0.6,
        borderRadius: {topLeft: 8, topRight: 8, bottomLeft: 8, bottomRight: 8},
        borderWidth: 2,
        borderSkipped: false,
        borderColor: 'white', 
        stack: 'stack1',
      }
    })
  const ChartData = {
    labels: labels,
    datasets,
  }
  const CustomerReportBarChartOption  = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const datasetLabel = tooltipItem.dataset.label || ''
            const index = tooltipItem.dataIndex 
            const originalData = data[datasetLabel] ? data[datasetLabel][index] : {}
            const percentage =
              originalData !== undefined ? originalData?.toFixed(2) : 0 
  
            return `${datasetLabel}: ${percentage}%`
          },
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value: any) => {
          return value + '%'
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: function (value: any) {
            return value + '%'
          },
        },
        grid: {
          display: false,
        },
      },
    },
  }

  return {ChartData,CustomerReportBarChartOption}
}

export function extractChartData(data: any, TotalPercentage: any) {
  const labels: any = []
  const chartData: any = {}

  // Extract months (labels) dynamically from any category (e.g., Chain Store Retailer)
  const months = Object.keys(TotalPercentage || {})
  months?.forEach((month) => {
    const [monthNum, year] = month.split('_')
    labels.push(`${monthNum}/${year}`)
  })

  // Extract percentage values dynamically for each category
  Object.keys(data).forEach((category: any) => {
    chartData[category] = months.map((month) => data[category][month]?.percentage || 0)
  })

  return {labels, chartData}
}

export const sortableColumnsForOrderHistory = [
  {
    key: 'order_created_date_time',
    label: 'ORDER DATE',
    headerStyle: 'min-w-120px',
    isSorted: true,
  },
  {
    key: 'order_id',
    label: 'ORDER #',
    headerStyle: 'min-w-120px',
    isSorted: false,
  },
  {
    key: 'order_status',
    label: 'Status',
    headerStyle: 'min-w-120px',
    isSorted: false,
  },
  {
    key: 'name',
    label: 'CUSTOMER NAME',
    headerStyle: 'min-w-300px',
    isSorted: true,
  },
  {
    key: 'company',
    label: 'COMPANY NAME',
    headerStyle: 'min-w-200px',
    isSorted: true,
  },
  {
    key: 'total_quantity',
    label: 'Pack QTY',
    headerStyle: 'min-w-150px',
    isSorted: true,
    style: 'justify-content-end',
  },
  {
    key: 'total_quantity_in_packets',
    label: 'QTY Piece',
    headerStyle: 'min-w-100px',
    isSorted: true,
    style: 'justify-content-end',
  },
  {
    key: 'product_price_after_discount',
    label: 'PRICE',
    headerStyle: 'min-w-100px',
    isSorted: true,
    style: 'justify-content-end',
  },
  {
    key: 'product_total',
    label: 'TOTAL',
    headerStyle: 'min-w-100px',
    isSorted: true,
    style: 'justify-content-end',
  },
  {
    key: 'order_total_including_tax',
    label: 'ORDER TOTAL',
    headerStyle: 'min-w-150px',
    isSorted: true,
    style: 'justify-content-end',
  },
];

export const sortableColumnsForPriceChangeLog = [
  {key: 'updated_at', label: 'CHANGE DATE', headerStyle: 'min-w-120px', isSorted: true},
  {key: 'updated_by', label: 'update by', headerStyle: 'min-w-250px', isSorted: true},
  {
    key: 'old_price',
    label: 'PREVIOUS PRICE',
    headerStyle: 'min-w-150px',
    isSorted: true,
    style: 'justify-content-end',
  },
  {
    key: 'new_price',
    label: 'NEW PRICE',
    headerStyle: 'min-w-80px',
    isSorted: true,
    style: 'justify-content-end',
  },
]
