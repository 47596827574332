function ChildTableHeader({monthly_column, isColumnSelected}: any) {
  const originalObject = monthly_column !== undefined ? monthly_column : {}
  const reversedObject = Object.fromEntries(Object.entries(originalObject).reverse())
  const monthly_column_keys = Object.keys(reversedObject)
  return (
    <thead className='fw-semibold bg-light text-uppercase'>
      <tr className='text-gray-700'>
        <th className='min-w-40px w-40px head-col-sticky'></th>
        <th className='min-w-50px w-50px head-col-sticky'></th>

        <th className='min-w-150px w-150px head-col-sticky'>{'SKU'}</th>

        <th className='min-w-400px w-400px head-col-sticky'>{'Title'}</th>

        {isColumnSelected('classification') && (
          <th className='min-w-250px w-250px head-col-sticky'>{'Classification'}</th>
        )}

        {isColumnSelected('reserved_quantity') && (
          <th className='min-w-150px w-150px text-center head-col-sticky'>{'Blocked Qty'}</th>
        )}
        {isColumnSelected('quantity_available') && (
          <th className='min-w-100px w-100px text-center head-col-sticky'>{'Available'}</th>
        )}
        {isColumnSelected('safetystock_quantity') && (
          <th className='min-w-150px w-100px text-center head-col-sticky'>{'Safety Stock'}</th>
        )}
        {isColumnSelected('quantity_incoming') && (
          <th className='min-w-100px w-100px text-center head-col-sticky'>{'Incoming'}</th>
        )}
        {isColumnSelected('backorder_quantity') && (
          <th className='min-w-100px w-100px text-center head-col-sticky'>{'Backorder'}</th>
        )}
        {isColumnSelected('total_sold_30') && (
          <th className={`min-w-150px w-150px text-center`}>{'Total Sold (30)'}</th>
        )}
        {isColumnSelected('incremental_quantity') && (
          <th className={`min-w-180px w-180px text-center`}>{'Incremental Qty'}</th>
        )}
        {isColumnSelected('case_qty') && (
          <th className={`min-w-150px w-150px text-center`}>{'Case Quantity'}</th>
        )}
        {isColumnSelected('suggested_order_qty') && (
          <th className={`min-w-250px w-250px text-center`}>{' Suggested Order Qty'}</th>
        )}
        {isColumnSelected('suggested_order_qty_45') && (
          <th className={`min-w-250px w-250px text-center`}>{' Suggested Order Qty 45'}</th>
        )}
        {isColumnSelected('suggested_order_qty_60') && (
          <th className={`min-w-250px w-250px text-center`}>{' Suggested Order Qty 60'}</th>
        )}
        {isColumnSelected('to_order_qty') && (
          <th className={`min-w-200px w-200px text-center`}>{'To Order Quantity'}</th>
        )}
        {isColumnSelected('item_order_qty') && (
          <th className={`min-w-120px w-120px text-center`}>{'Item To Be Ordered'}</th>
        )}

        {monthly_column_keys &&
          monthly_column_keys !== undefined &&
          monthly_column_keys.map((column) => (
            <th className={`min-w-80px w-80px`} key={monthly_column[column]}>
              {monthly_column[column]}
            </th>
          ))}
        {isColumnSelected('total_quantity') && (
          <th className='min-w-120px w-120px text-center'>{'Available + Incoming'}</th>
        )}
        {isColumnSelected('days_out_of_stock') && (
          <th className='min-w-175px w-200px text-center'>{'Out of Stock Days'}</th>
        )}
        {isColumnSelected('cost') && (
          <th className={`min-w-100px w-100px`}>
            <span className=''>{'Cost'}</span>
          </th>
        )}
        {isColumnSelected('total_cost') && (
          <th className={`min-w-150px w-150px text-center`}>{'Total Cost'}</th>
        )}
        {isColumnSelected('primary_supplier') && (
          <th className='min-w-300px w-300px'>{'Supplier Company Name'}</th>
        )}

        {isColumnSelected('weeks_on_hand') && (
          <th className={`min-w-150px w-150px text-center`}>{'Weeks on Hand'}</th>
        )}
        {isColumnSelected('turn_rate') && (
          <th className={`min-w-100px w-100px text-center`}>{'Turn Rate'}</th>
        )}
        <th className='min-w-80px w-80px text-center'>Actions</th>
      </tr>
    </thead>
  )
}

export default ChildTableHeader
