import {useContext, useState} from 'react'
import useApi from '../../../../services/useApi'
import {ProjectDetailsContext} from '../../context'

const useGanttChartOperations = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const [isLoading, setLoading] = useState(false)

  const updateGanttChart = (payload: any, ticketId: number) => {
    return Api.patch(`/agile/projects/${projectId}/cards/${ticketId}`, payload, {
      setLoading: setLoading,
    })
  }

  const sortGanttChartRows = (payload: any) => {
    return Api.patch(`/agile/projects/${projectId}/gantt-chart/sorting`, payload, {
      setLoading: setLoading,
    })
  }

  return {
    updateGanttChart,
    sortGanttChartRows,
    isLoading,
  }
}

export default useGanttChartOperations
