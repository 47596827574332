export function ReplenishmentDataDropdown({setShowModal}: any) {
  const handleEdit = () => {
    setShowModal(true)
    document.body.click()
  }

  return (
    <div>
      <div className='px-2 py-1'>
        <span onClick={handleEdit} className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>
    </div>
  )
}
