import {useLocation} from 'react-router-dom'
import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

const useGetClassifiedAsList = () => {
  const Api = useApi()
  const location = useLocation()

  const initialFilters = {
    search: true,
    page: true,
    limit: true,
    sort_by: 'created_at/-1',
  }

  const {filters, onSearch, onSortingChange, onPageChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data: response, isFetching} = Api.useGetQuery(`/analytics/replenishment/classified-as`, {
    queryId: 'classified-as-list',
    filters: location.pathname === '/analytics/replenishment/dashboard' ? {} : filters,
  })

  return {
    classifiedAsList: response?.data || [],
    isLoading: isFetching,
    filters,
    pagination: response?.pagination,
    onSearch,
    onSortingChange,
    onPageChange,
  }
}

export default useGetClassifiedAsList
