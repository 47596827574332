import {useContext, useEffect, useState} from 'react'
import {ReplenishmentDashboardContext} from '../../context'
import TableRow from './ReplenishmentDashboardTableRow'
import {toggleSortingOrder} from '../../../../utils/common'
import Loading from '../../../loading'
import {Pagination} from '../../../../utils/pagination'
import {RenderBothArrow} from '../../../../utils/renderBothArrow'
import NoDataFound from '../../../../components/NoDataFound'
import useGetJobData from '../../../jobs/hooks/useGetJobData'
import usePermission from '../../../../hook/usePermission'
import {formatDate} from '../../../../utils/date'
import fullScreenIcon from '../../../../images/maximize.svg'
import columnsIcon from '../../../../images/grid.svg'
import minimizeIcon from '../../../../images/minimize.svg'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {MultiSelect} from '../../../../components/MultiSelect/'
import useReplenishmentOperations from '../../hooks/useReplenishmentOperations'

function ReplenishmentDashboardTable({pagination, onPageChange}: any) {
  const {hasPermission} = usePermission()
  const {saveTableColumns} = useReplenishmentOperations()
  const {jobData, runTask, refetchJobData} = useGetJobData('update_analytics_tables')

  useEffect(() => {
    let intervalId: any = null

    if (jobData?.status === 'running') {
      intervalId = setInterval(() => {
        refetchJobData()
      }, 5000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobData?.status === 'running', refetchJobData])

  const {
    isFetchingReplenishmentData,
    replenishment,
    monthly_column,
    meta_price_lists,
    dataFilters,
    isColumnSelected,
    allTableColumns,
    selectedColumns,
    onClickSorting,
    register,
    setSelectedColumns,
  }: any = useContext(ReplenishmentDashboardContext)
  // const monthly_column_keys = monthly_column !== undefined ? Object.keys(monthly_column) : undefined

  const metaArray =
    meta_price_lists &&
    Object.entries(meta_price_lists).map(([key, value]: any) => ({
      key,
      value: value.name,
      active: value.active,
    }))

  const originalObject = monthly_column !== undefined ? monthly_column : {}
  const reversedObject = Object.fromEntries(Object.entries(originalObject).reverse())
  const monthly_column_keys = Object.keys(reversedObject)

  const [direction, setDirection] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (dataFilters) {
      const array = dataFilters['sort_by']?.split('/')
      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [dataFilters])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div className='card-body p-0'>
      <div className={`table-modes ${expanded ? 'enable-expand-view' : ''}`}>
        <div className='table-mode-buttons mb-10 text-end d-flex align-items-center justify-content-between'>
          <div className='d-flex gap-10 align-items-center'>
            <div className='table-fullscreen-link'>
              <span
                className='cursor-pointer text-dark fw-bold text-active-primary d-flex align-items-center'
                onClick={handleExpandClick}
              >
                {expanded && (
                  <>
                    <img src={minimizeIcon} alt='minimize' className='w-25px h-25px me-2' />{' '}
                    Minimize
                  </>
                )}
                {!expanded && (
                  <>
                    <img src={fullScreenIcon} alt='fullscreen' className='w-25px h-25px me-2' />{' '}
                    Full Screen
                  </>
                )}
              </span>
            </div>

            <div className='me-10'>
              <OverlayComponent
                btnIcon={
                  <div className='d-flex d-flex align-items-center'>
                    <img src={columnsIcon} alt='columns' className='w-25px h-25px me-2' />
                    Columns
                  </div>
                }
                className='w-100'
                popoverClass='h-400px'
                placement={'bottom'}
                children={
                  <MultiSelect
                    id='replenishment-columns'
                    options={allTableColumns}
                    className='w-350px'
                    handleSelected={(values: any) => {
                      setSelectedColumns(values)
                      saveTableColumns(
                        values?.map((value: any) => value.value),
                        'replenishment_dashboard'
                      )
                    }}
                    isOpen={true}
                    selectedValues={selectedColumns}
                  />
                }
              />
            </div>
          </div>

          {hasPermission('settings_jobs', 'write') && (
            <div className='position-relative d-flex align-items-center me-10'>
              <span className='me-2'>Last Sync:</span>
              <span className='me-3'>{formatDate(jobData.last_run_end_time, false)} </span>
              {jobData?.status !== 'running' ? (
                <>
                  {
                    <i
                      className='fas fa-sync cursor-pointer fs-5'
                      onClick={() => runTask('update_analytics_tables')}
                    ></i>
                  }
                </>
              ) : (
                <div className='d-flex justify-content-center'>
                  <div className='spinner-border text-success' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className='table-responsive-wrapper'>
          <div className='table-responsive position-relative'>
            <div className='table-loader-wrapper'>
              <table className='table table-row-bordered table-row-gray-300 align-middle gs-0 gy-3 replenishment-dashboard-sticky-table'>
                <thead>
                  <tr className='fw-semibold text-muted text-uppercase'>
                    <th className='min-w-40px w-40px head-col-sticky'></th>

                    <th className='min-w-150px w-150px head-col-sticky'>{'SKU'}</th>

                    <th
                      className={`min-w-400px w-400px head-col-sticky`}
                      onClick={() => onClickSorting('product_title', toggleSortingOrder(direction))}
                    >
                      <div className='d-flex align-items-center'>
                        <span className='me-2'>{'Title'}</span>

                        {direction === 'asc' && sortBy === 'product_title' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'product_title' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>

                    {isColumnSelected('created_at') && (
                      <th
                        className='min-w-150px w-150px'
                        onClick={() => onClickSorting('created_at', toggleSortingOrder(direction))}
                      >
                        <div className='d-flex align-items-center'>
                          <span className='me-2'>{'Date Created'}</span>

                          {direction === 'asc' && sortBy === 'created_at' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && sortBy === 'created_at' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                    )}

                    {isColumnSelected('year') && <th className='min-w-75px w-75px'>{'Year'}</th>}

                    {isColumnSelected('primary_supplier') && (
                      <th className='min-w-175px w-175px'>{'Supplier'}</th>
                    )}

                    {isColumnSelected('purchaser_name') && (
                      <th className='min-w-175px w-175px'>{'Purchaser Name'}</th>
                    )}

                    {isColumnSelected('classified_as') && (
                      <th className='min-w-175px w-175px'>{'Classified As'}</th>
                    )}

                    {isColumnSelected('classification') && (
                      <th className='min-w-175px w-175px'>{'Classification'}</th>
                    )}

                    {isColumnSelected('cost') && (
                      <th className='min-w-75px w-75px text-center'>{'Cost Price'}</th>
                    )}

                    {isColumnSelected('quantity_on_hold') && (
                      <th className='min-w-100px w-100px text-center'>{'Quantity on Hold'}</th>
                    )}

                    {isColumnSelected('quantity_pending') && (
                      <th className='min-w-100px w-100px text-center'>{'Quantity Pending'}</th>
                    )}

                    {isColumnSelected('quantity_incoming') && (
                      <th className='min-w-100px w-100px text-center'>{'Incoming Qty'}</th>
                    )}

                    {isColumnSelected('quantity_available') && (
                      <th className='min-w-100px w-100px text-center'>{'Available Qty'}</th>
                    )}

                    {isColumnSelected('total_quantity') && (
                      <th className='min-w-125px w-125px text-center'>{'Available + Incoming'}</th>
                    )}

                    {monthly_column_keys &&
                      monthly_column_keys !== undefined &&
                      monthly_column_keys.map((column) => (
                        <th key={monthly_column[column]} className={`min-w-80px w-80px`}>
                          {monthly_column[column]}
                        </th>
                      ))}

                    {isColumnSelected('total_sold') && (
                      <th className='min-w-100px w-100px text-center'>{'Total Sold'}</th>
                    )}

                    {isColumnSelected('wholesale_price') && (
                      <th className='min-w-125px w-125px text-center  bg-light-warning'>
                        {'Wholesale'}
                      </th>
                    )}

                    {metaArray &&
                      metaArray?.map((item: any, index: number) => {
                        const metaItem = metaArray.find(
                          (meta: any) => meta.key === `price_${index + 1}`
                        )
                        return isColumnSelected(metaItem?.key) ? (
                          <th
                            key={index}
                            className={`text-center min-w-100px ${!item.active ? 'bg-light ' : ''}`}
                          >
                            <div className='text-nowrap'>{item?.value}</div>
                          </th>
                        ) : null
                      })}

                    {isColumnSelected('ratio') && (
                      <th
                        className={`min-w-100px w-100px `}
                        onClick={() => onClickSorting('ratio', toggleSortingOrder(direction))}
                      >
                        <div className='d-flex align-items-center '>
                          <span className='me-2'>{'Ratio'}</span>

                          {direction === 'asc' && sortBy === 'ratio' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && sortBy === 'ratio' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                    )}

                    {isColumnSelected('total_cost') && (
                      <th
                        className={`min-w-150px w-150px`}
                        onClick={() => onClickSorting('total_cost', toggleSortingOrder(direction))}
                      >
                        <div className='d-flex align-items-center'>
                          <span className='me-2'>{'Total Cost'}</span>

                          {direction === 'asc' && sortBy === 'total_cost' ? (
                            <i className='bi bi-caret-up-fill fs-8'></i>
                          ) : direction === 'desc' && sortBy === 'total_cost' ? (
                            <i className='bi bi-caret-down-fill fs-8'></i>
                          ) : (
                            <RenderBothArrow />
                          )}
                        </div>
                      </th>
                    )}

                    {isColumnSelected('terms_consignment') && (
                      <th className='min-w-200px w-200px'>{'Terms Consignment'}</th>
                    )}

                    {isColumnSelected('action_taken') && (
                      <th className='min-w-350px w-350px'>{'Action Taken'}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {replenishment && replenishment.length > 0
                    ? replenishment.map((row: any) => (
                        <TableRow
                          row={row}
                          monthly_column_keys={monthly_column_keys}
                          register={register}
                          key={row['sku']}
                        />
                      ))
                    : !(
                        isFetchingReplenishmentData || isFetchingReplenishmentData === undefined
                      ) && (
                        <NoDataFound
                          colspan={selectedColumns?.length === 0 ? 26 : selectedColumns?.length + 3}
                        />
                      )}
                </tbody>
              </table>
              {(isFetchingReplenishmentData || isFetchingReplenishmentData === undefined) && (
                <Loading />
              )}
            </div>
          </div>
        </div>
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </div>
    </div>
  )
}

export default ReplenishmentDashboardTable
